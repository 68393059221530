import React from 'react';
import propTypes from 'prop-types';

import ResponseIcon from './ResponseIcon';

function getInitials(name) { return name.split(' ').map((part) => part[0]); }

export default function AssigneeAvatar({ name, response }) {
  return (
    <div className="assignee-avatar">
      {getInitials(name)}
      <ResponseIcon response={response} />
    </div>
  );
}
AssigneeAvatar.propTypes = {
  name: propTypes.string.isRequired,
  response: propTypes.string.isRequired,
};
