import React from 'react';
import PropTypes from 'prop-types';
import '../Input.css';

import Label from '../Label';

function FilePicker({
  fileTypes = ['.csv,'],
  error = false,
  onChange = () => {},
  label = '',
  id = 'file_picker',
  fullWidth = false,
  disabled = false,
  helperText = '',
}) {
  const formattedFileTypes = fileTypes.join(', ');
  const inputClassNames = ['input-select'];
  if (error) inputClassNames.push('error');
  return (
    <div className={fullWidth ? 'input-full-width input-container' : 'input-container'}>
      <Label text={label} htmlFor={id} helperText={helperText} />
      <input
        className={inputClassNames.join(' ')}
        type="file"
        id={id}
        accept={formattedFileTypes}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

export default FilePicker;

FilePicker.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};
