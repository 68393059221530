import React, { useContext, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

import ButtonGroup from '../../core/Input/ButtonGroup';

import UserContext from '../../UserContext';
import { REPORT_PATH } from '../../constants';

import Button from '../../core/Input/Button';
import Dialog from '../../core/Display/Dialog';
import LoadingAnimation from '../../core/Display/LoadingAnimation';
import Text from '../../core/Display/Text';

import DataViewHeader from '../Report/DisplayReport/Common/DataViewHeader';

import Flex from '../Common/FlexV2';

import ListEntry from './ListEntry';
import DeleteConfirmationText from './DeleteConfirmationText';
import {
  fetchReportList,
  deleteReport,
} from '../../utils/networkUtils';

/* eslint "no-underscore-dangle": "off" */

function SavedReportsView() {
  const [reports, setReports] = useState([]);
  const [rptToDelete, setRptToDelete] = useState({});
  const [pendingDelete, setPendingDelete] = useState(false);
  const [message, setMessage] = useState('');
  const {
    receivedURLs,
    userID,
  } = useContext(UserContext);

  useEffect(() => {
    if (!receivedURLs) { return; }
    fetchReportList(userID)
      .then((resp) => {
        const list = resp.data.Reports.map((rep) => {
          const button = (
            <>
              <Button
                variant="icon"
                color="secondary"
                url={`${REPORT_PATH}/${rep._id}`}
                title="View report"
              >
                <VisibilityIcon />
              </Button>
              <Button
                variant="icon"
                color="tertiary"
                onClick={() => { setRptToDelete(rep); }}
                title="Delete report"
              >
                <DeleteIcon />
              </Button>
            </>
          );
          return (
            <ListEntry
              key={`${rep._id}`}
              text={rep.rpt_title}
              button={button}
            />
          );
        });
        setReports(list);
      })
      .catch((err) => { setReports(err.message); });
  }, [receivedURLs]);

  const pruneReport = (id) => {
    const nrpts = [];
    reports.forEach((rpt) => {
      if (rpt.key === id) { return; }
      nrpts.push(rpt);
    });
    setReports(nrpts);
  };

  const confirmationPopupChildren = (
    <>
      {pendingDelete && <LoadingAnimation />}
      {!pendingDelete
      && <DeleteConfirmationText name={rptToDelete.rpt_title} />}
    </>
  );

  const clearConfirmationPopup = () => {
    setRptToDelete({});
    setPendingDelete(false);
  };
  const deleteSuccess = (resp) => {
    setMessage(resp.data.message);
    pruneReport(rptToDelete._id);
    clearConfirmationPopup();
  };
  const deleteFailure = () => {
    setMessage('Unable to delete report.');
    clearConfirmationPopup();
  };
  const confirmDelete = () => {
    deleteReport(rptToDelete._id)
      .then((resp) => { deleteSuccess(resp); })
      .catch(() => { deleteFailure(); });
  };

  return (
    <DataViewHeader title="Saved Reports" defaultOpen>
      {rptToDelete._id
      && (
        <Dialog
          centerAll
          closeFn={() => { setRptToDelete({}); }}
          footerContent={(
            <ButtonGroup center>
              <Button onClick={() => { setRptToDelete({}); }}>Cancel</Button>
              <Button onClick={confirmDelete}>Confirm</Button>
            </ButtonGroup>
          )}
        >
          {confirmationPopupChildren}
        </Dialog>
      )}
      {message
      && (
        <Dialog
          centerAll
          footerContent={(
            <Button onClick={() => { setMessage(''); }}>OK</Button>
          )}
        >
          <Text>{message}</Text>
        </Dialog>
      )}
      <Flex direction="column">
        {reports}
      </Flex>
    </DataViewHeader>
  );
}

export default SavedReportsView;
