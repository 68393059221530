import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import ShareIcon from '@mui/icons-material/Share';

import SectionTitle from '../../../../core/Display/SectionTitle';
import Dialog from '../../../../core/Display/Dialog';
import ButtonGroup from '../../../../core/Input/ButtonGroup';
import Dropdown from '../../../../core/Input/Dropdown';
import Button from '../../../../core/Input/Button';

import { USER_KEY } from '../../../../constants';
import { getSaveReportURL } from '../../../../utils/urlMapUtils';
import { getReportDestinations } from '../../../../utils/networkUtils';
import UserContext from '../../../../UserContext';

import exportTableAsCSV from '../../../Common/ExportCSV';
import { SHARE_TXT, SHARE_BT_TXT } from '../../strings';

import EmailPagePopup from './EmailPagePopup';
import useSaveReport from './useSaveReport';
import generateURL from './generateURL';
import './SaveReport.css';

const MESSAGES = {
  missingTitle: 'The report needs a title.',
  missingDestination: 'Select a destination.',
  notSignedIn: 'You must be signed in to save to this destination.',
  notAuthorized: 'You are not authorized to save to this destination.',
  notImplemented: 'This option has not yet been implemented.',
  confirmDestination: (name) => `Are you sure you want to save to ${name}?`,
  reportSaved: 'Report saved.',
  reportNotSaved: 'There was an error saving the report.',
};

const SAVE_TARGETS = {
  ACC: {
    name: 'Save to Your Account',
    restricted: false,
    disabled: false,
  },
  CSV: {
    name: 'Download Data Table as CSV',
    restricted: false,
    disabled: false,
  },
  PDF: {
    name: 'Download Report as PDF',
    restricted: false,
    disabled: true,
  },
  EML: {
    name: 'Email Report',
    restricted: false,
    disabled: false,
  },
};

function ConfirmDialog({ text, onConfirm, onCancel }) {
  return (
    <Dialog
      centerAll
      closeFn={onCancel}
      title={text}
      footerContent={(
        <ButtonGroup center>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>
            Confirm
          </Button>
        </ButtonGroup>
      )}
    />
  );
}

ConfirmDialog.propTypes = {
  text: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
};

function toKey(text) {
  return text.replace(/ /g, '-');
}

function fromKey(text) {
  return text.replace(/-/g, ' ');
}

function interpretAuthList(userID, authList) {
  const validDestinations = [];
  authList.forEach((destination) => {
    if (destination.auth_users.includes(userID)) {
      validDestinations.push(destination.dest_nm || destination.name);
    }
  });
  return validDestinations;
}

function useValidHomepages(userID) {
  const [validHomepages, setValidHomepages] = useState([]);
  const { receivedURLs, getRptDestsAuthListURL } = useContext(UserContext);

  useEffect(() => {
    if (!receivedURLs) { return; }
    const source = axios.CancelToken.source();
    axios.get(getRptDestsAuthListURL(), { cancelToken: source.token })
      .then((resp) => {
        setValidHomepages(interpretAuthList(userID, resp.data['Report destinations']));
      })
      .catch(() => {});
  }, [userID]);

  return validHomepages;
}

function makeOptionsList(destinations, validHomepages) {
  const options = Object.keys(SAVE_TARGETS)
    .map((id) => ({ name: SAVE_TARGETS[id].name, id }));
  const targets = { ...SAVE_TARGETS };

  destinations.forEach((target) => {
    if (!validHomepages.includes(target)) return;
    options.push({ id: target, name: target });
    targets[target] = { name: target, restricted: true, disabled: false };
  });

  return [options, targets];
}

function TargetSelection({
  setSaveTarget = () => {},
  options,
}) {
  const [chosenTarget, setChosenTarget] = useState('ACC');

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <Dropdown
        id="save-target-dropdown"
        value={chosenTarget}
        label="Choose destination"
        onChange={(event) => {
          setChosenTarget(event.target.value);
          setSaveTarget(event.target.value);
        }}
        options={options}
      />
    </div>
  );
}

TargetSelection.propTypes = {
  setSaveTarget: propTypes.func,
  options: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
};

function SaveReport({
  dataSet,
  form,
  report,
  small = false,
}) {
  const {
    user,
    userID,
    receivedURLs,
  } = useContext(UserContext);
  const validHomepages = useValidHomepages(userID);
  const [destinations, setDestinations] = useState([]);
  const [target, setTarget] = useState('ACC');
  const [popup, setPopup] = useState('');
  const [confirm, setConfirm] = useState('');
  const [emailer, setEmailer] = useState(false);
  const [reportID, setReportID] = useState('');

  const [options, targets] = useMemo(
    () => makeOptionsList(destinations, validHomepages),
    [JSON.stringify(validHomepages), JSON.stringify(destinations)],
  );

  useEffect(() => {
    if (!receivedURLs) return;
    getReportDestinations()
      .then((data) => { setDestinations(data); });
  }, [receivedURLs]);

  const formSubmit = () => {
    if (!report.title) {
      setPopup(MESSAGES.missingTitle);
      return;
    }
    if (target === '') {
      setPopup(MESSAGES.missingDestination);
      return;
    }
    const targObj = targets[target];
    if (targObj.disabled) {
      setPopup(MESSAGES.notImplemented);
      return;
    }
    if (!user[USER_KEY] && (targObj.restricted || target === 'ACC')) {
      setPopup(MESSAGES.notSignedIn);
      return;
    }
    if (targObj.restricted && !validHomepages.includes(targObj.name)) {
      setPopup(MESSAGES.notAuthorized);
      return;
    }
    const isSavedReport = (Object.keys(form).length === 0);
    if (targObj.restricted && confirm === '') {
      setConfirm(MESSAGES.confirmDestination(fromKey(targObj.name)));
      return;
    }
    switch (target) {
      case 'CSV':
        exportTableAsCSV(report.title, dataSet.cols, dataSet.data)
          .then(() => { setPopup(MESSAGES.reportSaved); })
          .catch(() => { setPopup(MESSAGES.reportNotSaved); });
        break;
      case 'EML':
        setEmailer(true);
        break;
      default:
        setConfirm('');
        useSaveReport(
          (isSavedReport ? dataSet.params : form),
          getSaveReportURL,
          report,
          user,
          userID,
          (targObj.restricted ? fromKey(targObj.name) : ''),
        )
          .then((id) => {
            setReportID(id);
            setPopup(MESSAGES.reportSaved);
          })
          .catch(() => { setPopup(MESSAGES.reportNotSaved); });
    }
  };

  return (
    <div className={small ? 'save-report-small' : 'save-report'}>
      {!small && <SectionTitle text={SHARE_TXT} />}
      {popup
      && (
      <Dialog
        centerAll
        closeFn={() => setPopup('')}
        title={popup}
        footerContent={(
          <ButtonGroup center>
            {popup === MESSAGES.reportSaved
            && reportID
            && (
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    generateURL(reportID),
                  );
                }}
              >
                Copy Report URL
              </Button>
            )}
            <Button onClick={() => setPopup('')}>OK</Button>
          </ButtonGroup>
        )}
      />
      )}
      {confirm
      && (
      <ConfirmDialog
        text={confirm}
        onConfirm={formSubmit}
        onCancel={() => setConfirm('')}
      />
      )}
      {emailer
      && (
        <EmailPagePopup
          closeHandler={() => { setEmailer(false); }}
          form={form}
          report={report}
        />
      )}
      <TargetSelection
        userID={userID}
        validHomepages={validHomepages}
        setSaveTarget={setTarget}
        options={options}
      />
      <Button
        omitTextOnMobile
        onClick={formSubmit}
        endIcon={<ShareIcon />}
      >
        {SHARE_BT_TXT}
      </Button>
    </div>
  );
}

SaveReport.propTypes = {
  dataSet: propTypes.instanceOf(Object).isRequired,
  form: propTypes.instanceOf(Object).isRequired,
  report: propTypes.instanceOf(Object).isRequired,
  small: propTypes.bool,
};

export default SaveReport;
export {
  MESSAGES,
  SAVE_TARGETS,
  toKey,
  useValidHomepages,
  interpretAuthList,
};
