import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../Input.css';
import Label from '../Label';

const DEF_WIDTH = 60;

function TextField({
  classes = [],
  defaultValue = undefined,
  disabled = false,
  error = false,
  fullWidth = false,
  helperText = undefined,
  id = undefined,
  label = undefined,
  maxRows = undefined,
  minRows = undefined,
  multiline = false,
  name = undefined,
  onChange = undefined,
  placeholder = undefined,
  required = false,
  size = undefined,
  sx = {},
  type = 'text',
  value = undefined,
  width = DEF_WIDTH,
  onFocus = () => {},
  onBlur = () => {},
}) {
  const inputRef = useRef(null);

  const containerClassName = [...classes, 'input-container'];
  const inputClassName = [];

  if (error) inputClassName.push('error text-field-error');
  const style = { ...sx };

  if (fullWidth) {
    containerClassName.push('input-full-width');
    inputClassName.push('input-full-width');
  }

  if (label && !id) throw new Error('TextFields with labels must have an id property');
  if (maxRows && multiline) style.maxHeight = `${Number(maxRows) * 2}rem`;
  if (minRows && multiline) style.minHeight = `${Number(minRows) * 2}rem`;

  let inputArea;
  const inputAreaProps = {
    ref: inputRef,
    className: inputClassName.join(' '),
    defaultValue,
    disabled,
    id,
    name,
    onChange,
    placeholder,
    size,
    style,
    type,
    value,
    width,
    required,
    onFocus,
    onBlur,
  };
  if (multiline) {
    inputArea = <textarea {...inputAreaProps} />; // eslint-disable-line
  } else {
    inputArea = <input {...inputAreaProps} />;  // eslint-disable-line
  }
  return (
    <div className={containerClassName.join(' ')}>
      {label
      && (
        <Label
          className="input-label"
          htmlFor={id}
          id={`${label.replace(/ /g, '-')}`}
          text={label}
          helperText={helperText}
        />
      )}
      {inputArea}
    </div>
  );
}

TextField.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.node,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default TextField;
