export const DEFAULT_INTERVAL = 0.5;
const DEFAULT_BUCKET_VALUE_KEY = 'current_coupon';

export function getStructure(rows, bucketValueKey = DEFAULT_BUCKET_VALUE_KEY) {
  const agencies = [];
  let start;
  let end;

  rows.forEach((row) => {
    const { agency } = row;
    const bucketValue = row[bucketValueKey];
    if (start === undefined) start = bucketValue;
    if (end === undefined) end = bucketValue;
    if (start > bucketValue) start = bucketValue;
    if (end < bucketValue) end = bucketValue;
    if (!agencies.includes(agency)) agencies.push(agency);
  });

  return { agencies, range: [start, end] };
}

function forceTwoDigits(number) {
  return Number(number.toFixed(2));
}

export function getColumnID(agency, bucket) {
  const bucketString = Number(bucket).toFixed(2);
  return `${agency}_${bucketString}`;
}
export function getColumnName(
  agency,
  bucket,
  bucketValueLabel,
  interval = DEFAULT_INTERVAL,
) {
  const start = bucket.toFixed(2);
  const end = (bucket + interval - 0.01).toFixed(2);
  return [
    `${agency}:`,
    'Total Unpaid Balance for Values of',
    bucketValueLabel,
    `between ${start} and ${end}`,
  ].join(' ');
}
export function getBucket(bucketValue, interval = DEFAULT_INTERVAL) {
  const factor = (bucketValue * 100) / (interval * 100);
  const bucket = Math.floor(factor) * interval;
  return forceTwoDigits(bucket);
}
export function getColumns(
  rows,
  agencies,
  range,
  bucketValueLabel,
  interval = DEFAULT_INTERVAL,
) {
  const start = getBucket(range[0], interval);
  const antiFloatingPointFudgeFactor = 0.005;
  const end = getBucket(range[1], interval) + interval - antiFloatingPointFudgeFactor;

  const columns = [{ id: 'agency', name: 'Agency Bucket' }];
  agencies.forEach((agency) => {
    for (let bucket = start; bucket < end - 0.005; bucket += interval) {
      columns.push({
        id: getColumnID(agency, bucket),
        name: getColumnName(agency, bucket, bucketValueLabel, interval),
      });
    }
  });
  return columns;
}

export function getCouponRows(
  agencies,
  columns,
  fullRows,
  bucketValueKey = DEFAULT_BUCKET_VALUE_KEY,
  interval = DEFAULT_INTERVAL,
) {
  const rows = agencies.map((agency) => {
    const row = { agency };
    columns.forEach(({ id }) => {
      if (id === 'agency') return;
      row[id] = 0;
    });
    return row;
  });
  fullRows.forEach((row) => {
    const { agency, unpaid_balance: unpaidBalance } = row;
    const bucketValue = row[bucketValueKey];
    const bucket = getBucket(bucketValue, interval);

    const id = getColumnID(agency, bucket);
    const agencyRow = rows.find(({ agency: rowAgency }) => rowAgency === agency);
    agencyRow[id] = forceTwoDigits(agencyRow[id] + unpaidBalance);
  });
  return rows;
}

function pruneEmptyColumns(columns, rows) {
  return columns.filter(({ id }) => {
    if (id === 'agency') return true;
    const values = rows.map((row) => row[id]);
    const max = Math.max(...values);
    return Boolean(max);
  });
}

export default function bucketValueData({
  rows = [],
  bucketValueKey = DEFAULT_BUCKET_VALUE_KEY,
  bucketValueLabel = 'Current Coupon',
  interval = DEFAULT_INTERVAL,
  noPrune = false,
}) {
  const { agencies, range } = getStructure(rows, bucketValueKey);
  const columns = getColumns(rows, agencies, range, bucketValueLabel, interval);
  const couponRows = getCouponRows(agencies, columns, rows, bucketValueKey, interval);
  return {
    columns: noPrune ? columns : pruneEmptyColumns(columns, couponRows),
    rows: couponRows,
  };
}
