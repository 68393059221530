import React from 'react';

import PageTitle from '../PageTitle';
import SectionTitle from '../SectionTitle';
import ContentBox from '../ContentBox';

import './ComingSoonPage.css';

function ComingSoonPage() {
  return (
    <ContentBox classes={['glass', 'full-width']}>
      <div className="comingSoon">
        <PageTitle
          text="Coming soon."
        />
        <SectionTitle
          text="We appreciate your patience as we work on this feature."
        />
        <div style={{ height: '8rem' }} />
      </div>
    </ContentBox>
  );
}

export default ComingSoonPage;
