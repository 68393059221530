import propTypes from 'prop-types';

export const FILTERS_PROPS = propTypes.shape({
  search: propTypes.string.isRequired,
  statuses: propTypes.arrayOf(propTypes.string).isRequired,
  authors: propTypes.arrayOf(propTypes.string).isRequired,
  reviewers: propTypes.arrayOf(propTypes.string).isRequired,
});
export const REVIEWER_PROPS = propTypes.shape({
  name: propTypes.string.isRequired,
  response: propTypes.string.isRequired,
});
export const MANUSCRIPT_PROPS = propTypes.shape({
  title: propTypes.string.isRequired,
  authors: propTypes.arrayOf(propTypes.string),
  reviewers: propTypes.arrayOf(REVIEWER_PROPS),
  timeUpdated: propTypes.instanceOf(Date).isRequired,
  status: propTypes.string.isRequired,
});

const PROPS = {
  FILTERS_PROPS,
  REVIEWER_PROPS,
  MANUSCRIPT_PROPS,
};

export default PROPS;
