import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../core/Display/Table';
import SectionAnalysis from '../Common/SectionAnalysis';

function StatsView({
  canEdit = false,
  dataSet = {},
  userText = '',
  onUserTextChange = () => {},
}) {
  const table = dataSet.stats
    .split('\n')
    .map((row) => row.split('\t'));
  const columns = table[0].map((name) => ({
    id: name || 'Data',
    name: name || 'Data',
    type: name ? 'float' : 'string',
  }));
  const rows = table.slice(1, table.length - 1).map((row) => {
    const object = {};
    row.forEach((cell, index) => {
      object[columns[index].id] = cell;
    });
    return object;
  });
  return (
    <>
      <SectionAnalysis
        canEdit={canEdit}
        content={userText}
        onChange={onUserTextChange}
      />
      <Table
        keyFieldName="Data"
        columns={columns}
        rows={rows}
      />
    </>
  );
}

export default StatsView;

StatsView.propTypes = {
  canEdit: PropTypes.bool,
  dataSet: PropTypes.shape({
    stats: PropTypes.string.isRequired,
  }),
  userText: PropTypes.string,
  onUserTextChange: PropTypes.func,
};
