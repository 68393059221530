import React from 'react';

/* eslint import/prefer-default-export: off */
const isOnSafari = window.navigator.userAgent.toLowerCase().includes('safari');

const useScrollbarWidth = () => {
  const didCompute = React.useRef(false);
  const widthRef = React.useRef(0);

  if (didCompute.current) return widthRef.current;

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

const setTitle = (pageTitle) => {
  let siteTitle = 'DataMixMaster';
  switch (process.env.REACT_APP_STYLE) {
    case 'sfa-variant': siteTitle = 'SFA Knowledge Hub'; break;
    default: break;
  }

  const fullTitle = pageTitle
    ? `${pageTitle} | ${siteTitle}`
    : siteTitle;
  if (document.title === fullTitle) return;
  document.title = fullTitle;
};

export {
  isOnSafari,
  useScrollbarWidth,
  setTitle,
};
