import React from 'react';
import { Link } from 'react-router-dom';

import ContentBox from './core/Display/ContentBox';

export default function Page404() {
  return (
    <ContentBox classes={['glass', 'full-width', 'center']}>
      <h1>404</h1>
      The requested page could not be found.
      <br />
      {'Click '}
      <Link to="/">here</Link>
      {' to return to the home page.'}
    </ContentBox>
  );
}
