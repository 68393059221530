import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../core/Display/Table';

import ReportContext from '../../ReportContext';

import SectionAnalysis from '../Common/SectionAnalysis';

function DataTableView({
  canEdit = false,
  dataSet = { cols: [], data: [] },
  userText = '',
  onUserTextChange = () => {},
  setUserColumns = () => {},
  userColumns = [],
}) {
  const { title } = useContext(ReportContext);
  const { columns, rows } = dataSet;
  return (
    <>
      <SectionAnalysis
        canEdit={canEdit}
        content={userText}
        onChange={onUserTextChange}
      />
      <Table
        filename={title}
        columns={columns}
        rows={rows}
        userColumns={userColumns}
        onUserColumnsChange={setUserColumns}
      />
    </>
  );
}

export default DataTableView;

DataTableView.propTypes = {
  canEdit: PropTypes.bool,
  dataSet: PropTypes.instanceOf(Object),
  userText: PropTypes.string,
  onUserTextChange: PropTypes.func,
  userColumns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setUserColumns: PropTypes.func,
};
