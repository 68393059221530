import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import './Button.css';

import ForwardIcon from '@mui/icons-material/ChevronRight';
import BackwardIcon from '@mui/icons-material/ChevronLeft';

function Button({
  children = null,
  classes = [],
  color = 'primary',
  direction = 'none',
  disabled = false,
  href = undefined,
  onClick = () => {},
  size = 'small',
  sx = undefined,
  type = 'button',
  url = null,
  variant = 'text',
  fullWidth = undefined,
  homePage = undefined,
  title = undefined,
  omitTextOnMobile = false,
  startIcon = null,
  endIcon = null,
  form = undefined,
}) {
  const classNames = ['button'];
  if (fullWidth) classNames.push('button-full-width');
  if (homePage) classNames.push('button-home-page');
  if (color) classNames.push(`button-${color}`);
  if (variant) classNames.push(`button-${variant}`);
  if (omitTextOnMobile) classNames.push('hide-on-mobile');
  if (classes) classes.forEach((cls) => { classNames.push(cls); });
  const innerProps = {
    disabled,
    href,
    onClick,
    size,
    sx,
    type,
    title,
    form,
  };
  const inner = (
    <button
      className={classNames.join(' ')}
      type={(type === 'submit' ? 'submit' : 'button')}
      {...innerProps} /* eslint-disable-line*/
    >
      <div className="button-adornment start">
        {direction === 'backward' ? <BackwardIcon /> : startIcon}
      </div>
      <div className="button-content">
        {children}
      </div>
      <div className="button-adornment end">
        {direction === 'forward' ? <ForwardIcon /> : endIcon}
      </div>
    </button>
  );

  if (url) {
    return (
      <Link to={url}>
        {inner}
      </Link>
    );
  }

  return inner;
}

export default Button;

Button.propTypes = {
  children: propTypes.node,
  classes: propTypes.arrayOf(propTypes.string),
  color: propTypes.string,
  direction: propTypes.string,
  disabled: propTypes.bool,
  href: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  sx: propTypes.instanceOf(Object),
  type: propTypes.string,
  url: propTypes.string,
  variant: propTypes.string,
  fullWidth: propTypes.bool,
  homePage: propTypes.bool,
  title: propTypes.string,
  omitTextOnMobile: propTypes.bool,
  startIcon: propTypes.node,
  endIcon: propTypes.node,
  form: propTypes.string,
};
