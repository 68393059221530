import React from 'react';

import MarkdownParser from '../../core/Transform/MarkdownParser';
import Button from '../../core/Input/Button';

import homeMD from '../../text/sfa/home_text';
import BACKGROUND_GIF from '../../videos/sfa-knowledgeHub-background.gif';

import './SFAHomepage.css';

import {
  SFA_TRENDS,
  SFA_HMDA,
  SFA_STATE,
  getPath,
} from '../../constants';

function Home() {
  const homeText = <MarkdownParser md={homeMD} />;

  return (
    <div className="landing-page">
      <div className="landing-page-video-wrapper">
        <img src={BACKGROUND_GIF} alt="" />
      </div>
      <div className="landing-page-body">
        <div className="landing-page-text sfa-tagline">
          {homeText}
          <br />
          <br />
        </div>
        <div className="landing-page-buttons">
          <Button url={SFA_STATE}>
            Learn How Securitization Helps Your State
          </Button>
          <Button url={SFA_HMDA}>
            Explore Data From The Home Mortgage Disclosure Act
          </Button>
          <Button url={SFA_TRENDS}>
            Some Market Trends We Track
          </Button>
          <Button url={getPath('SFA.RMBS')}>
            Agency RMBS Data
          </Button>
          <Button url={getPath('SFA.JOURNALHOME')}>
            Structured Finance Journal
          </Button>
          <Button url={getPath('SFA.SEC_CANADA')}>
            Securitization in Canada
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Home;
