import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ContentBox from '../../../core/Display/ContentBox';

import { getPath } from '../../../constants';

import './Selection.css';

function Card({ text }) {
  const to = getPath(`SFA.${text.toUpperCase().replace(' ', '')}`);
  return (
    <Link to={to}>
      <ContentBox classes={['outlined', text.replace(' ', '')]}>
        {text}
      </ContentBox>
    </Link>
  );
}
Card.propTypes = { text: propTypes.string.isRequired };

const PAGES = [
  'Gross Issuance',
  'Servicer Performance',
];
export default function Selection() {
  return (
    <div className="library">
      {PAGES.map((text) => <Card key={text} text={text} />)}
    </div>
  );
}
