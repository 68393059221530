/* eslint react/jsx-props-no-spreading: off */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

import './Toaster.css';

export const toasterContext = createContext({});

const STRINGS = {
  dismiss: 'Dismiss notification',
};

const TYPES = propTypes.oneOf([
  'message',
  'success',
  'error',
]);

function TimerBar({ percentage }) {
  return (
    <div className="dmm-toast-timer-bar-background">
      <div className="dmm-toast-timer-bar-foreground" style={{ width: `${percentage * 100}%` }} />
    </div>
  );
}
TimerBar.propTypes = {
  percentage: propTypes.number.isRequired,
};

function Icon({ type }) {
  switch (type) {
    case 'error': return <ErrorIcon />;
    case 'success': return <CheckIcon />;
    default: return null;
  }
}
Icon.propTypes = {
  type: TYPES.isRequired,
};

function Toast({
  text,
  timestamp,
  dismissable = true,
  timeout = 5000,
  type = 'message',
}) {
  const { removeToast } = useContext(toasterContext);
  const [timeRemaining, setTimeRemaining] = useState(timeout);
  const [classes, setClasses] = useState(['dmm-toast-wrapper', type]);

  const [fadingTimer, setFadingTimer] = useState();
  const [shrinkingTimer, setShrinkingTimer] = useState();

  const fadeTime = 500;
  const style = { '--fade-time': `${fadeTime}ms` };
  const shrink = () => {
    setClasses([...classes, 'faded']);
    setShrinkingTimer(fadeTime);
  };
  const fadeOut = () => {
    setClasses([...classes, 'fading']);
    setFadingTimer(fadeTime);
  };

  const interval = 100;
  useEffect(() => {
    if (shrinkingTimer === undefined) return;
    setTimeout(() => {
      const newTime = shrinkingTimer - interval;
      if (newTime <= 0) {
        removeToast(timestamp);
      } else {
        setShrinkingTimer(newTime);
      }
    }, interval);
  }, [shrinkingTimer]);

  useEffect(() => {
    if (fadingTimer === undefined) return;
    setTimeout(() => {
      const newTime = fadingTimer - interval;
      if (newTime <= 0) {
        shrink();
      } else {
        setFadingTimer(newTime);
      }
    }, interval);
  }, [fadingTimer]);

  useEffect(() => {
    if (!timeout) return;
    setTimeout(() => {
      const newTime = timeRemaining - interval;
      if (newTime <= 0) {
        fadeOut();
      } else {
        setTimeRemaining(newTime);
      }
    }, interval);
  }, [timeRemaining]);

  return (
    <div className={classes.join(' ')} style={style}>
      <div className="icon">
        <Icon type={type} />
      </div>
      <div className="text">{text}</div>
      {dismissable && (
        <button
          type="button"
          title={STRINGS.dismiss}
          onClick={() => { fadeOut(); }}
        >
          <span style={{ position: 'absolute', visibility: 'hidden' }}>{STRINGS.dismiss}</span>
          <CloseIcon />
        </button>
      )}
      <TimerBar percentage={timeRemaining / timeout} />
    </div>
  );
}
Toast.propTypes = {
  text: propTypes.string.isRequired,
  dismissable: propTypes.bool,
  timestamp: propTypes.number.isRequired,
  timeout: propTypes.number,
  type: TYPES,
};

export default function Toaster({ anchor = 'topLeft', children = null }) {
  const [toasts, setToasts] = useState([]);
  const addToast = (newToast) => {
    const date = new Date();
    const timestamp = date.getTime();
    setToasts([...toasts, { ...newToast, timestamp }]);
  };
  const removeToast = (timestamp) => {
    const index = toasts.findIndex((toast) => toast.timestamp === timestamp);
    const preceding = toasts.slice(0, index);
    const following = toasts.slice(index + 1);
    setToasts([...preceding, ...following]);
  };

  const value = useMemo(() => ({
    toasts,
    addToast,
    removeToast,
  }), [toasts]);

  return (
    <toasterContext.Provider value={value}>
      <div className={`dmm-toaster-wrapper ${anchor}`}>
        {toasts.map((toast) => <Toast {...toast} key={toast.timestamp} />)}
      </div>
      {children}
    </toasterContext.Provider>
  );
}
Toaster.propTypes = {
  children: propTypes.node,
  anchor: propTypes.oneOf([
    'topRight',
    'bottomRight',
    'bottomLeft',
    'topLeft',
  ]),
};
