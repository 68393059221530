import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PageTitle from '../../../core/Display/PageTitle';
import TextField from '../../../core/Input/TextField';

import './ReportTitle.css';

const SAVE_TEXT = 'Save Title';
const EDIT_TEXT = 'Edit Title';

function ReportTitle({
  text = '',
  canEdit = false,
  onChange = () => {},
}) {
  const [display, setDisplay] = useState(text);

  const textField = (
    <TextField
      className="report-title-input"
      value={display}
      onChange={(e) => { setDisplay(e.target.value); onChange(e.target.value); }}
      placeholder="Enter a report title here..."
      fullWidth
    />
  );
  const textDisplay = <PageTitle text={display} />;

  return canEdit ? textField : textDisplay;
}

export { SAVE_TEXT, EDIT_TEXT };
export default ReportTitle;

ReportTitle.propTypes = {
  text: PropTypes.string,
  canEdit: PropTypes.bool,
  onChange: PropTypes.func,
};
