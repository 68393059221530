import NONE from '../../../constants';

/* eslint camelcase: off */
/* This code handles input from the backend, which is snake case, as the backend
 * is written in Python.
 */

function isOptional(question) { return Boolean(question.optional); }
function isAnswered(question) {
  const { value } = question;
  if (value === undefined) return false;
  if (value === NONE) return false;
  if (value === 'default') return false;
  if (value === '') return false;
  return true;
}

function removeUnansweredOptionalQuestions(questions) {
  const cleanedQuestions = {};
  const questionKeys = Object.keys(questions);
  questionKeys.forEach((key) => {
    const question = questions[key];
    if (isOptional(question) && !isAnswered(question)) return;
    cleanedQuestions[key] = { ...question };
  });
  return cleanedQuestions;
}

export default function questionsToQuery(allQuestions) {
  const questions = removeUnansweredOptionalQuestions(allQuestions);
  const pathPresort = [];
  const params = {};
  Object.keys(questions).forEach((fld_nm) => {
    const { param_type, value, index } = questions[fld_nm];
    switch (param_type) {
      case 'path':
        pathPresort.push([index, value]);
        break;
      default:
        if (['default', NONE, '', []].includes(value)) break;
        if (Array.isArray(value) && !value.length) break;
        if (Array.isArray(value)) params[fld_nm] = value.join();
        else params[fld_nm] = value;
    }
  });
  const path = pathPresort
    .sort()
    .map((answer) => answer[1])
    .join('/');
  return [path, params];
}

export function questionsToQueryString(allQuestions) {
  const questions = removeUnansweredOptionalQuestions(allQuestions);
  const keys = Object.keys(questions);
  const pathKeys = [];
  const query = keys.map((key) => {
    const { value, param_type: paramType } = questions[key];
    if (value === undefined) return undefined;
    if (value === 'default') return undefined;
    if (Array.isArray(value) && ['', 'default'].includes(value.join())) return undefined;
    if (paramType === 'path') pathKeys.push(key);
    return `${key}=${value}`;
  }).filter((string) => string !== undefined);
  if (pathKeys.length) {
    query.push(`path_keys=${JSON.stringify(pathKeys)}`);
  }
  return '?'.concat(query.join('&'));
}
