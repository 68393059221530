import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';

function SectionTitle({ text }) {
  return (
    <Text sx={{ verticalAlign: 'center' }} variant="h5">
      <h5>{text}</h5>
    </Text>
  );
}

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SectionTitle;
