import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../core/Display/Table';

import SectionAnalysis from '../Common/SectionAnalysis';

function HigherMomentsView({
  canEdit = false,
  dataSet = {},
  userText = '',
  onUserTextChange = () => {},
}) {
  const sets = ['var', 'skew', 'kurt'];
  const tables = sets.map((set) => dataSet[set]
    .split('\n')
    .map((string) => string.split('\t')));
  const columns = [];
  tables.forEach((table) => {
    const [headers] = table;
    headers.forEach((header, index) => {
      if (columns.find(({ name }) => header === name)) return;
      const newColumn = {
        name: header,
        data: table.slice(1).map((row) => row[index]),
      };
      columns.push(newColumn);
    });
  });
  const tableColumns = columns.map(({ name }) => ({
    id: name,
    name,
    type: name === 'Data' ? 'string' : 'float',
  }));
  const tableRows = columns[0].data.map((cell, cellIndex) => {
    const row = {};
    if (!cell) return null;
    columns.forEach(({ name, data }) => {
      row[name] = data[cellIndex];
    });
    return row;
  }).filter((row) => row !== null);

  return (
    <>
      <SectionAnalysis
        canEdit={canEdit}
        content={userText}
        onChange={onUserTextChange}
      />
      <Table
        keyFieldName="Data"
        columns={tableColumns}
        rows={tableRows}
      />
    </>
  );
}

export default HigherMomentsView;

HigherMomentsView.propTypes = {
  canEdit: PropTypes.bool,
  dataSet: PropTypes.shape({
    var: PropTypes.string.isRequired,
    skew: PropTypes.string.isRequired,
    kurt: PropTypes.string.isRequired,
  }),
  userText: PropTypes.string,
  onUserTextChange: PropTypes.func,
};
