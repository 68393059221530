import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import './ZoomControl.css';

const LOCAL_STORAGE_NAME = 'dashboard-zoom-level';
export default function ZoomControl({ zoomLevel, setZoomLevel }) {
  useEffect(() => {
    const storedLevel = Number(localStorage.getItem(LOCAL_STORAGE_NAME));
    if (Number.isNaN(storedLevel)) return;
    setZoomLevel(storedLevel);
  }, []);

  return (
    <div className="zoom-control-wrapper">
      <AddIcon />
      <div className="range-wrapper">
        <input
          className="zoom-control"
          type="range"
          id="zoomLevel"
          name="zoomLevel"
          min={0}
          max={100}
          value={zoomLevel}
          step={1}
          onChange={({ target }) => {
            const newLevel = Number(target.value);
            setZoomLevel(newLevel);
            localStorage.setItem(LOCAL_STORAGE_NAME, newLevel);
          }}
        />
      </div>
      <RemoveIcon />
    </div>
  );
}
ZoomControl.propTypes = {
  zoomLevel: propTypes.number.isRequired,
  setZoomLevel: propTypes.func.isRequired,
};
