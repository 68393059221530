/*
 * Creates a download link and saves it to the user's browser.
 */
function downloadCSV(csv, filename) {
  // TODO: Is directly manipulating the DOM good in react?
  const csvFile = new Blob([csv], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.download = filename;
  const inTest = !window.URL.createObjectURL;
  if (inTest) return;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
}

function quotesAroundCommas(data) {
  return String(data).includes(',') ? `"${data}"` : String(data);
}

function handleArrayRow(dataRow) {
  return dataRow.map((cell) => quotesAroundCommas(cell));
}

function handleObjectRow(cols, row) {
  return cols.map(({ id }) => quotesAroundCommas(row[id]));
}

/*
 * Finds a table on the page and returns it as a CSV string.
 */
export function getCSVText(cols, data) {
  const csv = [];

  const headers = [];
  cols.forEach((col) => {
    headers.push(quotesAroundCommas(col.name));
  });
  csv.push(headers.join(','));

  data.forEach((dataRow) => {
    if (Array.isArray(dataRow)) csv.push(handleArrayRow(dataRow));
    else csv.push(handleObjectRow(cols, dataRow));
  });

  return csv.join('\n');
}

/*
 * Finds a table on the page and downloads it as a CSV.
 */
export default async function exportTableAsCSV(filename, cols, data) {
  return new Promise((resolve, reject) => {
    if (!Array.isArray(cols)) { reject(Error('cols is not an array')); }
    if (!Array.isArray(data)) { reject(Error('data is not an array')); }
    const csvText = getCSVText(cols, data);
    downloadCSV(csvText, filename);
    resolve();
  });
}
