import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryIcon from '@mui/icons-material/History';
import DownloadIcon from '@mui/icons-material/Download';

import Dialog from '../../../../core/Display/Dialog';
import InlineMessage from '../../../../core/Display/InlineMessage';
import Button from '../../../../core/Input/Button';
import { toasterContext } from '../../../../core/Display/Toaster';

import { capitalizeString } from '../../../../core/Utils/stringUtils';
import { fetchManuFile } from '../../../../utils/networkUtils';

import { MANUSCRIPT_PROPS } from './dashboardProps';
import './Dashboard.css';
import ManuscriptActions, { transformManuReferees } from './ManuscriptActions';
import stringifyAuthorNames from './stringifyAuthorNames';

import './ManuscriptDetails.css';

function renderName(name) { return (name ? name.replaceAll('-', ' ') : ''); }

const manuscriptDetailsContext = createContext({});
const VIEWS = {
  overview: 'Overview',
  content: 'Content',
  history: 'History',
};

function Tabs({ view, setView }) {
  return (
    <ul className="tabs">
      {Object.entries(VIEWS).map(([key, value]) => (
        <li>
          <button
            type="button"
            key={key}
            className={value === view ? 'tab-selector current' : 'tab-selector'}
            onClick={() => { setView(value); }}
          >
            {value}
          </button>
        </li>
      ))}
    </ul>
  );
}
Tabs.propTypes = {
  view: propTypes.oneOf(Object.values(VIEWS)).isRequired,
  setView: propTypes.func.isRequired,
};

function Overview() {
  const {
    manuscript,
    refreshManuscripts,
    refreshReferees,
    column,
    actions,
    allReferees,
    timeUpdated,
    viewContent,
    viewHistory,
    closeWindow,
    peopleQuestions,
  } = useContext(manuscriptDetailsContext);
  const {
    _id: id,
    state,
    referees,
  } = manuscript;

  const { addToast } = useContext(toasterContext);

  const downloadContent = () => {
    fetchManuFile(id)
      .then(() => {
        addToast({ type: 'success', text: 'File downloading.' });
      })
      .catch(() => {
        addToast({ type: 'error', text: 'No file to download.' });
      });
  };

  return (
    <>
      <section className="abstract">
        <h3>Abstract</h3>
        <div>
          <Markdown>{manuscript.abstract}</Markdown>
        </div>
        <div className="abstract-buttons">
          <Button
            onClick={viewContent}
            startIcon={<VisibilityIcon />}
          >
            View Full Manuscript
          </Button>
          <Button
            onClick={downloadContent}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </div>
      </section>
      <section className="status">
        <h3>Status</h3>
        <div>{renderName(state)}</div>
        <span>
          {'Last updated: '}
          <time>
            {timeUpdated && timeUpdated.toLocaleDateString('en-US')}
          </time>
          <Button variant="icon" onClick={viewHistory}>
            <HistoryIcon />
          </Button>
        </span>
      </section>
      {Array.isArray(referees) && referees.length > 0 && (
        <section>
          <h3>Referees</h3>
          {transformManuReferees(allReferees, manuscript.referees).map(({ name }) => (
            <div className="referee">
              {name}
            </div>
          ))}
        </section>
      )}
      <section className="actions">
        <h3>Actions</h3>
        <ManuscriptActions
          manuscript={manuscript}
          column={column}
          refreshManuscripts={refreshManuscripts}
          refreshReferees={refreshReferees}
          actions={actions}
          allReferees={allReferees}
          closeWindow={closeWindow}
          peopleQuestions={peopleQuestions}
        />
      </section>
    </>
  );
}
function BackToOverview() {
  const { viewOverview } = useContext(manuscriptDetailsContext);
  return (
    <div style={{ width: 'fit-content', margin: '2rem 0 2rem auto' }}>
      <Button onClick={viewOverview}>Back to Overview</Button>
    </div>
  );
}
function NoContent() {
  const message = 'This manuscript has no content to display.';
  return <InlineMessage type="error" text={message} />;
}
function Content() {
  const { manuscript } = useContext(manuscriptDetailsContext);
  const { text } = manuscript;
  if (!text || typeof text !== 'string') {
    return (
      <div className="content-wrapper">
        <NoContent />
        <BackToOverview />
      </div>
    );
  }
  return (
    <div className="content-wrapper">
      <Markdown>{text}</Markdown>
      <BackToOverview />
    </div>
  );
}
function getRefereeName(referee, allReferees) {
  try {
    return allReferees[referee].name;
  } catch {
    return 'an unknown person';
  }
}
const HISTORY_ENTRY_PROPS = propTypes.shape({
  new_state: propTypes.string.isRequired,
  action: propTypes.string.isRequired,
  Editor: propTypes.string.isRequired,
  referee: propTypes.string.isRequired,
  state: propTypes.string.isRequired,
});
function HistoryEntryMessage({ entry }) {
  const { allReferees } = useContext(manuscriptDetailsContext);
  const { action, Editor, referee } = entry;
  const refereeName = getRefereeName(referee, allReferees);
  switch (action) {
    case 'submitted': return 'The manuscript was submitted.';
    case 'assign-referee':
      return `${capitalizeString(refereeName)} was assigned to be referee by ${Editor}.`;
    case 'remove-referee':
      return `${capitalizeString(refereeName)} was removed as a referee by ${Editor}.`;
    default: return `Action "${action}" was performed by ${Editor}.`;
  }
}
HistoryEntryMessage.propTypes = { entry: HISTORY_ENTRY_PROPS.isRequired };
function HistoryEntry({ entry, timestamp }) {
  const localeString = new Date(timestamp).toLocaleString();
  return (
    <li className="history-entry">
      <span className="timestamp">{localeString}</span>
      <HistoryEntryMessage entry={entry} />
    </li>
  );
}
HistoryEntry.propTypes = {
  entry: HISTORY_ENTRY_PROPS.isRequired,
  timestamp: propTypes.string.isRequired,
};
function History() {
  const { manuscript } = useContext(manuscriptDetailsContext);
  const { history } = manuscript;
  return (
    <div className="history-wrapper">
      {typeof history !== 'object' && (
        <InlineMessage type="error" text="No history is available for this manuscript." />
      )}
      <ol className="history">
        {typeof history === 'object' && Object.entries(history).map(([timestamp, entry]) => (
          <HistoryEntry
            entry={entry}
            timestamp={timestamp}
            key={timestamp}
          />
        ))}
      </ol>
      <BackToOverview />
    </div>
  );
}

function InvalidView({ view }) {
  return <InlineMessage type="error" text={`Invalid view: ${view}`} />;
}
InvalidView.propTypes = { view: propTypes.oneOf(Object.values(VIEWS)).isRequired };

function View({ view }) {
  switch (view) {
    case VIEWS.overview: return <Overview />;
    case VIEWS.content: return <Content />;
    case VIEWS.history: return <History />;
    default: return <InvalidView view={view} />;
  }
}
View.propTypes = { view: propTypes.oneOf(Object.values(VIEWS)).isRequired };

export default function ManuscriptDetails({
  manuscript,
  setViewedManuscript,
  column = {},
  refreshManuscripts,
  refreshReferees,
  actions,
  allReferees,
  peopleQuestions,
}) {
  if (!manuscript) return <div className="manuscript-wrapper empty" />;

  const [view, setView] = useState(VIEWS.overview);
  const closeWindow = () => { setViewedManuscript(undefined); };

  const timeUpdated = new Date(manuscript.timeUpdated);
  const viewOverview = () => { setView(VIEWS.overview); };
  const viewContent = () => { setView(VIEWS.content); };
  const viewHistory = () => { setView(VIEWS.history); };

  const contextValue = useMemo(() => ({
    manuscript,
    refreshManuscripts,
    refreshReferees,
    column,
    actions,
    allReferees,
    timeUpdated,
    viewOverview,
    viewContent,
    viewHistory,
    closeWindow,
    peopleQuestions,
  }), [manuscript]);

  const {
    title,
    authors,
  } = manuscript;
  const authorNames = stringifyAuthorNames(authors);
  return (
    <Dialog
      title={title}
      subtitle={authorNames}
      closeFn={closeWindow}
      className="manuscript-wrapper"
    >
      <manuscriptDetailsContext.Provider value={contextValue}>
        <Tabs view={view} setView={setView} />
        <View
          view={view}
          manuscript={manuscript}
        />
      </manuscriptDetailsContext.Provider>
    </Dialog>
  );
}
ManuscriptDetails.propTypes = {
  manuscript: MANUSCRIPT_PROPS.isRequired,
  setViewedManuscript: propTypes.func.isRequired,
  column: propTypes.shape({
    text: propTypes.string,
    options: propTypes.instanceOf(Object),
  }),
  refreshManuscripts: propTypes.func.isRequired,
  refreshReferees: propTypes.func.isRequired,
  actions: propTypes.instanceOf(Object).isRequired,
  allReferees: propTypes.instanceOf(Object).isRequired,
  peopleQuestions: propTypes.instanceOf(Object).isRequired,
};
