import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import './OldLineGraph.css';
import './chart-dragdata';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { getColor } from '../../../constants';

import CustomLegend from './CustomLegend';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const borderColor = '#8a9199CC';

const LINE_WIDTH = 1.5;
const lineOptions = (index, reverse) => ({
  position: index > 0 ? 'right' : 'left',
  display: true,
  grid: {
    tickColor: getColor(index + 1),
    tickWidth: LINE_WIDTH,
  },
  ticks: {
    color: getColor(index + 1),
  },
  border: {
    color: getColor(index + 1),
    width: LINE_WIDTH,
  },
  reverse,
});

const manyAxis = (count, setInversions) => {
  const axes = {};
  for (let index = 1; index < count; index += 1) {
    axes[`y${index}`] = lineOptions(index, setInversions[index]);
  }
  return axes;
};

const useMany = (count, multiple, setInversions) => {
  if (multiple) return manyAxis(count, setInversions);
  return {};
};

function getGraphOptions(
  count,
  multiple,
  onDrag,
  onDragEnd,
  setInversions,
) {
  return ({
    responsive: true,
    scales: {
      x: {
        grid: {
          color: borderColor,
        },
      },
      y: { ...lineOptions(0, setInversions[0]) },
      ...useMany(count, multiple, setInversions),
    },
    plugins: {
      dragData: {
        round: 0,
        dragX: true,
        showTooltip: true,
        onDrag,
        onDragEnd,
      },
      legend: {
        display: false,
      },
    },
  });
}

function getSets(data) {
  /*
   * This function is an attempt to make the following line more legible:
     const sets = Object.keys(data[0])
       .map((_, index) => data.map((item) => item[index])).slice(1, Object.keys(data[0]).length);
   */
  const [firstRow] = data;
  const ids = Object.keys(firstRow);
  const sets = ids.map((element, index) => data.map((row) => row[index]));
  return sets.slice(1, ids.length);
}

export default function VariableAxesGraph({
  dataSet = {
    data: [[1, 2, 3]],
    cols: [
      { name: 'a' },
      { name: 'b' },
      { name: 'c' },
    ],
  },
  onDrag = () => {},
  onDragEnd = () => {},
  isMultiAxes = true,
}) {
  const { data, cols } = dataSet;

  // Prevent crash on no data
  if (!data || !data.length || !cols || !cols.length) { return (<div />); }

  const sets = getSets(data);
  const labels = cols.map((col) => col.name);
  const dates = data.map((item) => `${item[0]}`);
  const [enabledSets, setEnabledSets] = useState(cols.map(() => true));
  const [setInversions, setSetInversions] = useState(cols
    .slice(1).map(() => false));

  const width = window.innerWidth;
  const radiusRatio = 0.5;
  const pointRadius = (set) => {
    if (!set) return undefined;
    if (!width) return undefined;
    return (set.length / width > radiusRatio) ? 0 : undefined;
  };

  const graphData = labels.map((label, index) => ({
    label,
    data: sets[index - 1],
    borderColor: getColor(index),
    pointRadius: pointRadius(sets[index - 1]),
  })).slice(1, labels.length);

  const [offsetData, setOffsetData] = useState(graphData);

  const [multiAxisData, setMultiAxisData] = useState(graphData);

  useEffect(() => {
    setOffsetData(graphData);
  }, [dataSet, dataSet.cols, dataSet.data]);

  const toggleSet = (index) => {
    enabledSets[index] = !enabledSets[index];
    setEnabledSets([...enabledSets]);
  };

  const invertSet = (index) => {
    setInversions[index] = !setInversions[index];
    setSetInversions([...setInversions]);
  };

  const getDynamicGraphOptions = () => getGraphOptions(
    graphData.length,
    isMultiAxes,
    onDrag,
    onDragEnd,
    setInversions,
  );

  useEffect(() => {
    if (!isMultiAxes) {
      setMultiAxisData(offsetData);
    } else {
      const temp = offsetData.map((item, index) => ({
        ...item,
        yAxisID: index > 0 ? `y${index}` : 'y',
      }));

      setMultiAxisData(temp);
    }
  }, [offsetData, isMultiAxes]);

  const filterDisabled = (prefilterData) => prefilterData
    .filter((set, index) => enabledSets[index + 1]);

  return (
    <>
      <CustomLegend
        columns={cols}
        enabledSets={enabledSets}
        toggleSet={toggleSet}
        setInversions={setInversions}
        invertSet={invertSet}
        isMultiAxes={isMultiAxes}
      />
      {isMultiAxes && (
        <Graph
          graphData={filterDisabled(multiAxisData)}
          dates={dates}
          options={getDynamicGraphOptions()}
          setInversions={setInversions}
        />
      )}
      {!isMultiAxes && (
        <Graph
          graphData={filterDisabled(offsetData)}
          dates={dates}
          options={getDynamicGraphOptions()}
          setInversions={setInversions}
        />
      )}
    </>
  );
}

VariableAxesGraph.propTypes = {
  dataSet: PropTypes.instanceOf(Object),
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  isMultiAxes: PropTypes.bool,
};

function Graph({
  graphData,
  dates,
  options,
}) {
  if (typeof window !== 'undefined') {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }

  return (
    <div className="graph">
      <Line
        options={options}
        data={{
          labels: dates,
          datasets: graphData.map((set) => {
            const newSet = {
              ...set,
              pointRadius: 0,
            };
            return newSet;
          }),
        }}
      />
    </div>
  );
}

Graph.propTypes = {
  // eslint-disable-next-line
  graphData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
};
