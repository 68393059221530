import React from 'react';
import propTypes from 'prop-types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';

import LoadingAnimation from '../../core/Display/LoadingAnimation';
import InlineMessage from '../../core/Display/InlineMessage';

import './ProgressIndicator.css';

export const PROGRESS_ALT_TEXT = {
  true: 'Finished:',
  false: 'In Progress:',
};

function Icon({ done }) {
  return (
    <>
      {done ? <CheckCircleIcon /> : <SyncIcon className="rotate" />}
      <span className="hidden-text">{PROGRESS_ALT_TEXT[done]}</span>
    </>
  );
}
Icon.propTypes = { done: propTypes.bool.isRequired };

function Step({ step, progress = false }) {
  const type = progress ? 'success' : 'pending';
  return (
    <li>
      <InlineMessage type={type} text={step} />
    </li>
  );
}
Step.propTypes = {
  step: propTypes.string.isRequired,
  progress: propTypes.bool,
};

function ProgressIndicator({ steps = undefined, progress = [] }) {
  if (!steps) return <LoadingAnimation />;
  return (
    <div className="progress-indicator">
      <ul>
        {steps.map((step, index) => (
          <Step
            key={step}
            step={step}
            progress={progress[index]}
          />
        ))}
      </ul>
    </div>
  );
}
ProgressIndicator.propTypes = {
  steps: propTypes.arrayOf(propTypes.string),
  progress: propTypes.arrayOf(propTypes.bool),
};

export default ProgressIndicator;
