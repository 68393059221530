/* GRAMMAR */
/*
expression
  term
  term operator expression
operator
  +
  -
  *
  /
term
  /[0-9]+/
  - term
  ( expression )
*/

/* eslint no-use-before-define: [off, { functions: true }] */

const OPERATOR_REGEX_G = /[+\-*/]/g;

const VARIABLE_REGEX = /[A-Z]/;
const VARIABLE_REGEX_G = new RegExp(`${VARIABLE_REGEX}g`);

export function isOperator(cha) {
  if (typeof cha !== 'string') return false;
  const matches = cha.match(OPERATOR_REGEX_G);
  if (!matches) return false;
  return (matches.length === 1);
}

export function balancedParens(exp) {
  if (!Array.isArray(exp)) { throw new Error('balancedParens expects an array'); }
  let unpaired = 0;
  exp.forEach((cha) => {
    if (cha === '(') { unpaired += 1; }
    if (cha === ')') { unpaired -= 1; }
  });
  return (unpaired === 0);
}

export function parseTerm(term) {
  if (!Array.isArray(term)) { throw new Error('parseTerm expects an array'); }
  if (term.length === 1) return Number(term[0]);
  if (term[0] === '-') return -1 * parseTerm(term.slice(1));
  if (term[0] === '(' && term[term.length - 1] === ')') {
    const inner = term.slice(1, term.length - 1);
    if (balancedParens(inner)) { return parseExpression(inner); }
  }
  return NaN;
}

export function parseExpression(exp) {
  if (!Array.isArray(exp)) { throw new Error('parseExpression expects an array'); }

  let term = parseTerm(exp);
  if (!Number.isNaN(term)) return term;

  term = NaN;
  let operator = '';
  let expression = NaN;
  exp.forEach((cha, idx) => {
    if (!Number.isNaN(term)) { return; }
    if (!isOperator(cha)) { return; }
    const left = exp.slice(0, idx);
    if (!balancedParens(left)) { return; }
    const right = exp.slice(idx + 1);
    expression = parseExpression(right);
    term = parseTerm(left);
    operator = cha;
  });
  let termOperatorExpression = NaN;
  if (!Number.isNaN(term) && operator && !Number.isNaN(expression)) {
    switch (operator) {
      case '/':
        termOperatorExpression = term / expression;
        break;
      case '*':
        termOperatorExpression = term * expression;
        break;
      case '+':
        termOperatorExpression = term + expression;
        break;
      case '-':
        termOperatorExpression = term - expression;
        break;
      default:
        break;
    }
  }
  if (!Number.isNaN(termOperatorExpression)) return termOperatorExpression;

  return NaN;
}

export function stringToCharArr(string) {
  const arr = string
    .toUpperCase()
    .replace(VARIABLE_REGEX_G, ' $& ')
    .replace(OPERATOR_REGEX_G, ' $& ')
    .replace(/[()]/g, ' $& ')
    .split(' ')
    .filter((cha) => (cha !== ''))
    .map((cha) => {
      if (Number(cha)) { return Number(cha); }
      return cha;
    });
  return arr;
}

export function parseStringExpression(string, vars = {}) {
  const varArr = stringToCharArr(string);
  const constArr = [];
  varArr.forEach((cha) => {
    if (typeof cha === 'string' && cha.match(VARIABLE_REGEX)) {
      if (!Object.keys(vars).includes(cha)) {
        throw new Error(`Invalid variable name: ${cha}`);
      }
      constArr.push(vars[cha]);
      return;
    }
    constArr.push(cha);
  });
  return parseExpression(constArr);
}
