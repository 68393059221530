import ReactGA from 'react-ga4';
/* eslint import/prefer-default-export: off */

export const useGoogleAnalyticsTracker = (category) => {
  const errorMessage = (name) => `useGoogleAnalyticsTracker requires varable ${name} to be truthy.`;

  if (!category) throw new Error(errorMessage('category'));

  const eventTracker = (action, label) => {
    if (!action) throw new Error(errorMessage('action'));
    if (!label) throw new Error(errorMessage('label'));
    ReactGA.event({ category, action, label });
  };

  return eventTracker;
};
