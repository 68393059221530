import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Navigate,
  Link,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import ArrowLeft from '@mui/icons-material/ArrowLeft';

import PageTitle from '../../../core/Display/PageTitle';

import Logo from '../../../images/LogoColor';
import BACKGROUND from '../../../images/sfa-variant/SFA_JournalBG_blurred.jpg';
import BackgroundImage from '../../Common/BackgroundImage';

import UserContext from '../../../UserContext';
import SecurityContext from '../../SecurityContext';

import Submissions from './Submissions';
import Dashboard, { People } from './Dashboard';
import EditableText from './EditableText';
import Masthead from './Masthead';

import './Journal.css';

const PAGES = [
  'Home',
  'Masthead',
  'Back Issues',
  'Submissions',
];
const getPages = (isLoggedIn = false) => {
  if (!isLoggedIn) return PAGES;
  return [...PAGES, 'Dashboard'];
};
const toURL = (string) => String(string).replaceAll(' ', '_').toLowerCase();

function Home() {
  return (
    <>
      <PageTitle text="Home" />
      <EditableText specifiedItem="Home" noTitle />
    </>
  );
}
function BackIssues() {
  return (
    <>
      <PageTitle text="Back Issues" />
      <EditableText specifiedItem="Back Issues" noTitle />
    </>
  );
}

function Wrapper() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { setPage } = useContext(SecurityContext);
  const { receivedURLs, userID } = useContext(UserContext);

  // const isEditor = [
  //   'samuelmebersole@gmail.com',
  //   'elen.callahan@structuredfinance.org',
  //   'gcallah@mac.com',
  //   'bk1nyc@gmail.com',
  // ].includes(userID);

  useEffect(() => { setPage('journal'); }, [receivedURLs]);

  return (
    <BackgroundImage color="var(--color-primary)" className="journal" image={BACKGROUND}>
      <div className="header">
        <div className="left-side">
          <Logo color="white" />
        </div>
        <div className={`right-side ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
          <div className="journal-text">
            <span>Journal</span>
            <button type="button" onClick={() => { setMenuOpen(!menuOpen); }}>
              Journal
              <ArrowLeft />
            </button>
          </div>
          <ul>
            {getPages(userID).map((name) => (
              <li key={name}>
                <Link to={toURL(name)} onClick={() => { setMenuOpen(false); }}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Outlet />
    </BackgroundImage>
  );
}

function Journal() {
  return (
    <Routes>
      <Route path="" element={<Wrapper />}>
        <Route index element={<Navigate to="home" />} />
        <Route path="*" element={<Navigate to="home" />} />
        <Route path="home" element={<Home />} />
        <Route path="back_issues" element={<BackIssues />} />
        <Route path="submissions" element={<Submissions />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard/people" element={<People />} />
        <Route path="masthead" element={<Masthead />} />
      </Route>
    </Routes>
  );
}

export default Journal;
