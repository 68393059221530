import React from 'react';
import propTypes from 'prop-types';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import './Checkbox.css';

export const getTextColor = (highlighted) => ({
  color: highlighted ? 'var(--color-primary)' : undefined,
});

export function CheckboxIcon({ checked }) {
  const svg = checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
  return <div className="input-checkbox" style={getTextColor(checked)}>{svg}</div>;
}
CheckboxIcon.propTypes = { checked: propTypes.bool.isRequired };

function Checkbox({
  id,
  label = '',
  value,
  onChange,
}) {
  return (
    <label
      htmlFor={id}
      className="checkbox-label"
      title={label}
    >
      <input
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        style={{ display: 'none' }}
        aria-label={label}
      />
      <CheckboxIcon checked={value} />
    </label>
  );
}
Checkbox.propTypes = {
  id: propTypes.string.isRequired,
  value: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  label: propTypes.string,
};

export default Checkbox;
