import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Button from '../../core/Input/Button';

import './navbar.css';
import {
  LOGIN_PATH,
  LOGOUT_PATH,
  PROFILE_PATH,
} from '../../constants';
import UserContext from '../../UserContext';

function useOnHoverOutside(ref, handler) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current) { return; }
      if (ref.current.contains(e.target)) { return; }
      handler(e);
    };

    document.addEventListener('mouseover', listener);
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('mouseout', listener);
    };
  }, [ref, handler]);
}

function SignInOutButton({ setNavOpen, onBottom = false }) {
  const { loggedIn } = useContext(UserContext);
  const label = loggedIn() ? 'Profile' : 'Sign in';
  const to = loggedIn() ? PROFILE_PATH : LOGIN_PATH;
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  const expand = () => { setOpen(true); };
  const collapse = () => { setOpen(false); };
  useOnHoverOutside(dropdownRef, collapse);
  const classNames = [
    'sign-out-button',
    'nav-list-dropdown',
    (open ? 'nav-list-open' : 'nav-list-closed'),
  ];

  const dropdown = loggedIn()
    ? (
      <ul className={classNames.join(' ')}>
        <li>
          <Link
            to={LOGOUT_PATH}
            onClick={() => { setNavOpen(false); }}
          >
            Sign out
          </Link>
        </li>
      </ul>
    ) : null;

  return (
    <div
      className="sign-in-out-link-wrapper"
      ref={dropdownRef}
      onFocus={expand}
      onMouseOver={expand}
    >
      {onBottom && dropdown}
      <Link
        className="sign-in-out-link"
        to={to}
        onClick={() => { setNavOpen(false); }}
      >
        <Button
          variant="hollow"
          startIcon={<AccountCircleIcon />}
        >
          {label}
        </Button>
      </Link>
      {!onBottom && dropdown}
    </div>
  );
}

SignInOutButton.propTypes = {
  setNavOpen: PropTypes.func.isRequired,
  onBottom: PropTypes.bool,
};

export default SignInOutButton;
