import React, { useState } from 'react';
import propTypes from 'prop-types';

import {
  formatQuestions,
  questionsToPostParams,
  getErrorMessage,
} from '../../Utils/objectUtils';

import Button from '../../Input/Button';
import ButtonGroup from '../../Input/ButtonGroup';
import Dialog from '../Dialog';
import Questions from '../../Input/Questions';

import LoadingAnimation from '../LoadingAnimation';

import TableHead from './TableHead';
import TableRow from './TableRow';
import columnProps from './columnProps';
import './Table.css';

function RowDetails({ row, columns }) {
  return (
    <table>
      <TableHead columns={columns} />
      <tbody>
        <TableRow row={row} columns={columns} />
      </tbody>
    </table>
  );
}
RowDetails.propTypes = {
  row: propTypes.instanceOf(Object).isRequired,
  columns: propTypes.arrayOf(columnProps).isRequired,
};

const ACTION_STATES = {
  options: 1,
  pending: 2,
  success: 3,
  failure: 4,
};
export default function RowActionDialog({
  title,
  editingRow,
  setEditingRow,
  onSubmit,
  form,
  row,
  columns = [],
  confirmText = 'Confirm',
  showRow = false,
}) {
  const [state, setState] = useState(ACTION_STATES.options);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const getInitialValues = () => {
    const initialQuestions = formatQuestions(form);
    Object.keys(initialQuestions).forEach((key) => {
      initialQuestions[key].value = row[key];
    });
    return initialQuestions;
  };

  const [questions, setQuestions] = useState(getInitialValues());
  const answerQuestion = (fieldName, value) => {
    const newQuestions = { ...questions };
    newQuestions[fieldName].value = value;
    setQuestions(newQuestions);
  };

  const cancel = () => { setEditingRow(); };
  const confirm = () => {
    setState(ACTION_STATES.pending);
    onSubmit(editingRow, questionsToPostParams(questions))
      .then(() => { setState(ACTION_STATES.success); })
      .catch((error) => {
        console.error(error);
        setErrorMessage(getErrorMessage(error));
        setState(ACTION_STATES.failure);
      });
  };

  switch (state) {
    case ACTION_STATES.pending:
      return <Dialog title={title}><LoadingAnimation /></Dialog>;
    case ACTION_STATES.failure:
      return (
        <Dialog title={title} closeFn={cancel}>
          {'Unsuccessful: '}
          {errorMessage}
        </Dialog>
      );
    case ACTION_STATES.success:
      return (
        <Dialog title={title} closeFn={cancel}>
          Success!
        </Dialog>
      );
    case ACTION_STATES.options:
    default:
      return (
        <Dialog
          title={title}
          closeFn={cancel}
        >
          {showRow && <RowDetails row={row} columns={columns} />}
          <Questions
            questions={questions}
            responseSetter={answerQuestion}
            horizontal
          />
          <ButtonGroup>
            <Button variant="hollow" onClick={cancel}>Cancel</Button>
            <Button onClick={confirm}>{confirmText}</Button>
          </ButtonGroup>
        </Dialog>
      );
  }
}
RowActionDialog.propTypes = {
  editingRow: propTypes.string.isRequired,
  setEditingRow: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  form: propTypes.instanceOf(Object).isRequired,
  row: propTypes.instanceOf(Object).isRequired,
  columns: propTypes.arrayOf(columnProps),
  confirmText: propTypes.string,
  title: propTypes.string.isRequired,
  showRow: propTypes.bool,
};
