import React, { useState } from 'react';

import ObjectArray from '../../core/Input/ObjectArray';

import BackgroundImage from '../Common/BackgroundImage';

const SUBFIELDS = [
  {
    fld_nm: 'name',
    question: 'Author name',
  },
  {
    fld_nm: 'email',
    question: 'Author email address',
  },
];
export default function Mockup() {
  const [value, setValue] = useState([]);
  return (
    <div className="journal">
      <div className="submissions">
        <BackgroundImage>
          <ObjectArray
            id="authors"
            label="Authors"
            value={value}
            onChange={setValue}
            subfields={SUBFIELDS}
          />
        </BackgroundImage>
      </div>
    </div>
  );
}
