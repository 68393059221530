import React from 'react';
import propTypes from 'prop-types';

function Flex({
  children = undefined,
  direction = 'row',
  gap = undefined,
  className = undefined,
  width = undefined,
  sx = {},
}) {
  const style = {
    ...sx,
    display: 'flex',
    width: width ? `${width}%` : '100%',
    flexDirection: direction || 'row',
    gap: gap || 0,
  };
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}

Flex.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  direction: propTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),
  gap: propTypes.number,
  className: propTypes.string,
  width: propTypes.number,
  sx: propTypes.instanceOf(Object),
};

export default Flex;
