import React, { createContext, useContext, useMemo } from 'react';
import propTypes from 'prop-types';

const ParserContext = createContext({});

const ESCAPE_CHARACTER = '\\';

function countCharacter(character, line) {
  let count = 0;
  line.split('').forEach((lineCharacter) => {
    if (lineCharacter === character) count += 1;
  });
  return count;
}

function Break() { return <br data-testid="line break" />; }

function lineIsHeader(line) { return line[0] === '#'; }
function lineIsAlternateHeader(line) { return line.match(/^[=-]+$/); }
function Header({ line }) {
  const words = line.split(' ');
  const level = countCharacter('#', words[0]);
  const content = words.slice(1).join(' ');
  switch (level) {
    case 6: return <h6><Line line={content} /></h6>;
    case 5: return <h5><Line line={content} /></h5>;
    case 4: return <h4><Line line={content} /></h4>;
    case 3: return <h3><Line line={content} /></h3>;
    case 2: return <h2><Line line={content} /></h2>;
    case 1: return <h1><Line line={content} /></h1>;
    default: return line;
  }
}
Header.propTypes = { line: propTypes.string.isRequired };

function splitStrongs(line) { return line.split(/__|\*\*/); }
function hasStrongs(line) { return splitStrongs(line).length > 2; }
function Strongs({ line }) {
  return splitStrongs(line).map((segment, index) => {
    if (index % 2 === 0) return <Line key={segment} line={segment} />;
    return <strong key={segment}><Line line={segment} /></strong>;
  });
}
Strongs.propTypes = { line: propTypes.string.isRequired };

function splitEms(line) {
  const DELIMITERS = ['*', '_'];
  const sections = [];
  let section = '';
  const append = (character) => { section = `${section}${character}`; };
  line.split('').forEach((character, index) => {
    const isOrdinaryCharacter = !DELIMITERS.includes(character) && character !== ESCAPE_CHARACTER;
    if (isOrdinaryCharacter) { append(character); return; }
    if (index === 0) return;
    const previousCharacter = line[index - 1];
    if (previousCharacter === ESCAPE_CHARACTER) { append(character); return; }
    if (character === ESCAPE_CHARACTER) return;
    sections.push(section);
    section = '';
  });
  if (section.length) sections.push(section);
  return sections;
}
function hasEms(line) { return splitEms(line).length > 2; }
function Ems({ line }) {
  return splitEms(line).map((segment, index) => {
    if (index % 2 === 0) return <Line key={segment} line={segment} />;
    return <em key={segment}><Line line={segment} /></em>;
  });
}
Ems.propTypes = { line: propTypes.string.isRequired };

const bracketsClosed = (text) => countCharacter('[', text) - countCharacter(']', text) === 0;
const bracketOpens = (text) => countCharacter('[', text) === 0;
const parenCloses = (text) => countCharacter('(', text) - countCharacter(')', text) === 1;
function splitLinks(line) {
  const sections = [];
  let section = '';

  const appendCharacter = (character) => { section = `${section}${character}`; };
  const endSection = (character) => {
    appendCharacter(character);
    sections.push(section);
    section = '';
  };
  const newSection = (character) => { sections.push(section); section = character; };

  line.split('').forEach((character) => {
    switch (character) {
      case '[':
        if (bracketOpens(section)) {
          newSection(character);
        } else {
          appendCharacter(character);
        }
        return;
      case ')':
        if (bracketsClosed(section) && parenCloses(section)) {
          endSection(character);
        } else {
          appendCharacter(character);
        }
        return;
      default: appendCharacter(character);
    }
  });
  if (section.length) sections.push(section);

  return sections;
}
function hasLinks(line) { return splitLinks(line).length > 1; }
function sectionIsLink(section) {
  return section[0] === '[' && section[section.length - 1] === ')';
}
export function interpretLink(markdown) {
  let text = '';
  let url = '';
  let title = '';
  const textClosed = () => countCharacter('[', text) > 0
    && (countCharacter('[', text) - countCharacter(']', text) === 0);
  const urlClosed = () => url[url.length - 1] === ' ';
  const assignCharacter = (character) => {
    if (urlClosed()) { title = `${title}${character}`; return; }
    if (textClosed()) { url = `${url}${character}`; return; }
    text = `${text}${character}`;
  };
  markdown.split('').forEach(assignCharacter);
  url = url.slice(1, url.length - 1);
  text = text.slice(1, text.length - 1);
  title = title.slice(1, title.length - 2);
  return { text, url, title };
}
function Link({ markdown }) {
  const { openNewTab } = useContext(ParserContext);
  const { text, url, title } = interpretLink(markdown);
  const newTab = typeof openNewTab === 'function'
    ? openNewTab(url, text)
    : openNewTab;
  /* eslint jsx-a11y/anchor-is-valid: off */
  /* If there isn't a proper href, I don't want this to behave like a link. - Sam */
  return (
    <a
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
      href={url}
      title={title}
    >
      {text}
    </a>
  );
  /* eslint jsx-a11y/anchor-is-valid: error, react/jsx-props-no-spreading: error */
}
Link.propTypes = { markdown: propTypes.string.isRequired };
function Links({ line }) {
  const sections = splitLinks(line);
  return (
    <>
      {sections.map((section) => {
        if (sectionIsLink(section)) return <Link markdown={section} key={section} />;
        return <Line line={section} key={section} />;
      })}
    </>
  );
}
Links.propTypes = { line: propTypes.string.isRequired };

function removeEscapes(line) {
  return line.split('').filter((character) => character !== ESCAPE_CHARACTER).join('');
}
function Line({ line }) {
  if (lineIsHeader(line)) return <Header line={line} />;
  if (hasLinks(line)) return <Links line={line} />;
  if (hasStrongs(line)) return <Strongs line={line} />;
  if (hasEms(line)) return <Ems line={line} />;
  if (line.includes('<br>')) {
    return (
      <>
        {line.split('<br>').map((segment, index) => (
          <React.Fragment key={segment}>
            {index > 0 && <Break />}
            <Line line={segment} />
          </React.Fragment>
        ))}
      </>
    );
  }
  if (line.substring(line.length - 2) === '  ') {
    return (
      <>
        <Line line={line.substring(0, line.length - 2)} />
        <Break />
      </>
    );
  }
  return removeEscapes(`${line} `);
}
Line.propTypes = { line: propTypes.string.isRequired };

const NUMBERS = '1234567890'.split('');
const ORDERED_LIST_DELIMITERS = [...NUMBERS, '.'];
const UNORDERED_LIST_DELIMITERS = ['-', '*', '+'];
const WHITESPACE = [' ', '\t'];
function listDepth(line) {
  let depth = 0;
  const permittedCharacters = [
    ...WHITESPACE,
    ...ORDERED_LIST_DELIMITERS,
    ...UNORDERED_LIST_DELIMITERS,
  ];
  for (let index = 0; index < line.length; index += 1) {
    const character = line[index];
    const asterisksInLine = line.match(/\*/g);
    const asteriskCount = asterisksInLine === null
      ? 0
      : asterisksInLine.length;
    const leadingAsteriskMarksEmphasis = asteriskCount % 2 === 0;
    if (character === '*' && leadingAsteriskMarksEmphasis) return depth;
    if (permittedCharacters.includes(character)) depth += 1;
    else return depth;
  }
  return depth;
}
export function listDepths(lines) {
  return lines.map((line) => [line, listDepth(line)]);
}
function lineIsListItem(line) {
  const trimmedLine = String(line).trim();
  const isUnorderedListItem = ['-', '*', '+'].includes(trimmedLine[0]);
  if (isUnorderedListItem) return true;

  const [firstWord] = trimmedLine.split('.');
  let firstWordIsAllDigits = true;
  firstWord.split('').forEach((character) => {
    if (!NUMBERS.includes(character)) firstWordIsAllDigits = false;
  });
  const periodFollowsFirstWord = trimmedLine.length > firstWord.length
    && trimmedLine[firstWord.length] === '.';
  const isOrderedListItem = firstWordIsAllDigits && periodFollowsFirstWord;
  if (isOrderedListItem) return true;

  return false;
}
function trimListDelimiting(line, charactersToTrim = line.length) {
  let trimmedLine = line;
  while (trimmedLine.length > line.length - charactersToTrim) {
    const firstCharacter = trimmedLine[0];
    const isDelimiter = UNORDERED_LIST_DELIMITERS.includes(firstCharacter)
      || ORDERED_LIST_DELIMITERS.includes(firstCharacter);
    const isWhitespace = WHITESPACE.includes(firstCharacter);
    if (isDelimiter || isWhitespace) trimmedLine = trimmedLine.slice(1);
    else return trimmedLine;
  }
  return trimmedLine;
}

function splitChunkAroundList(lines) {
  const preList = [];
  const list = [];
  const postList = [];
  const depths = listDepths(lines);
  depths.forEach(([line, depth]) => {
    if (postList.length > 0) return postList.push(line);
    if (depth > 0) {
      if (list.length > 0 || lineIsListItem(line)) return list.push(line);
    }
    if (list.length > 0) return postList.push(line);
    return preList.push(line);
  });
  return [preList, list, postList];
}

function ListWrapper({ type = 'ul', children = null }) {
  return type === 'ul'
    ? <ul>{children}</ul>
    : <ol>{children}</ol>;
}
ListWrapper.propTypes = {
  type: propTypes.oneOf(['ul', 'ol']),
  children: propTypes.node,
};

function List({ lines }) {
  const depth = listDepth(lines[0]);
  const isOrderedList = NUMBERS.includes(lines[0][0]);
  let lastSection = {
    lines: [],
    isListDepth: true,
  };
  const sections = [];

  lines.forEach((line) => {
    const lineDepth = listDepth(line);
    const isListDepth = lineDepth === depth;
    if (isListDepth === lastSection.isListDepth) {
      lastSection.lines.push(line);
    } else {
      sections.push(lastSection);
      lastSection = {
        lines: [line],
        isListDepth,
      };
    }
  });
  sections.push(lastSection);

  return (
    <ListWrapper type={isOrderedList ? 'ol' : 'ul'}>
      {sections.map((section) => {
        if (section.isListDepth) {
          return section.lines
            .map((line) => <li key={line}><Line line={trimListDelimiting(line)} /></li>);
        }
        return <List key={lines.join()} lines={section.lines} />;
      })}
    </ListWrapper>
  );
}
List.propTypes = { lines: propTypes.arrayOf(propTypes.string).isRequired };

function Chunk({ lines }) {
  if (lines.length === 0) return null;

  const lastLine = lines[lines.length - 1];
  if (lines.length === 1 && lineIsHeader(lastLine)) return <Header line={lastLine} />;
  if (lineIsAlternateHeader(lastLine)) {
    return lastLine[0] === '='
      ? <h1>{lines.slice(0, lines.length - 1).map((line) => <Line key={line} line={line} />)}</h1>
      : <h2>{lines.slice(0, lines.length - 1).map((line) => <Line key={line} line={line} />)}</h2>;
  }

  const [preList, list, postList] = splitChunkAroundList(lines);
  if (list.length > 0) {
    return (
      <>
        <Chunk lines={preList} />
        <List lines={list} />
        <Chunk lines={postList} />
      </>
    );
  }

  /* Eventually I would prefer to use <section> tags instead of <div> tags,
   * but just for a quick fix to prevent invalid DOM nesting, I'm changing
   * from <p> to <div>. - Sam
   * */
  return <div>{lines.map((line) => <Line key={line} line={line} />)}</div>;
}
Chunk.propTypes = { lines: propTypes.arrayOf(propTypes.string).isRequired };

function createChunks(markdown) {
  const lines = String(markdown).split('\n');
  const chunks = [];
  let lastChunk = [];
  lines.forEach((line) => {
    const isChunkDelimiter = !line.length;
    if (isChunkDelimiter) { chunks.push(lastChunk); lastChunk = []; return; }
    lastChunk.push(line);
  });
  chunks.push(lastChunk);
  return chunks.filter((chunk) => chunk.length !== 0);
}

function MarkdownParser({ md, openNewTab = false }) {
  const value = useMemo(() => ({
    openNewTab,
  }), [openNewTab]);
  return (
    <ParserContext.Provider value={value}>
      {createChunks(md).map((chunk, index) => (
        <Chunk
          key={chunk.length ? chunk.join() : index}
          lines={chunk}
        />
      ))}
    </ParserContext.Provider>
  );
}

MarkdownParser.propTypes = {
  md: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.bool,
  ]).isRequired, // The markdown to parse
  openNewTab: propTypes.oneOfType([ // If true (or returns true), a link will open in a new tab
    propTypes.bool,
    propTypes.func,
  ]),
};

export default MarkdownParser;
