import React from 'react';
import PropTypes from 'prop-types';

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import Tooltip from '../../../core/Display/Tooltip';

import { getColor } from '../../../constants';

import Text from '../Text';

function CustomLegend({
  columns,
  enabledSets,
  toggleSet,
  isMultiAxes,
  isBar = false,
  xAxisID = 'date',
  setInversions = () => {},
  invertSet = () => {},
}) {
  const visibleColumns = columns.filter(({ id }) => {
    if (isBar) return id !== xAxisID;
    return true;
  });
  const labels = visibleColumns.map(({
    id,
    name,
    description,
  }, index) => {
    if (id === xAxisID) return null;
    const visible = enabledSets[index];
    return (
      <div key={name} className="legend-label-wrapper">
        <Text variant={visible ? 'graph-legend' : 'graph-legend hidden'}>
          <div
            style={{
              border: `1px solid ${(isBar ? getColor(index + 1) : getColor(index))}`,
              display: 'inline-block',
              height: 'var(--font-size)',
              margin: '0px 5px',
            }}
          />
          <button
            type="button"
            className="legend-label"
            onClick={() => {
              toggleSet(index);
            }}
            title={`Toggle "${name}" visibility`}
          >
            <div className="legend-label-text">
              {name}
            </div>
          </button>
          <Tooltip
            text={description}
            buttonClasses={['legend-tooltip-button']}
          />
          {!isBar && (
            <button
              className="legend-tooltip-button"
              type="button"
              disabled={!isMultiAxes}
              onClick={() => { invertSet(index - 1); }}
              title="Invert line on graph"
            >
              { setInversions[index - 1] ? <TrendingDownIcon /> : <TrendingUpIcon />}
            </button>
          )}
        </Text>
      </div>
    );
  });

  return (
    <div className="legend">
      {labels}
    </div>
  );
}

CustomLegend.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  enabledSets: PropTypes.instanceOf(Array).isRequired,
  setInversions: PropTypes.func,
  toggleSet: PropTypes.func.isRequired,
  invertSet: PropTypes.func,
  isMultiAxes: PropTypes.bool.isRequired,
  isBar: PropTypes.bool,
  xAxisID: PropTypes.string,
};

export default CustomLegend;
