import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { randomString } from '../../Utils/stringUtils';
import {
  deepCopy,
  indexToLetter,
} from '../../Utils/objectUtils';
import { parseStringExpression } from '../../Utils/expressionUtils';

import TextField from '../../Input/TextField';
import Button from '../../Input/Button';
import ButtonGroup from '../../Input/ButtonGroup';
import exportTableAsCSV from '../../Utils/ExportCSV';
import Dialog from '../Dialog';

import './Table.css';
import columnProps from './columnProps';
import tableOptionsProps from './tableOptionsProps';
import RowActionDialog from './RowActionDialog';
import TableHead from './TableHead';
import TableRow from './TableRow';

import TableToolbar from '../TableToolbar';
import PaginationButtons from '../../Input/PaginationButtons';

const columnsProps = propTypes.arrayOf(columnProps);
const rowsProps = propTypes.arrayOf(
  propTypes.instanceOf(Object),
);

function TableFoot({
  startRow,
  setStartRow,
  rowsPerPage,
  tableLength,
}) {
  const prevStartRow = startRow > rowsPerPage
    ? startRow - rowsPerPage : 0;
  const nextStartRow = startRow + rowsPerPage >= tableLength - 1
    ? startRow : startRow + rowsPerPage;
  const prev = () => { setStartRow(Number(prevStartRow)); };
  const next = () => { setStartRow(Number(nextStartRow)); };
  const change = (page) => { setStartRow((page - 1) * rowsPerPage); };

  return (
    <PaginationButtons
      startRow={startRow}
      rowsPerPage={rowsPerPage}
      tableLength={tableLength}
      onNext={next}
      onBack={prev}
      changePage={change}
    />
  );
}
TableFoot.propTypes = {
  startRow: propTypes.number.isRequired,
  setStartRow: propTypes.func.isRequired,
  rowsPerPage: propTypes.number.isRequired,
  tableLength: propTypes.number.isRequired,
};

function TableBody({
  startRow,
  rowsPerPage,
  rows = [],
  columns,
  keyFieldName = undefined,
  options,
  setDeletingRow,
  setEditingRow,
}) {
  return (
    <tbody>
      {rows.slice(startRow, startRow + rowsPerPage).map((row) => (
        <TableRow
          options={options}
          key={keyFieldName ? row[keyFieldName] : JSON.stringify(row)}
          keyFieldName={keyFieldName}
          row={row}
          columns={columns}
          setDeletingRow={setDeletingRow}
          setEditingRow={setEditingRow}
        />
      ))}
    </tbody>
  );
}
TableBody.propTypes = {
  startRow: propTypes.number.isRequired,
  rowsPerPage: propTypes.number.isRequired,
  columns: columnsProps.isRequired,
  rows: rowsProps,
  keyFieldName: propTypes.string,
  options: tableOptionsProps.isRequired,
  setDeletingRow: propTypes.func.isRequired,
  setEditingRow: propTypes.func.isRequired,
};

export function joinRows(rows, columns) {
  const userColumns = columns.filter(({ expression }) => expression);
  const newRows = rows.filter((row) => row).map((row) => {
    const newRow = { ...row };
    const variables = {};
    columns.forEach(({ id }, index) => {
      const letter = indexToLetter(index);
      variables[letter] = row[id];
    });
    userColumns.forEach(({ id, expression }) => {
      try {
        newRow[id] = parseStringExpression(expression, variables);
      } catch {
        newRow[id] = NaN;
      }
    });
    return newRow;
  });
  return newRows;
}

function EditColumnsPanelRow({
  name,
  setName,
  expression = null,
  setExpression,
  letter,
  deleteColumn,
}) {
  const expressionRow = expression !== null;
  const button = expressionRow
    ? (
      <Button
        onClick={deleteColumn}
        variant="icon"
      >
        <CloseIcon />
      </Button>
    ) : null;
  const nameField = expressionRow
    ? (
      <TextField
        value={name !== undefined ? name : name}
        id={`${letter}-name-field`}
        label="Name"
        onChange={({ target }) => { setName(target.value); }}
      />
    ) : name;
  const expressionField = expressionRow
    ? (
      <TextField
        value={expression !== undefined ? expression : expression}
        id={`${letter}-expression-field`}
        label="Expression"
        onChange={({ target }) => { setExpression(target.value); }}
      />
    ) : expression;

  return (
    <div className="edit-columns-panel-row">
      <div className="edit-columnspanel-row-label">
        <div className="edit-columns-panel-row-letter">
          {letter}
        </div>
        <div>
          {nameField}
        </div>
      </div>
      <div>
        {expressionField}
        {button}
      </div>
    </div>
  );
}
EditColumnsPanelRow.propTypes = {
  letter: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  setName: propTypes.func.isRequired,
  expression: propTypes.string,
  setExpression: propTypes.func.isRequired,
  deleteColumn: propTypes.func.isRequired,
};

function EditColumnsPanel({
  open = false,
  cancelEdit,
  saveEdit,
  joinedColumns = [],
}) {
  if (!open) return null;

  const [editedColumns, setEditedColumns] = useState(
    deepCopy(joinedColumns),
  );
  const addColumn = () => {
    editedColumns.push({
      id: randomString(8),
      name: 'New Column',
      expression: '',
    });
    setEditedColumns([...editedColumns]);
  };
  const updateValue = (id, value, key) => {
    editedColumns
      .find(({ id: colID }) => colID === id)[key] = value;
    setEditedColumns([...editedColumns]);
  };
  const deleteColumn = (idToDelete) => {
    setEditedColumns(
      editedColumns.filter(({ id }) => id !== idToDelete),
    );
  };

  const renderColumns = () => editedColumns
    .map((column, index) => {
      const letter = indexToLetter(index);
      const {
        id,
        name,
        expression,
      } = column;
      return (
        <EditColumnsPanelRow
          key={letter}
          name={name}
          setName={(value) => { updateValue(id, value, 'name'); }}
          expression={expression}
          setExpression={(value) => {
            updateValue(id, value, 'expression');
          }}
          letter={letter}
          deleteColumn={() => { deleteColumn(id); }}
        />
      );
    });

  return (
    <Dialog
      closeFn={cancelEdit}
      title="Edit Columns"
      footerContent={(
        <ButtonGroup>
          <Button onClick={cancelEdit} variant="hollow">Cancel</Button>
          <Button
            onClick={() => { saveEdit(editedColumns); }}
          >
            Save
          </Button>
        </ButtonGroup>
      )}
    >
      <div className="edit-columns-panel">
        {renderColumns()}
        <button
          className="edit-columns-panel-row"
          type="button"
          onClick={addColumn}
        >
          <div>
            <AddIcon />
            Add New Column
          </div>
          <div />
        </button>
      </div>
    </Dialog>
  );
}
EditColumnsPanel.propTypes = {
  open: propTypes.bool,
  joinedColumns: propTypes.arrayOf(propTypes.instanceOf(Object)),
  cancelEdit: propTypes.func.isRequired,
  saveEdit: propTypes.func.isRequired,
};

function putStickyColumnsFirst(columns) {
  const stickyColumns = [];
  const nonStickyColumns = [];
  columns.forEach((column) => {
    if (column.sticky) stickyColumns.push(column);
    else nonStickyColumns.push(column);
  });
  return stickyColumns.concat(nonStickyColumns);
}

function getKeyField(keyFieldName, columns) {
  if (keyFieldName) return keyFieldName;
  const keyColumn = columns.find((column) => column.isKeyField);
  return keyColumn ? keyColumn.id : undefined;
}

function Table({
  columns = [],
  userColumns = [],
  rows = [],
  defaultRowsPerPage = 25,
  rowsPerPageOptions = [10, 25, 50, 100, 200],
  keyFieldName = undefined,
  onUserColumnsChange = () => {},
  options = {},
  defaultSortedColumnID = null,
  defaultSortDirection = 'descending',
  filename = 'DataMixMaster-table',
}) {
  if (!columns || columns.length === 0) return null;

  const [editRowForm, setEditRowForm] = useState();
  useEffect(() => {
    if (editRowForm) return;
    if (!(options.allowEdit && options.allowEdit.isEnabled)) return;
    if (typeof options.allowEdit.form === 'object') {
      setEditRowForm(options.allowEdit.form);
      return;
    }
    options.allowEdit.form().then(setEditRowForm);
  }, []);
  const [editingRow, setEditingRow] = useState();
  const [deleteRowForm, setDeleteRowForm] = useState();
  useEffect(() => {
    if (deleteRowForm) return;
    if (!(options.allowDelete && options.allowDelete.isEnabled)) return;
    if (typeof options.allowDelete.form === 'object') {
      setDeleteRowForm(options.allowDelete.form);
      return;
    }
    options.allowEdit.form().then(setDeleteRowForm);
  }, []);
  const [deletingRow, setDeletingRow] = useState();

  const [startRow, setStartRow] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem('rowsPerTablePage'))
    || defaultRowsPerPage,
  );

  const [joinedColumns, setJoinedColumns] = useState(
    [...putStickyColumnsFirst(columns), ...userColumns],
  );
  const joinedRows = useMemo(
    () => joinRows(rows, joinedColumns),
    [rows, joinedColumns],
  );

  const [sort, setSort] = useState({
    column: columns.find(({ id }) => id === defaultSortedColumnID)
      ? defaultSortedColumnID : columns[0].id,
    ascending: defaultSortDirection === 'ascending',
  });
  const sortRows = (sortObject) => {
    const sortFunction = (rowA, rowB) => {
      const cellARaw = rowA[sortObject.column];
      const cellBRaw = rowB[sortObject.column];
      const cellA = Number(cellARaw) || cellARaw;
      const cellB = Number(cellBRaw) || cellBRaw;
      const inverse = 1 - (2 * !sortObject.ascending);
      const nullValues = ['NA', 'None'];
      if (!cellA || nullValues.includes(cellA)) return inverse;
      if (!cellB || nullValues.includes(cellB)) return inverse * -1;
      if (cellA < cellB) return 1 * inverse;
      if (cellA > cellB) return -1 * inverse;
      return 0;
    };
    const newRows = [...joinedRows.sort(sortFunction)];
    return newRows;
  };
  const sortedRows = useMemo(
    () => {
      sortRows({
        column: columns.find(({ id }) => id === defaultSortedColumnID)
          ? defaultSortedColumnID : columns[0].id,
        ascending: defaultSortDirection === 'ascending',
      });
      return sortRows(sort);
    },
    [sort, joinedRows],
  );
  const switchSortDirection = () => {
    const newSort = {
      column: sort.column,
      ascending: !sort.ascending,
    };
    setSort(newSort);
  };
  const switchSortColumn = (column) => {
    const newSort = {
      column,
      ascending: defaultSortDirection === 'ascending',
    };
    setSort(newSort);
  };

  const [editingUserColumns, setEditingUserColumns] = useState(false);
  const startEditUserColumns = () => {
    setEditingUserColumns(true);
  };
  const cancelEditUserColumns = () => {
    setEditingUserColumns(false);
  };
  const saveEditUserColumns = (newColumns) => {
    setJoinedColumns(deepCopy(newColumns));
    setEditingUserColumns(false);
    onUserColumnsChange(newColumns
      .filter(({ expression }) => expression));
  };

  const downloadCSV = () => {
    exportTableAsCSV(filename, joinedColumns, sortedRows);
  };

  useEffect(() => {
    sortRows(sort);
  }, [joinedRows]);

  return (
    <div className="dmm-table-wrapper">
      {deletingRow && (
        <RowActionDialog
          title="Delete Row"
          editingRow={deletingRow}
          setEditingRow={setDeletingRow}
          onSubmit={options.allowDelete.onSubmit}
          form={deleteRowForm}
          row={sortedRows
            .find((row) => row[getKeyField(keyFieldName, joinedColumns)] === deletingRow)}
          columns={columns}
          showRow
        />
      )}
      {editingRow && (
        <RowActionDialog
          title="Edit Row"
          confirmText="Update"
          editingRow={editingRow}
          setEditingRow={setEditingRow}
          onSubmit={options.allowEdit.onSubmit}
          form={editRowForm}
          row={sortedRows
            .find((row) => row[getKeyField(keyFieldName, joinedColumns)] === editingRow)}
        />
      )}
      <TableToolbar
        rowsPerPage={rowsPerPage}
        setStartRow={setStartRow}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        downloadCSV={downloadCSV}
        startEditUserColumns={startEditUserColumns}
        options={options}
      />
      <EditColumnsPanel
        open={editingUserColumns}
        joinedColumns={joinedColumns}
        cancelEdit={cancelEditUserColumns}
        saveEdit={saveEditUserColumns}
      />
      <table>
        <TableHead
          columns={joinedColumns}
          sort={sort}
          switchSortDirection={switchSortDirection}
          switchSortColumn={switchSortColumn}
          options={options}
          keyFieldName={getKeyField(keyFieldName, joinedColumns)}
        />
        <TableBody
          columns={joinedColumns}
          rows={sortedRows}
          startRow={startRow}
          rowsPerPage={rowsPerPage}
          keyFieldName={getKeyField(keyFieldName, joinedColumns)}
          options={options}
          setDeletingRow={setDeletingRow}
          setEditingRow={setEditingRow}
        />
      </table>
      <TableFoot
        startRow={startRow}
        setStartRow={setStartRow}
        rowsPerPage={rowsPerPage}
        tableLength={rows.length}
      />
    </div>
  );
}
Table.propTypes = {
  columns: columnsProps,
  userColumns: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
      expression: propTypes.string,
    }),
  ),
  rows: rowsProps,
  defaultRowsPerPage: propTypes.number,
  rowsPerPageOptions: propTypes.arrayOf(propTypes.number),
  keyFieldName: propTypes.string,
  onUserColumnsChange: propTypes.func,
  options: tableOptionsProps,
  defaultSortedColumnID: propTypes.string,
  defaultSortDirection: propTypes.string,
  filename: propTypes.string,
};

export default Table;
