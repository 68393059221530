/*
 * Takes a number month, 1-12.
 * Returns the name of that month.
 */
export function monthNumToName(month) {
  const names = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  if (!month) return names[0];
  return names[month - 1];
}

/*
 * Takes number month, 1-12.
 * Returns number of days in the given month.
 * Leap years are not accounted for.
 */
export function daysInMonth(month) {
  if (month === 2) { return 28; }
  if (month < 8) { return 30 + (month % 2); }
  return 30 + (1 - (month % 2));
}

/*
 * Takes strings date and fallback, and optional bool defaultEarly.
 * If date is formatted as YYYY, YYYY-MM, or YYYY-MM-DD,
 * returns it converted to YYYY-MM-DD.
 * Otherwise, returns fallback.
 * If defaultEarly, unknown days/months default to 01.
 * Else, they default to 12/the # of days in the month.
 */
export function parseDate(date, fallback = '', defaultEarly = true) {
  if (!date) return fallback;
  if (typeof date !== 'string') return date;
  const hyphenDate = date.replace(/\//g, '-');
  const YEAR_ONLY = /^[0-9]{4}/;
  const YEAR_MONTH = /^[0-9]{4}-[0-9]{1,2}/;
  const YEAR_MONTH_DAY = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/;
  if (YEAR_MONTH_DAY.test(hyphenDate)) { return hyphenDate; }
  if (YEAR_MONTH.test(hyphenDate)) {
    if (defaultEarly) {
      return hyphenDate.concat('-01');
    }
    const month = Number(hyphenDate.split('-')[1]);
    const days = daysInMonth(month);
    return hyphenDate.concat('-', days.toString());
  }
  if (YEAR_ONLY.test(hyphenDate)) {
    if (defaultEarly) {
      return hyphenDate.concat('-01-01');
    }
    return hyphenDate.concat('-12-31');
  }
  return fallback;
}

export function yearMonthDate(dateObject) {
  const year = dateObject.getFullYear();
  const month = `${dateObject.getMonth() + 1}`.padStart(2, '0');
  const date = `${dateObject.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${date}`;
}
