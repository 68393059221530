import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';

function PageTitle({ text }) {
  return (
    <div className="page-title" style={{ margin: '20px 0px' }}>
      <Text variant="h3">
        <h3>
          {text}
        </h3>
      </Text>
    </div>
  );
}

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageTitle;
