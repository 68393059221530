import { indexToOrdinal } from '../../utils/stringUtils';

/* SELECT PAGE */
export const SELECT_INSTRUCTION = 'Select a data source from the list above';
export const SELECT_SOURCES_ARE_INCOMPATIBLE = 'At least two of your chosen sources do not overlap in time. Please ensure that all sources overlap by at least one day.';
export const SELECT_REMOVE_SOURCE = 'Remove sources from report';

export const SELECT_RADIO_OPTIONS = {
  category: 'Select by category',
  category2: 'Select by another category',
  catfunction: 'and/or',
  country: 'Select by country',
  state: 'Select by state',
  stype: 'Select by frequency',
  search: 'Search for a source',
  reset: 'Reset filters',
};
export function SELECT_CATEGORY_DROPDOWN(index) {
  return `Select your ${indexToOrdinal(index)} source's category`;
}
export function SELECT_SEARCH(index) {
  return `Search for your ${indexToOrdinal(index)} source`;
}
export function SELECT_SOURCE_DROPDOWN(index) {
  return `Select your ${indexToOrdinal(index)} source`;
}

export const SHARE_TXT = 'Share Report:';
export const SHARE_BT_TXT = 'Share';
