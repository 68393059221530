import React from 'react';
import propTypes from 'prop-types';

const COLORS = {
  raw: 'var(--color-secondary)',
  aggregate: 'var(--color-secondary)',
};
const WORDS = {
  raw: 'individual',
  aggregate: 'aggregate',
};

function TitlePrompt({ type }) {
  const color = COLORS[type];
  return (
    <h2 style={{ textTransform: 'uppercase', fontSize: '1.2em' }}>
      {'Choose '}
      <span style={{ color }}>{WORDS[type]}</span>
      {' mortgage options'}
    </h2>
  );
}

TitlePrompt.propTypes = {
  type: propTypes.oneOf(['raw', 'aggregate']).isRequired,
};

export default TitlePrompt;
