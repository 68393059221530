import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { toasterContext } from '../../../core/Display/Toaster';
import PageTitle from '../../../core/Display/PageTitle';
import ContentBox from '../../../core/Display/ContentBox';
import InlineMessage from '../../../core/Display/InlineMessage';
import LoadingAnimation from '../../../core/Display/LoadingAnimation';

import Flex from '../../Common/FlexV2';

import { setTitle } from '../../../utils/browserUtils';
import UserContext from '../../../UserContext';
import NONE, { USER_KEY, PAGE_TITLES } from '../../../constants';

// DataViews
import Content from './Content';

import { DISPLAY } from '../constants';
import ReportContext from '../ReportContext';
import PageButtons from '../PageButtons';

// Our *private* name for the index column.
const INT_SERV_ERR = 500;
const NOT_IMPL = 501;

const NO_SOURCES_SELECTED = 'Please select at least one data source before attempting to view a report.';

const NO_DATA_ERR = {
  status: INT_SERV_ERR,
  message: 'There is no data to display.',
};

/*
 * Tells the user there was an error in fetching a data set.
 */
function FetchErrorDisplay({ error }) {
  let errorMessage = '';
  let displayType = 'error_text';
  if (error == null) {
    errorMessage = 'We ran into an unexpected error.';
  } else if (error.status === NOT_IMPL) {
    errorMessage += error.data.message;
    displayType = 'unimplemented';
  } else if (error.status === INT_SERV_ERR) {
    errorMessage += error.data.message;
    displayType = 'int_server_err';
  } else {
    errorMessage = 'There was a problem with this request. Please try again later. ';
    if (error.data) errorMessage += error.data.message;
  }
  return (
    <h3 className={displayType}>
      {errorMessage}
    </h3>
  );
}

/*
 * Makes a Data Fetch request.
 */
async function fetchData(reportURL, user, requestCancel) {
  return axios.get(reportURL, {
    cancelToken: requestCancel.token,
    headers: {
      [USER_KEY]: user[USER_KEY],
    },
  });
}

function handleFetchDataSuccess(resp, setDataSet, setDisplay, setError) {
  if (resp.data.data.length > 0) {
    setDataSet(resp.data);
    setDisplay(DISPLAY.Report);
  } else {
    setDisplay(DISPLAY.Error);
    setError(NO_DATA_ERR);
  }
}

function handleFetchDataError(error, form, setError, setDisplay) {
  if (!axios.isCancel(error)) {
    /*
     * If a cancellation token was received, do nothing, since the back button handles
     * the page change, and there was no unexpected error
     * If anything other than a cancellation token is received,
     * set the status to error
     */
    setError(error);
    setDisplay(DISPLAY.Error);
  }
}

/*
 * Fetches a report using a url. This should be
 * integrated into useFormDataSet after we cut over
 * from "Query" to "Report".
 */
function useReport(
  reportURL,
) {
  const [report, setReport] = useState([]);
  useEffect(() => {
    axios.get(reportURL)
      .then((resp) => setReport(resp.data))
      .catch();
  }, [reportURL]);
  return report;
}

function getAnswersToQuestions(questions) {
  const answers = {};
  Object.keys(questions).forEach((fieldName) => {
    const answer = questions[fieldName].value;
    if (!answer) return;
    if (answer === NONE) return;
    answers[fieldName] = answer;
  });
  return answers;
}

/*
 * Requests and displays DataSet based off client's submitted form.
 */
function ResultPage() {
  setTitle(PAGE_TITLES.CreateReport);
  const [display, setDisplay] = useState(DISPLAY.Pending);
  const [error, setError] = useState({ data: { message: '' } });
  const {
    dataSources,
    analysis,
    setAnalysis,
    questions,
  } = useContext(ReportContext);
  const { addToast } = useContext(toasterContext);
  const { user, receivedURLs } = useContext(UserContext);
  const { getDataURLReport } = useContext(UserContext);
  const [dataSet, setDataSet] = useState([]);
  const [form] = useState(getAnswersToQuestions(questions));

  useEffect(() => {
    if (!receivedURLs) return;
    const reportURL = getDataURLReport(form);
    const requestCancel = axios.CancelToken.source();
    fetchData(reportURL, user, requestCancel)
      .then((resp) => handleFetchDataSuccess(resp, setDataSet, setDisplay, setError))
      .catch((err) => handleFetchDataError(err, form, setError, setDisplay));
  }, [receivedURLs]);

  useEffect(() => {
    if (dataSources.length) return;
    addToast({
      type: 'error',
      text: NO_SOURCES_SELECTED,
    });
  }, []);

  if (!dataSources.length) {
    return <Navigate to="../options" />;
  }
  if (display === DISPLAY.Pending) {
    return (
      <ContentBox>
        <LoadingAnimation />
      </ContentBox>
    );
  }
  if (display === DISPLAY.Error) {
    return (
      <ContentBox>
        <InlineMessage type="error" text={error.message} />
        <br />
        <PageButtons currentPage="results" />
      </ContentBox>
    );
  }
  return (
    <ContentBox>
      <Flex direction="column" gap={5}>
        <PageTitle text={PAGE_TITLES.CreateReport} />
        <Content
          analysis={analysis}
          dataSet={dataSet}
          display={display}
          error={error}
          form={form}
          setDisplay={setDisplay}
          setAnalysis={setAnalysis}
        />
      </Flex>
      <PageButtons currentPage="results" />
    </ContentBox>
  );
}

export default ResultPage;
export { useReport };

FetchErrorDisplay.propTypes = {
  error: PropTypes.instanceOf(Object).isRequired,
};
