import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../../core/Display/Tooltip';
import Text from '../../../../core/Display/Text';
import TextField from '../../../../core/Input/TextField';
import Button from '../../../../core/Input/Button';

import './SectionAnalysis.css';

export const LABEL_TEXT = 'Enter analysis here';

const STRINGS = {
  contentGeneratorText: 'Generate Analysis',
  contentGeneratorTooltip: 'Coming soon: auto-generate an analysis of this data using AI.',
};

function SectionAnalysisContent({
  content = '',
  editing = false,
  onChange = () => {},
}) {
  if (editing) {
    return (
      <TextField
        fullWidth
        multiline
        value={content}
        onChange={onChange}
        placeholder={LABEL_TEXT}
      />
    );
  }
  return (<Text variant="reportSectionAnalysis">{content}</Text>);
}

SectionAnalysisContent.propTypes = {
  content: PropTypes.string,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
};

function SectionAnalysisGenerator() {
  return (
    <Button
      disabled
      variant="hollow"
    >
      {STRINGS.contentGeneratorText}
      <Tooltip
        tooltipIcon="(?)"
        text={STRINGS.contentGeneratorTooltip}
      />
    </Button>
  );
}

function SectionAnalysis({
  canEdit = false,
  content = '',
  onChange = () => {},
}) {
  const [userText, setUserText] = useState(content);

  if (!content && !canEdit) { return null; }
  return (
    <div className="sectionAnalysis">
      <div style={{ flexGrow: 1 }}>
        <SectionAnalysisContent
          content={userText}
          editing={canEdit}
          onChange={(e) => { setUserText(e.target.value); onChange(e.target.value); }}
        />
      </div>
      {canEdit && <SectionAnalysisGenerator />}
    </div>
  );
}

export default SectionAnalysis;

SectionAnalysis.propTypes = {
  canEdit: PropTypes.bool,
  content: PropTypes.string,
  onChange: PropTypes.func,
};
