import React from 'react';
import propTypes from 'prop-types';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Button from '../../Input/Button';
import ButtonGroup from '../../Input/ButtonGroup';

import './Table.css';
import columnProps from './columnProps';
import tableOptionsProps from './tableOptionsProps';
import hasRowActions, { editEnabled, deleteEnabled } from './hasRowActions';
import TableCell from './TableCell';

const columnsProps = propTypes.arrayOf(columnProps);

export default function TableRow({
  row,
  columns,
  options = undefined,
  keyFieldName = '',
  setDeletingRow = () => {},
  setEditingRow = () => {},
}) {
  const editRow = () => { setEditingRow(row[keyFieldName]); };
  const deleteRow = () => { setDeletingRow(row[keyFieldName]); };
  return (
    <tr key={JSON.stringify(row)}>
      {columns.map((column) => (
        <TableCell
          key={`${column.id}-${row[column.id]}`}
          row={row}
          column={column}
        />
      ))}
      {hasRowActions(keyFieldName, options) && (
        <td>
          <ButtonGroup>
            {editEnabled(options) && (
              <Button variant="icon" onClick={editRow} title="Edit row"><EditIcon /></Button>
            )}
            {deleteEnabled(options) && (
              <Button variant="icon" onClick={deleteRow} title="Delete row"><DeleteIcon /></Button>
            )}
          </ButtonGroup>
        </td>
      )}
    </tr>
  );
}
TableRow.propTypes = {
  columns: columnsProps.isRequired,
  row: propTypes.instanceOf(Object).isRequired,
  options: tableOptionsProps,
  keyFieldName: propTypes.string,
  setDeletingRow: propTypes.func,
  setEditingRow: propTypes.func,
};
