import React from 'react';

import MarkdownParser from '../../core/Transform/MarkdownParser';
import ContentBox from '../../core/Display/ContentBox';

import './Solutions.css';

import Text from '../Common/Text';
import Flex from '../Common/FlexV2';

import { setTitle, useScrollbarWidth } from '../../utils/browserUtils';
import solutionsText from '../../text/solutions_text';
import { HOMEPAGE_REPORT, MOBILE } from '../../constants';
import { ReportContextProvider } from '../Report/ReportContext';
import ReportRetriever from '../Report/RerunReport/ReportRetriever';
import Aligner from '../Common/AlignerV2';

/* eslint jsx-a11y/media-has-caption: warn */
function Solutions() {
  setTitle('Solutions');

  const text = <MarkdownParser md={solutionsText} openNewTab />;
  return (
    <div
      className="solutions-wrapper"
      style={{
        width: `calc(100dvw - ${useScrollbarWidth() / 2}px)`,
      }}
    >
      <Flex direction="column">
        <ContentBox classes={['solutions-text']}>
          <div className="interior_margin" direction={MOBILE ? 'column' : 'row'}>
            <Aligner width="40">
              <Text variant="body1">
                {text}
              </Text>
            </Aligner>
          </div>
        </ContentBox>
        <ContentBox classes={['solutions-graph']}>
          <Flex sx={{ justifyContent: 'center' }} classes={['solutions-graph']}>
            <ReportContextProvider>
              <ReportRetriever
                reportid={HOMEPAGE_REPORT}
                graphOnly
              />
            </ReportContextProvider>
          </Flex>
        </ContentBox>
      </Flex>
    </div>
  );
}

export default Solutions;
