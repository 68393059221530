import React, {
  useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';

import PageTitle from '../../core/Display/PageTitle';
import ContentBox from '../../core/Display/ContentBox';
import Dropdown from '../../core/Input/Dropdown';

import { deepCopy, formatQuestions } from '../../core/Utils/objectUtils';
import {
  getAddDataSourceTypes,
  getAddDataSourceQuestions,
  addDataSource,
} from '../../utils/networkUtils';
import { setTitle } from '../../utils/browserUtils';
import UserContext from '../../UserContext';

import Button from '../../core/Input/Button';
import Questions from '../../core/Input/Questions';
import Text from '../../core/Display/Text';
import Dialog from '../../core/Display/Dialog';

import './DataSourceAdder.css';
import NONE, { PAGE_TITLES } from '../../constants';
import BackgroundImage from '../Common/BackgroundImage';
// import BACKGROUND_IMAGE from '../../images/DMM_geohero_02_2X.png';
import BACKGROUND_IMAGE from '../../images/DMM_reporthero_2X.png';

const DEFAULT_SOURCES = [{ id: NONE, name: NONE }];
export const MESSAGES = {
  emptyFields: 'Please fill in all required fields',
  success: 'Data source added!',
};

function SourceDropdown({
  sources, selectedSource, selectedSourceSetter,
}) {
  return (
    <Dropdown
      key="sourceDropdown"
      options={sources}
      value={selectedSource}
      onChange={selectedSourceSetter}
      optional
    />
  );
}

const removeInvisibleQuestions = (questionsArray) => questionsArray
  .filter(({ question }) => question);

function DataSourceAdder() {
  setTitle(PAGE_TITLES.AddDataSource);
  const {
    receivedURLs,
    userID,
  } = useContext(UserContext);
  const [selectedSource, setSelectedSource] = useState('None');
  const [sources, setSources] = useState(deepCopy(DEFAULT_SOURCES));
  const [questions, setQuestions] = useState();
  const [message, setMessage] = useState('');

  const changeSource = (e) => {
    const source = e.target.value;
    setSelectedSource(source);
    getAddDataSourceQuestions(source)
      .then((questionsList) => {
        setQuestions(formatQuestions(removeInvisibleQuestions(questionsList)));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!receivedURLs) return;
    getAddDataSourceTypes()
      .then((sourceList) => {
        const formattedList = Object.keys(sourceList)
          .map((id) => (
            { id, name: sourceList[id] }
          ))
          .reverse();
        setSources(
          deepCopy(DEFAULT_SOURCES).concat(formattedList),
        );
      })
      .catch(() => {});
  }, [receivedURLs]);

  const handleResponseChange = (fieldName, value, typecast) => {
    let newValue = value;
    questions[fieldName].error = (!value && !questions[fieldName].optional);
    const isCSV = (fieldName === 'data' && newValue.type === 'text/csv');
    const isInteger = (typecast === 'int');
    if (isCSV) {
      const reader = new FileReader();
      reader.onload = (e) => {
        questions[fieldName].value = e.target.result;
        setQuestions({ ...questions });
      };
      reader.readAsText(newValue);
      newValue = '';
      return;
    }
    if (isInteger) {
      newValue = parseInt(value, 10);
    }
    questions[fieldName].value = newValue;
    setQuestions({ ...questions });
  };

  const questionAnswered = (question) => question.value && question.value !== NONE;
  const questionUnanswered = (question) => !questionAnswered(question);
  const questionRequired = (question) => !question.optional;
  const onlyRequiredQuestions = (array) => array.filter(questionRequired);
  const onlyAnsweredQuestions = (array) => array.filter(questionAnswered);
  const onlyUnansweredQuestions = (array) => array.filter(questionUnanswered);
  const missingValues = (object) => {
    const array = Object.values(object);
    const questionsMissingAnswers = onlyUnansweredQuestions(array);
    return onlyRequiredQuestions(questionsMissingAnswers);
  };
  const requiredValuesMissing = (object) => missingValues(object).length > 0;

  const updateQuestionsErrors = (object) => {
    const questionsWithError = missingValues(object);
    const newQuestions = { ...object };
    Object.keys(newQuestions).forEach((fieldName) => { newQuestions[fieldName].error = false; });
    questionsWithError.forEach(({ fld_nm: fieldName }) => {
      newQuestions[fieldName].error = true;
    });
    return newQuestions;
  };
  const applyMissingValuesError = () => {
    const newQuestions = updateQuestionsErrors(questions);

    setQuestions(newQuestions);
    setMessage(MESSAGES.emptyFields);
  };

  const extractValues = (object) => {
    const array = Object.values(object);
    const answeredQuestions = onlyAnsweredQuestions(array);
    const valuesToSubmit = { user: userID };
    answeredQuestions.forEach(({ fld_nm: fieldName, value }) => {
      valuesToSubmit[fieldName] = value;
    });
    return valuesToSubmit;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (requiredValuesMissing(questions)) {
      applyMissingValuesError();
      return;
    }

    const valuesToSubmit = extractValues(questions);
    addDataSource(selectedSource, valuesToSubmit)
      .then(() => { setMessage(MESSAGES.success); })
      .catch((err) => {
        try {
          setMessage(err.response.data.message);
        } catch {
          setMessage(err.message);
        }
      });
  };

  return (
    <BackgroundImage image={BACKGROUND_IMAGE} color="#293d87">
      {message && (
        <Dialog
          centerAll
          footerContent={(
            <Button onClick={() => { setMessage(''); }}>
              OK
            </Button>
          )}
        >
          <Text>{message}</Text>
        </Dialog>
      )}
      <div style={{ height: '13rem' }} />
      <ContentBox classes={['glass', 'dataSourceAdder-top', 'full-width']}>
        <PageTitle text={PAGE_TITLES.AddDataSource} />
        <SourceDropdown
          sources={sources}
          selectedSource={selectedSource}
          selectedSourceSetter={changeSource}
        />
      </ContentBox>
      <div style={{ height: '5rem' }} />
      {questions
      && (
        <ContentBox>
          <Questions
            questions={questions}
            responseSetter={handleResponseChange}
            horizontal
            noValidate
          />
          {selectedSource && (
            <Button
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </ContentBox>
      )}
    </BackgroundImage>
  );
}

SourceDropdown.propTypes = {
  sources: PropTypes.instanceOf(Object).isRequired,
  selectedSource: PropTypes.string.isRequired,
  selectedSourceSetter: PropTypes.func.isRequired,
};

export default DataSourceAdder;
