import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/CloudDownload';

import Dialog from '../Dialog';
import LoadingAnimation from '../LoadingAnimation';
import InlineMessage from '../InlineMessage';

import {
  deepCopy,
  formatQuestions,
  questionsToPostParams,
} from '../../Utils/objectUtils';

import Questions from '../../Input/Questions';
import Button from '../../Input/Button';
import ButtonGroup from '../../Input/ButtonGroup';
import Dropdown from '../../Input/Dropdown';

import tableOptionsProps from '../Table/tableOptionsProps';

import '../Table/Table.css';

const ADD_ITEM_STATES = {
  form: 0,
  pending: 1,
  success: 2,
  failure: 3,
};
const ADD_ITEM_STRINGS = {
  title: 'Add item to table',
  success: 'Item added! You may need to refresh the page for your changes to be shown.',
  failure: 'Something went wrong while attempting to add the item.',
};
function getMessage(error) {
  const fallback = ADD_ITEM_STRINGS.failure;
  if (!error) return fallback;
  if (!error.response) return fallback;
  if (!error.response.data) return fallback;
  if (!error.response.data.message) return fallback;
  return error.response.data.message;
}
function AddItemForm({ setAddingItem, form, options }) {
  const [state, setState] = useState(ADD_ITEM_STATES.form);
  const close = () => { setAddingItem(false); };
  const [questions, setQuestions] = useState(form);
  const [errorMessage, setErrorMessage] = useState(ADD_ITEM_STRINGS.failure);

  const answerQuestion = (fieldName, value) => {
    const newQuestions = { ...questions };
    newQuestions[fieldName].value = value;
    setQuestions(newQuestions);
  };
  const submit = () => {
    setState(ADD_ITEM_STATES.pending);
    options.onSubmit(questionsToPostParams(questions))
      .then(() => { setState(ADD_ITEM_STATES.success); })
      .catch((error) => {
        setErrorMessage(getMessage(error));
        setState(ADD_ITEM_STATES.failure);
      });
  };

  switch (state) {
    case ADD_ITEM_STATES.pending:
      return (
        <Dialog title={ADD_ITEM_STRINGS.title}>
          <LoadingAnimation />
        </Dialog>
      );
    case ADD_ITEM_STATES.success:
      return (
        <Dialog title={ADD_ITEM_STRINGS.title} closeFn={close}>
          <InlineMessage type="success" text={ADD_ITEM_STRINGS.success} />
        </Dialog>
      );
    case ADD_ITEM_STATES.failure:
      return (
        <Dialog title={ADD_ITEM_STRINGS.title} closeFn={close}>
          <InlineMessage type="error" text={errorMessage} />
        </Dialog>
      );
    case ADD_ITEM_STATES.form:
    default:
      return (
        <Dialog
          title="Add item to table"
          closeFn={close}
        >
          <Questions questions={questions} responseSetter={answerQuestion} horizontal />
          <ButtonGroup>
            <Button variant="hollow" onClick={close}>Cancel</Button>
            <Button onClick={submit}>Submit</Button>
          </ButtonGroup>
        </Dialog>
      );
  }
}
AddItemForm.propTypes = {
  setAddingItem: propTypes.func.isRequired,
  options: propTypes.shape({
    onSubmit: propTypes.func,
  }).isRequired,
  form: propTypes.instanceOf(Object).isRequired,
};

/* eslint jsx-a11y/label-has-associated-control: off */
/* The label has an associated control
   I don't know what the problem is. */
function TableToolbar({
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
  downloadCSV,
  startEditUserColumns,
  options,
}) {
  const [addingItem, setAddingItem] = useState(false);
  const [addItemForm, setAddItemForm] = useState();

  const addEnabled = options.allowAdd && options.allowAdd.isEnabled;

  useEffect(() => {
    if (addItemForm) return;
    if (!addEnabled) return;

    const { form } = options.allowAdd;
    if (Array.isArray(form)) {
      setAddItemForm(formatQuestions(form));
    } else if (typeof form === 'object') {
      setAddItemForm(deepCopy(form));
    } else if (typeof form === 'function') {
      form()
        .then((response) => { setAddItemForm(formatQuestions(response)); })
        .catch((error) => { console.error(error); });
    }
  }, []);

  const selectID = 'table-pagination-select';
  return (
    <>
      {addingItem && (
        <AddItemForm
          setAddingItem={setAddingItem}
          form={addItemForm}
          options={options.allowAdd}
        />
      )}
      <div className="table-toolbar">
        <div className="table-toolbar-section">
          Show
          <Dropdown
            id={selectID}
            value={rowsPerPage}
            onChange={({ target }) => {
              localStorage.setItem('rowsPerTablePage', target.value);
              setRowsPerPage(Number(target.value));
            }}
            options={rowsPerPageOptions.map((number) => ({
              id: number,
              name: number,
            }))}
          />
          Entries
        </div>
        <ButtonGroup>
          {addEnabled && (
            <Button
              variant="hollow"
              startIcon={<AddIcon />}
              onClick={() => { setAddingItem(true); }}
              omitTextOnMobile
              disabled={!addItemForm}
            >
              Add Item
            </Button>
          )}
          {!options.noCSV && (
            <Button
              variant="hollow"
              onClick={downloadCSV}
              startIcon={<DownloadIcon />}
              omitTextOnMobile
            >
              Download CSV
            </Button>
          )}
          {!options.noEdit && (
            <Button
              variant="hollow"
              onClick={startEditUserColumns}
              startIcon={<EditIcon />}
              omitTextOnMobile
            >
              Edit Columns
            </Button>
          )}
        </ButtonGroup>
      </div>
    </>
  );
}
TableToolbar.propTypes = {
  rowsPerPage: propTypes.number.isRequired,
  setRowsPerPage: propTypes.func.isRequired,
  rowsPerPageOptions: propTypes.arrayOf(propTypes.number).isRequired,
  downloadCSV: propTypes.func.isRequired,
  startEditUserColumns: propTypes.func.isRequired,
  options: tableOptionsProps.isRequired,
};

export default TableToolbar;
