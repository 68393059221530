import React from 'react';
import PropTypes from 'prop-types';

import './Text.css';

function Text({
  classes = [],
  children = [],
  sx = {},
  variant = '',
}) {
  const joinedClasses = ['text'].concat(classes).concat([variant]);
  return (
    <div
      className={joinedClasses.join(' ')}
      style={sx}
    >
      {children}
    </div>
  );
}

Text.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  sx: PropTypes.instanceOf(Object),
  variant: PropTypes.string,
};

export default Text;
