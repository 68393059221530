import React from 'react';
import Spiral from '../../images/Loading.gif';
import './LoadingAnimation.css';

function LoadingSpiral() {
  return (
    <div className="loading-animation-div">
      <img
        src={Spiral}
        className="loading-animation"
        alt="Loading"
      />
    </div>
  );
}

export default LoadingSpiral;
