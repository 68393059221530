const STATUSES = {
  SUBMITTED: 'submitted',
  UNDER_REFEREE: 'with-referee',
  UNDER_REVIEW: 'author-revision',
  COPY_EDITING: 'copy-editing',
  AUTHOR_REVIEW: 'author-review',
  FORMATTING: 'formatting',
  PUBLISHED: 'published',
};

export default STATUSES;
