import React from 'react';
import PropTypes from 'prop-types';

import EmailPage from './EmailPage';
import Dialog from '../../../../core/Display/Dialog';

function EmailPagePopup({
  closeHandler,
  form,
  report,
}) {
  return (
    <Dialog
      closeFn={closeHandler}
      title="Compose Email:"
    >
      <EmailPage
        form={form}
        defaultReportID={report.id}
        title={report.title}
        userText={report.text}
        components={report.components}
        closeHandler={closeHandler}
      />
    </Dialog>
  );
}

export default EmailPagePopup;

EmailPagePopup.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    components: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};
