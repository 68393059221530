/*
 * Contains functions helpful for working with users.
 */

import axios from 'axios';

/*
 * Login
 */
function login(email, password, setUser, setUserID, errorHandler, errorSetter, getLoginURL) {
  axios.post(getLoginURL(), { email, password })
    .then((resp) => resp.data)
    .then((data) => {
      setUserID(email);
      setUser(data);
    })
    .catch((error) => { errorHandler(error, errorSetter); });
}

export { login }; // eslint-disable-line
