import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../UserContext';

import Text from '../Common/Text';
import { LOGOUT_PATH } from '../../constants';

function UserIDView() {
  const { userID } = useContext(UserContext);
  return (
    <Text variant="h6">
      {'Logged in as '}
      {userID}
      {'  '}
      <Link to={LOGOUT_PATH}>
        Sign out
      </Link>
    </Text>
  );
}

export default UserIDView;
