import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  yearMonthDate,
  parseDate,
  monthNumToName,
} from '../../Utils/dateUtils';

import TextField from '../TextField';
import InlineError from '../../Display/InlineMessage';

const LOW_FALLBACK = '1900-01-01';
const HIGH_FALLBACK = '2100-01-01';

function rangeErrorMessage(min, max, date) {
  const minValid = !min || date >= min;
  const maxValid = !max || date <= max;
  if (minValid && maxValid) return '';
  const minName = monthNumToName(min.getMonth() + 1).concat(' ', min.getUTCDate(), ', ', min.getUTCFullYear());
  const maxName = monthNumToName(max.getMonth() + 1).concat(' ', max.getUTCDate(), ', ', max.getUTCFullYear());
  return `Please enter a date between ${minName} and ${maxName}`;
}

function DateField({
  onChange = () => {},
  minDate = LOW_FALLBACK,
  maxDate = HIGH_FALLBACK,
  value,
  label = '',
  helperText = '',
  disabled = false,
}) {
  const [errorMessage, setErrorMessage] = useState(false);
  const min = new Date(parseDate(minDate, '1900-01-01'));
  const max = new Date(parseDate(maxDate, '2100-12-31', false));

  const handleChange = ({ target }) => {
    const { value: newValue } = target;
    if (!newValue) return;
    const valueAsDate = new Date(newValue);
    if (!valueAsDate) return;
    const rangeError = rangeErrorMessage(min, max, valueAsDate);
    if (rangeError) { setErrorMessage(rangeError); return; }
    onChange(yearMonthDate(valueAsDate));
    setErrorMessage('');
  };

  return (
    <div>
      <TextField
        id={label}
        label={label}
        type="date"
        error={Boolean(errorMessage)}
        helperText={helperText}
        defaultValue={value}
        onChange={handleChange}
        disabled={disabled}
      />
      {errorMessage && <InlineError err={errorMessage} />}
    </div>
  );
}

DateField.propTypes = {
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  disabled: PropTypes.bool,
};

export default DateField;
