export function actionEnabled(options, action) {
  if (!options) return false;
  if (!options[action]) return false;
  return options[action].isEnabled;
}
export function editEnabled(options) { return actionEnabled(options, 'allowEdit'); }
export function deleteEnabled(options) { return actionEnabled(options, 'allowDelete'); }
export default function hasRowActions(keyFieldName, options) {
  return Boolean(keyFieldName && (editEnabled(options) || deleteEnabled(options)));
}
