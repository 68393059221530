import React from 'react';
import PropTypes from 'prop-types';

import { useScrollbarWidth } from '../../Utils/browserUtils';

import './ContentBox.css';

function ContentBox({ children = null, classes = [] }) {
  const classNames = ['content-box'].concat(classes);
  const boxWidth = `calc(100dvw - ${useScrollbarWidth() / 2}px)`;
  return (
    <div
      className={classNames.join(' ')}
      style={classNames.includes('full-width') ? {
        width: `${boxWidth}`,
      } : {}}
    >
      {children}
    </div>
  );
}

export default ContentBox;

ContentBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  classes: PropTypes.arrayOf(PropTypes.string),
};
