import { Chart } from 'chart.js';
import { drag, select } from 'd3';

let element;
let yAxisID;
let xAxisID;
let rAxisID;
let curDatasetIndex;
let curIndex;
let eventSettings;
let isDragging = false;

function getSafe(func) {
  try {
    return func();
  } catch (e) {
    return '';
  }
}

function roundValue(value, pos) {
  if (!Number.isNaN(pos)) {
    return Math.round(value * (10 ** pos)) / (10 ** pos);
  }
  return value;
}

function calcPosition(e, chartInstance, datasetIndex, index) {
  let x;
  if (e.touches) {
    x = chartInstance.scales[xAxisID].getValueForPixel(
      e.touches[0].clientX - chartInstance.canvas.getBoundingClientRect().left,
    );
  } else {
    x = chartInstance.scales[xAxisID].getValueForPixel(
      e.clientX - chartInstance.canvas.getBoundingClientRect().left,
    );
  }
  x = roundValue(x, chartInstance.config.options.plugins.dragData.round);
  x = x > chartInstance.scales[xAxisID].max ? chartInstance.scales[xAxisID].max : x;
  x = x < chartInstance.scales[xAxisID].min ? chartInstance.scales[xAxisID].min : x;

  return x - index;
}

const getElement = (e, chartInstance, callback) => {
  [element] = chartInstance.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false);

  if (element) {
    const { index, datasetIndex } = element;
    // save element settings
    eventSettings = getSafe(() => chartInstance.config.options.plugins.tooltip.animation);

    const dataset = chartInstance.data.datasets[datasetIndex];
    const datasetMeta = chartInstance.getDatasetMeta(datasetIndex);
    const curValue = dataset.data[index];
    // get the id of the datasets scale
    xAxisID = datasetMeta.xAxisID;
    yAxisID = datasetMeta.yAxisID;
    rAxisID = datasetMeta.rAxisID;

    // check if dragging the dataset or datapoint is prohibited
    if (dataset.dragData === false
      || (chartInstance.config.options.scales[xAxisID]
        && chartInstance.config.options.scales[xAxisID].dragData === false)
      || (chartInstance.config.options.scales[yAxisID]
        && chartInstance.config.options.scales[yAxisID].dragData === false)
      || (chartInstance.config.options.scales[rAxisID]
        && chartInstance.config.options.scales[rAxisID].rAxisID === false)
      || dataset.data[element.index].dragData === false
    ) {
      element = null;
      return;
    }

    // disable the tooltip animation
    if (
      chartInstance.config.options.plugins.dragData.showTooltip === undefined
      || chartInstance.config.options.plugins.dragData.showTooltip
    ) {
      if (!chartInstance.config.options.plugins.tooltip) {
        chartInstance.config.options.plugins.tooltip = {};
      }
      chartInstance.config.options.plugins.tooltip.animation = false;
    }

    if (typeof callback === 'function' && element) {
      if (callback(e, datasetIndex, index, curValue) === false) {
        element = null;
      }
    }
  }
};

const updateData = (e, chartInstance, pluginOptions, callback) => {
  if (element) {
    curDatasetIndex = element.datasetIndex;
    curIndex = element.index;
    isDragging = true;

    const dataPoint = chartInstance.data.datasets[curDatasetIndex].data[curIndex];
    const { label } = chartInstance.data.datasets[curDatasetIndex];
    const delta = calcPosition(e, chartInstance, curDatasetIndex, curIndex, dataPoint);

    if (!callback || (typeof callback === 'function' && callback(label, delta) !== false)) {
      // chartInstance.data.datasets[curDatasetIndex].data[curIndex] = dataPoint
      // Ensure that chartInstance has backups of undragged data
      if (!('backup_datasets' in chartInstance.data)) {
        chartInstance.data.backup_datasets = JSON.parse(
          JSON.stringify(chartInstance.data.datasets),
        );
      }

      // Drag data
      const backup = chartInstance.data.backup_datasets[curDatasetIndex].data;
      const target = chartInstance.data.datasets[curDatasetIndex].data;
      for (let i = 0; i < target.length; i += 1) {
        // iterate from low to high if delta is negative
        // iterate from high to low if delta is positive
        let origIndex = i;
        if (delta > 0) { origIndex = target.length - i - 1; }
        if (origIndex + delta >= 0 && origIndex + delta < target.length) {
          target[origIndex + delta] = backup[origIndex];
        }
        target[origIndex] = null;
      }
      chartInstance.update('none');
    }
  }
};

// Update values to the nearest values
function applyMagnet(chartInstance, i, j) {
  const pluginOptions = chartInstance.config.options.plugins.dragData;
  if (pluginOptions.magnet) {
    const { magnet } = pluginOptions;
    if (magnet.to && typeof magnet.to === 'function') {
      let data = chartInstance.data.datasets[i].data[j];
      data = magnet.to(data);
      chartInstance.data.datasets[i].data[j] = data;
      chartInstance.update('none');
      return data;
    }
  }
  return chartInstance.data.datasets[i].data[j];
}

const dragEndCallback = (e, chartInstance, callback) => {
  curDatasetIndex = undefined;
  curIndex = undefined;
  isDragging = false;
  // re-enable the tooltip animation
  /* eslint no-param-reassign: off */
  if (chartInstance.config.options.plugins.tooltip) {
    chartInstance.config.options.plugins.tooltip.animation = eventSettings;
    chartInstance.update('none');
  }

  if (typeof callback === 'function' && element) {
    const { datasetIndex } = element;
    const { index } = element;
    const value = applyMagnet(chartInstance, datasetIndex, index);
    return callback(e, datasetIndex, index, value);
  }

  return null;
};

const ChartJSdragDataPlugin = {
  id: 'dragdata',
  afterInit(chartInstance) {
    if (chartInstance.config.options.plugins && chartInstance.config.options.plugins.dragData) {
      const pluginOptions = chartInstance.config.options.plugins.dragData;
      select(chartInstance.canvas).call(
        drag().container(chartInstance.canvas)
          .on('start', (e) => getElement(e.sourceEvent, chartInstance, pluginOptions.onDragStart))
          .on('drag', (e) => updateData(e.sourceEvent, chartInstance, pluginOptions, pluginOptions.onDrag))
          .on('end', (e) => dragEndCallback(e.sourceEvent, chartInstance, pluginOptions.onDragEnd)),
      );
    }
  },
  beforeEvent(chart) {
    if (isDragging) {
      if (chart.tooltip) { chart.tooltip.update(); }
      return false;
    }
    return true;
  },
};

Chart.register(ChartJSdragDataPlugin);

export default ChartJSdragDataPlugin;
