import propTypes from 'prop-types';

const columnProps = propTypes.shape({
  id: propTypes.string, // A unique identifier for cells in this column.
  name: propTypes.string, // The display name.
  markdown: propTypes.oneOfType([ // A truthy value causes cells to be parsed as markdown.
    propTypes.bool,
    propTypes.number,
  ]),
  sticky: propTypes.oneOfType([ // A truthy value keeps the column visible despite scrolling.
    propTypes.bool,
    propTypes.number,
  ]),
  hidden: propTypes.oneOfType([ // A truthy value prevents the column from being rendered.
    propTypes.bool,
    propTypes.number,
  ]),
  width: propTypes.number, // The width of the column in ch. Fits content if undefined.
  link: propTypes.string, // A URL.
  /* hover: gives the content a tooltip which contains the results of a call to the URL
   *        specified in 'link'
   * */
  linkActivate: propTypes.oneOf(['hover', '', undefined]),
  type: propTypes.oneOf([
    undefined, // Defaults to string
    'string',
    'int',
    'float',
    'checkbox', // Renders a checkbox with the onChange prop as its onChange function.
  ]),
  onChange: propTypes.func, // The onChange function for a checkbox.
  /* If this is set, cells display the value that their row's actual value maps to.
   * e.g., if id = 'country' and map = { USA: 'United States of America' }
   *    a row { country: 'USA' } would render the text 'United States of America'
   *    instead of 'USA'
   */
  map: propTypes.instanceOf(Object),
  noSort: propTypes.bool, // If true, disables sorting on the column.
  decimalPlaces: propTypes.number, // How many decimal places to show on floats. 2 by default.
  maxLen: propTypes.number, // Number of characters permitted before content is truncated.
});

export default columnProps;
