import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useIsMounted from './useIsMounted';
import { LOGIN_PATH, USER_KEY } from '../../constants';
import UserContext from '../../UserContext';
import { checkAuth } from '../../utils/networkUtils';

import LoadingAnimation from './LoadingAnimation';

const VALUES = {
  SUCCESS: true,
  FAILURE: false,
  PENDING: 'pending',
};

function handleAuthSuccess(res, setStatus, setValidation) {
  setStatus({ resultStatus: res.status });
  setValidation({ validationStatus: VALUES.SUCCESS });
}

function handleAuthFailure(error, setStatus, setValidation) {
  setStatus({ resultStatus: error });
  setValidation({ validationStatus: VALUES.FAILURE });
}

/*
 * Tells us if we have sufficient info on the user
 * to check authorization status.
 */
function canAuth(user) {
  return user && user[USER_KEY];
}

/*
 * Handle case we cannot authorize a user.
 */
async function handleCannotAuth(setValidation, setStatus) {
  setValidation({ validationStatus: VALUES.FAILURE });
  setStatus({ resultStatus: 'NULL AUTH_KEY VALUE' });
}

/*
 * Checks whether or not the user is authorized via axios get
 */
function authorized(user, setValidation, receivedURLs) {
  const isMounted = useIsMounted();
  const [resultStatus, setStatus] = useState({});
  useEffect(() => {
    if (!canAuth(user)) {
      handleCannotAuth(setValidation, setStatus);
      return;
    }
    if (!receivedURLs) { return; }
    checkAuth(user)
      .then((res) => {
        if (isMounted()) {
          handleAuthSuccess(res, setStatus, setValidation);
        }
      })
      .catch((error) => {
        handleAuthFailure(error, setStatus, setValidation);
      });
  }, [user, isMounted, receivedURLs]);
  return resultStatus;
}

/*
 * Simple version of authorized meant for exporting.
 * In general this file and its usage of axios should be reworked.
*/
export function simpleAuthorized(user, receivedURLs) {
  const [resultStatus, setStatus] = useState(false);
  useEffect(() => {
    if (!canAuth(user) || !receivedURLs) return;
    checkAuth(user)
      .then((resp) => {
        setStatus(resp.status === 200);
      })
      .catch((error) => {
        // TEMPORARY RETURN once again this section should be reworked along with axios
        console.log(error); // eslint-disable-line
        setStatus(false);
      });
  }, [user, receivedURLs]);
  return resultStatus;
}

/*
 * If this component requires authentication and the
 * user is not authorized, redirect to the login page.
 * While the request is being processed, display a blank page.
 */
export default function AuthRedirect({ children }) {
  const {
    user, receivedURLs,
  } = useContext(UserContext);
  const [validation, setValidation] = useState({ validationStatus: VALUES.PENDING });
  const routerLocation = useLocation();
  const redirectLink = `${routerLocation.pathname}${routerLocation.search}`;

  authorized(user, setValidation, receivedURLs);

  switch (validation.validationStatus) {
    case VALUES.SUCCESS:
      return children;
    case VALUES.PENDING:
      return <LoadingAnimation />;
    default:
      return (
        <Navigate
          to={LOGIN_PATH}
          state={{ redirectLink }}
        />
      );
  }
}

AuthRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};
