import React, { useState, useContext } from 'react';
import axios from 'axios';

import ContentBox from '../../core/Display/ContentBox';
import Dialog from '../../core/Display/Dialog';
import TextField from '../../core/Input/TextField';
import Button from '../../core/Input/Button';

import UserContext from '../../UserContext';
import './ResetForm.css';

import { setTitle } from '../../utils/browserUtils';

const SUCCESS_MESSAGE = 'Reset link sent!';

function handleLoginError(error, setMessage) {
  const statusCode = error.response.status;
  if (statusCode >= 400 && statusCode < 500) {
    setMessage(SUCCESS_MESSAGE);
  } else {
    setMessage('Something went wrong. Please try again later.');
  }
}

function sendReset(details, setMessage, getResetTokURL) {
  return axios.get(getResetTokURL(details.email, details.server))
    .then(setMessage(SUCCESS_MESSAGE))
    .catch((error) => { handleLoginError(error, setMessage); });
}

function ResetForm() {
  const [message, setMessage] = useState('');
  const [details, setDetails] = useState({ email: '' });
  const { getResetTokURL } = useContext(UserContext);
  setTitle('Reset Password');

  const submitHandler = async (e) => {
    e.preventDefault();
    sendReset(details, setMessage, getResetTokURL);
  };

  // NOTE: This is sent so that the backend knows what url to make for password reset
  const SERVER_LOC = window.location.origin;

  return (
    <>
      {message !== ''
      && (
        <Dialog
          centerAll
          title={message}
          footerContent={(
            <Button onClick={() => setMessage('')}>OK</Button>
          )}
        />
      )}
      <ContentBox classes={['login_box']}>
        <form onSubmit={submitHandler}>
          <div className="form-inner">
            <ResetFormHdr />
            <TextField
              id="email"
              label="Email Address"
              fullWidth
              onChange={(e) => setDetails({
                email: e.target.value,
                server: SERVER_LOC,
              })}
              value={details.email}
              required
            />
            <Button
              className="button--wide signup__btn"
              type="submit"
              fullWidth
            >
              Send reset link
            </Button>
          </div>
        </form>
      </ContentBox>
    </>
  );
}

function ResetFormHdr() {
  return (
    <h2 className="signup_header">
      Reset your account&#700;s password
    </h2>
  );
}

export default ResetForm;
