const md = `
## Website Terms of Service  
Last modified: November 4, 2023  
  
DataMixMaster, LLC (“DataMixMaster,” “we,” “us,” or “our”) provides www.DataMixMaster.com (the “Site”) and other related sites  and online services, including the DataMixMaster Community, and any tools or services and content or data downloaded or accessed from these sites and services (collectively, the “Service(s)”) to users (each, “you”  or “your”) subject to these Website Terms of Service and any posted guidelines, rules, policies, or service specific terms of use applicable to the Services (collectively, the “Terms”), which may be updated from  time to time. By accessing the Site or Services, you agree to comply with and be bound by the Terms.  
  
### PROHIBITED USE OR CONTENT  

You may not use, or encourage, promote, facilitate or instruct others to use, the Site, Services, or your  Account for any illegal, unlawful, harmful, restricted, or offensive use, or to create, transmit, store, display,  or distribute or otherwise make available content that is illegal, unlawful, harmful, prohibited, or offensive.  Examples of prohibited activities and content include:  

**Illegal activities** - using the Site or Services in any manner that violates any laws or regulations, such as  advertising, transmitting, or otherwise making available gambling sites or services or storing,  disseminating, promoting, or facilitating child pornography.

**Harmful or fraudulent activities** - engaging in activities that may be harmful to others or to DataMixMaster's  operations or reputation, such as offering or disseminating speculative or fraudulent goods, services,  schemes, or promotions; engaging in any deceptive practices or practices that pose or create a privacy or  security risk to any person; attempting to impersonate another person or affiliation with an entity,  including, but not limited to, DataMixMaster; or attempting to circumvent any bans or suspensions by DataMixMaster  moderators or to evade any content filters. 

**Infringing content** - posting any material that you don't have the right to transmit under law (such as  copyright, trade secret, or securities) or under contractual obligation (such as nondisclosure agreements),  or posting content other than as authorized or in a manner that misappropriates the intellectual property  or proprietary rights of others, including, but not limited to, your employer or former employers. 

**Threatening content** - posting or reposting content that is threatening, defamatory, or abusive towards other users or an DataMixMaster Associate; "rants," "slams," or threats against DataMixMaster or any another company or  person. 

**Harmful content** - posting or reposting any content, file, link or technology that may damage, interfere  with, modify, intercept, or expropriate any system, program, or data, such as viruses, Trojan horses, worms,  time bombs, and cancelbots; attempting to interfere in any way with DataMixMaster operations or with another user's account, including, but not limited to, mail or "post" bombing, news bombing, use of other flooding  techniques, deliberate attempts to overload the system, or attempts to avoid message blocking. 

**Unsolicited or unlawful mailings or posting of duplicative content** - transmitting or distributing “junk  mail”, “chain letters”, “SPAM”, or other forms of unsolicited mail or instant messages; distributing, posting  or reposting of advertising or promotional materials, or any other forms of solicitation, including class  action suits, charitable appeals and offering or disseminating goods, services, schemes, or promotions (including through advertising links in signatures); unreasonably or unnecessarily posting the same  comments, content and workflows in multiple places while using the Site or Services.

**Competitive activities** - using or applying the Sites or Services or any Output Content in any manner  directly or indirectly competitive with any business of DataMixMaster, including developing, making, or creating products or services that are based off of or similar or competitive in nature to the Sites, Services, Output  Content, and other data, products or services of DataMixMaster, including, without limitation, by modifying or  making derivative works of the Sites or Services or any Output Content. 

**Training technologies** - using the Site, Services, or any Output Content in any manner for purposes of  developing or training Artificial Intelligence (AI) or other related technologies. 

**Personal data**- posting or reposting any content that discloses the personal data of others- including any  individually identifying information, in any format- whether about users, others, or DataMixMaster Associates, on  forums, comments, templates, workflows, use cases, or examples uploaded to the DataMixMaster Community, or  any other sharing of content using the Site or Services; soliciting of personal data from anyone under the  age of 18. As with any publicly accessible forum, for your privacy and security, you should not post  personal data about yourself while using the Site or Services. 

**Other use restrictions** - You may not a) copy, modify, or make derivative works of any part of the Site or  Services, b) incorporate any part of the Site or Services into AI or other technologies, c) translate or  convert any part of the Site or Services, including any workflows, recipes, insights or other information, into another programming language, or port over any data or content from the Sites or Services into a  different online site, product, or service, or d) extract any underlying code, data, components or other  information of the models, algorithms, and systems, such as exfiltrating the weights of models, utilized by  the Site or Services. 
You may not post or repost hyperlinks to sites that violate any of the Terms.

If you recognize any suspected violation of the prohibited use and content terms outlined above, please  promptly notify us. For violations pertaining to the DataMixMaster Community, you may use the "Notify  Moderator" option in the message or article Options menu to describe why you feel the content is  inappropriate or in violation of the Terms. You may also contact us directly support@datamixmaster.com. 

### AI SERVICES 

The following additional terms apply to your use of any Services that utilize or connect to AI technologies, whether developed by DataMixMaster or third parties (“AI Services”). 

**Service-specific terms**

Given the rapid advancement of AI technologies, product or service-specific terms, including terms  governing any usage data or personal data we process in order to provide the AI Services to you, may  also apply and will be included in the product documentation to the extent applicable to such AI Services.  

**Third-Party Terms **

In the event that the AI Services implement any components from, or connect to, third parties (e.g., OpenAI's ChatGPT), you may be subject to the terms of service, privacy policies, and usage policies of  that third party (together, “Third-Party Terms”), pursuant to any links or notices referenced by or included  in the AI Services or applicable product documentation. We are not responsible for the practices or  policies of such third parties.

You are solely responsible for reviewing and complying with any applicable Third-Party Terms. You are responsible for responding to any third-party claims regarding your use of the AI Services in compliance  with the Third-Party Terms and applicable laws (including, but not limited to, copyright infringement or  other claims relating to Output Content). 

**Output Content **

You understand and acknowledge that the AI Services may produce similar responses or results (for  example, to similar prompts or queries from multiple customers) and may not be unique to you, and that  your rights in Output Content may not be enforceable, including against other users of the AI Services. "Output Content" means any data, recommendations, or materials generated by a user while utilizing the  AI Services. Output Content can reflect biases and may include “AI hallucinations”, logic errors, and  inaccuracies depending upon the data inputs and prompts, and all Output Content should be subject to  human review and validation. 

DataMixMaster may limit or entirely restrict your access to or use of Output Content or the AI Services for any  reason, including if DataMixMaster has a reasonable basis to believe that the Output Content or your use of the AI  Services does not comply with the requirements and restrictions in any product documentation for the AI Services or the Terms, as may be updated from time to time in DataMixMaster's sole discretion.  

### SECURITY VIOLATIONS 

You may not use the Site or Services to violate the security or integrity of any network, computer or  computing device, communications system, or application (each, a “System”). Prohibited activities include  unauthorized access to or use of any System, including the Site and Services, such as attempting to probe,  scan, or test the vulnerability of a System or to breach any security or authentication measures used by a  System. 

You may not disrupt the Site or Services or any function of the Site or Services, interfere with another  user's enjoyment of the Site or Services, or use the Site or Services for fraudulent or deceptive purposes.  Examples of security violations under these Terms include, but are not limited to: 

(1) forging any TCP/IP packet header or any part of the header information in any posting, or in any way  using the Site or Services to send altered, deceptive, or false source-identifying information; 

(2) using viruses, bots, worms, or any other malicious computer code, files, or programs that interrupt,  destroy, or limit the functionality of any System or otherwise permit the unauthorized use of or access to a  System; 

(3) interfering with the access of any user, host, or network to the Site or Services; 

(4) making any automated use of a System, such as, but not limited to, using scripts to send images or  videos; 

(5) interfering with, disrupting, or creating an undue burden on the Site or Services, including any  networks or services connected to the Site or Services; 

(6) impersonating or attempting to impersonate or otherwise attribute content to or misrepresenting your  affiliation with another user, person, or entity, including through pretexting or any other form of social  engineering; 

(7) selling or otherwise transferring your profile;

(8) collecting, using, or storing any personal data about another user without their express consent by  electronic or other means and for any purpose; and 

(9) scraping, web harvesting, or using web data extraction methods on the Site or Services (including, for  clarity, with respect to any Output Content or data thereof, such as workflows, or any content or data  posted by other users on the Site and Services) using any manual means or crawlers, bots, or other  automated mechanisms. 

We reserve the right, at our sole discretion, to investigate any violation of these Terms or misuse of the  Site or Services and may remove, disable your access to, or modify any content on the Site or Services that  violates the Terms, applicable law or regulation, or fails to comply with any other agreement between you  and DataMixMaster or with respect to Third-Party Terms. 

DataMixMaster may report any activity that we suspect violates any law or regulation to law enforcement,  regulators, or appropriate third parties, including, but not limited to, for the purposes of enforcing  DataMixMaster's legal rights and remedies. Our reporting may disclose relevant information about you and your  use of the Site or Services, including network and systems information pertaining to illegal conduct or an  alleged violation of these Terms. 

### CONTENT AND FEEDBACK 

**User-generated content**

DataMixMaster makes no claims or representations as to the accuracy, completeness, or truth of any material or  content submitted or found in the discussion areas of the Site and Services. Your situation, configuration,  or implementation may vary from that of the user sharing a solution and some advice posted by users  may even be wrong. You are solely responsible for using your judgment about the user-generated  content found on the Site and Services, and any decision to act upon the information provided by other  users. 

We are not liable for any websites not administered by or under the control of DataMixMaster; you open website  links posted on the Site or Services at your own risk. DataMixMaster makes no guarantee of the confidentiality or  privacy of any communication or information transmitted on the Services or any website linked to the  Services.  

**Content moderation**

DataMixMaster reserves the right of its moderators to edit, move, block or remove comments, content, signatures,  data or images that they consider inappropriate or that violate the Terms. We may also ban any user at  our sole discretion and will inform you of a decision regarding your content or Account via private  message within the Service or by email. Questions or comments about a decision regarding content  moderation may be directed to the moderator of the applicable Service, either by private message (where  applicable) or via email.  

    DataMixMaster Community: support@datamixmaster.com 

**Feedback**

If you provide any ideas, suggestions, or recommendations regarding DataMixMaster's products, technologies or the Services (“Feedback”), we may use such Feedback and incorporate it into DataMixMaster's products,  technologies, and services without paying royalties and without any other obligations or restrictions.

### USAGE DATA 

In order to provide DataMixMaster products and Services, ensure compliance with the Terms, provide support and  recommendations, and to develop and improve functionality and personalization, DataMixMaster collects data  about your use of our Sites and Services. Usage data includes how and when you use the Site and  Services; your participation and completion of training and certification courses; your user preferences;  the content and type of posts, workflows, and use cases you publish; your membership in user groups;  your use of posted content, including downloads and podcasts; the details of any support tickets or  questions you submit; and any data you upload or link to your profiles within the Site or Services,  including photos or avatars, social media accounts, username, title or role, and employer. To the extent  usage data includes any individually identifying information, DataMixMaster and its third-party service providers  shall treat such data in accordance with DataMixMaster's published Privacy Policy. The most current version of the  Privacy Policy is accessible at https://www.DataMixMaster.com/privacy. 

### ELECTRONIC COMMUNICATIONS 

We will communicate with you by email or by posting notices or updated Terms on the Site or as part of  the Services. You agree that all agreements, notices, disclosures and other communications that DataMixMaster  provides to you electronically satisfy any legal requirement that such communications be in writing. 

### DataMixMaster TRADEMARKS 

“Datamixmaster” and other DataMixMaster graphics, logos, page headers, button icons, scripts, and service names are  trademarks, registered trademarks, or trade dress of DataMixMaster in the U.S. and/or other countries. DataMixMaster's  trademarks and trade dress may not be used in connection with any site, materials, product or service  except as expressly authorized by DataMixMaster, and may not be used in any manner that is likely to cause  confusion among customers or otherwise in the marketplace, or in any manner that disparages or  discredits DataMixMaster. All other trademarks not owned by DataMixMaster that appear on the Site and Services are the  property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by  DataMixMaster. 

### YOUR ACCOUNT 

We may refuse to approve a username or email address that is already being used by another user or  that, in our sole discretion, violates the Terms or is rejected by DataMixMaster for any other reason. You represent  that all information provided in your profile is accurate, true, and complete and you agree to keep all such  information up-to-date. DataMixMaster may terminate your Account at any time if any of the information  provided is found to be inaccurate, false, out of date, or incomplete. 

You may not share or permit anyone else to use your DataMixMaster account and credentials (“Account”). Each  user must establish an Account, subject to the Terms, which may be used only on an individual basis. If  you are under the age of 16, your parent or legal guardian must establish your Account on your behalf.  

You are responsible for maintaining the confidentiality of your Account credentials and restricting any  access to your Account. You agree to accept responsibility for all activities that occur using your DataMixMaster  Account. If your Account log-in credentials are compromised, or if you become aware of any unauthorized  use of your Account or other breach of security on the Services, you are responsible for promptly  notifying us by sending an email to support@datamixmaster.com. 

DataMixMaster reserves the right, in its sole discretion, to refuse service, terminate accounts, or to remove or edit  content posted to the Site or Services. You understand and agree that DataMixMaster may remove any prohibited  content and/or terminate your use of the Site or Services without prior notice to you. 

### SITE AVAILABILITY 

DataMixMaster will make commercially reasonable efforts to make the Site and Services available. We are not  liable for any errors or delays in the content or transmission of postings while using the Services.  

### COPYRIGHT INFRINGEMENT 

If you believe that content belonging to you has been made available on the Site or Services without your  permission, please notify DataMixMaster:  
• By mail: Copyright Agent, DataMixMaster, LLC, 17200 Laguna Canyon Rd, Irvine, CA 92618  
• By email support@datamixmaster.com  

In accordance with the Digital Millennium Copyright Act, your written notification must include: 

• Your physical or electronic signature, or that of a person authorized to act on behalf of the owner  of an exclusive right that is allegedly infringed; 

• Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted  works on a single site are covered by a single notification, a representative list of such works at  that site; 

• Identification of the material that is claimed to be infringing or the subject of infringing activity  and for which removal or disabled access is requested, together with information reasonably  sufficient to permit DataMixMaster to locate the material; 

• Information reasonably sufficient to permit DataMixMaster to contact you or the complaining party, such  as an address, telephone number, and, if available, an email address, at which you or the  complaining party may be contacted; 

• A statement that you or the complaining party has a good faith belief that use of the material in  the manner described in the notification is not authorized by you or the copyright owner, its  agent, or the law; and 

• A statement that the information provided in the notification is accurate and, under penalty of  perjury, that you or the complaining party is authorized to act as or on behalf of the owner of an  exclusive right that is allegedly infringed. 

### INTELLECTUAL PROPERTY 

You are not provided any rights to any intellectual property made part of or accessible to you through use  of the Site or Services. All rights not expressly granted under these Terms are reserved by DataMixMaster and its  partners and third-party service providers.
With respect to any specific combination of tools you utilize through the DataMixMaster software (including  workflows, recipes, insights, or any Output Content) and publish to the Site or Services, you agree and  grant DataMixMaster and any user of the Site or Services a perpetual, irrevocable, worldwide, non-exclusive, non assignable, royalty-free license (with the right to sublicense) to copy, distribute, publicly display (e.g., post  elsewhere), reproduce and create derivative works of, whether in print or any kind of electronic version  that exists now or later developed, for any purpose, including a commercial purpose. 

With respect to any workflow, recipe, insight, or any Output Content published by you to any private  Community group or workspace, you hereby grant DataMixMaster a worldwide, perpetual, irrevocable, non exclusive, non-assignable, royalty-free license to sublicense to any user to whom you have provided  access to such private group or workspace, the right to use, run, edit, or view, as applicable, the workflow, recipe, insight, or any Output Content, including the use of your data to create such formula or results. 

When you publish, upload, submit, store, send, share, or receive content to or through the Site and  Services, you give DataMixMaster (and our partners and third-party service providers) a worldwide license to use,  host, store, reproduce, modify, create derivative works (such as those resulting from translations,  adaptations, or other changes we make so that your content works better with the Services),  communicate, publish, publicly perform, publicly display, and distribute such content. This license  continues even if you stop using the Site and/or Services. 

You warrant that the content, including any workflow, recipe, insight, or Output Content that you make  available on the Site or Services shall not infringe the rights of others, including those of your employer or  former employers. You, and not DataMixMaster, are solely responsible for any violation of any intellectual  property rights in the content, including Output Content, that you upload or otherwise make available on  the Site or Services. 

### DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY 

YOUR ENTIRE USE OF THE SITE AND SERVICES (INCLUDING ANY CONTENT OR WORKFLOW, RECIPE,  INSIGHT, OR OUTPUT CONTENT) IS AT YOUR OWN RISK. THE SITE, SERVICES, AND ANY DATA,  INFORMATION, CONTENT, MATERIALS, PRODUCTS, AND WORKFLOWS, RECIPES, INSIGHTS, AND  OUTPUT CONTENT INCLUDED OR PUBLISHED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH  THE SITE AND SERVICES (COLLECTIVELY, THE “SITE MATERIALS”) ARE PROVIDED ON AN “AS IS” AND “AS  AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, DataMixMaster DISCLAIMS ALL  WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES  OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY OR COMPLETENESS OF  RESPONSES, RESULTS, REASONABLE CARE OR WORKMANLIKE EFFORT, LACK OF NEGLIGENCE,  AND/OR LACK OF VIRUSES, ALL WITH REGARD TO THE SITE, SERVICES, AND SITE MATERIALS. FURTHER,  DataMixMaster MAKES NO WARRANTY THAT THE SITES, SERVICES, OR SITE MATERIALS WILL OPERATE  PROPERLY AS INTEGRATED WITH YOUR WORKFLOWS, APPLICATIONS, DATA, OR SYSTEMS, OR PROVIDE  ANY PARTICULAR RESULTS. DataMixMaster DOES NOT WARRANT THAT THE SITE, SERVICES, SITE MATERIALS,  DataMixMaster'S SERVERS, OR EMAIL SENT FROM DataMixMaster ARE FREE OF VIRUSES OR OTHER HARMFUL CODE. 

DataMixMaster AND ITS AFFILIATES AND EACH OF THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS,  PARTNERS, SUPPLIERS, AND LICENSORS SHALL HAVE NO LIABILITY FOR ANY DIRECT, INDIRECT,  INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL, RELIANCE OR CONSEQUENTIAL DAMAGES ARISING OUT  OF OR RELATED TO THE SITES, SERVICES, TERMS, OR SITE MATERIALS, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR ANY SITE MATERIALS OR CONTENT PUBLISHED BY ANY PARTY ON THE SITE OR  SERVICES, OR DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA, EVEN IF DataMixMaster HAS BEEN  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION  OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE  DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE  ADDITIONAL LEGAL RIGHTS. 

### INDEMNIFICATION 

You shall indemnify, defend, and hold harmless DataMixMaster and its affiliates and each of their respective officers, directors, employees, attorneys, suppliers, licensors, and agents from and against any and all  claims, costs, damages, losses, liabilities, and expenses (including attorneys' fees and costs) arising out of  or in connection with any third-party claim based on or related to: (i) your use of the Site or Services,  including the AI Services, or any Output Content in breach of the Terms or any Third-Party Terms or in  violation of applicable law; (ii) your access to or use of any Site Materials or content on the Site or  Services; or (iii) your violation or alleged violation of any third-party intellectual property or privacy rights. 

### APPLICABLE LAW AND JURISDICTION 

You agree that the laws of the state of Arizona, without regard to principles of conflict of laws, will  govern these Terms and any dispute of any sort that may arise between you and DataMixMaster. You agree to  exclusive jurisdiction of California federal and state courts, Orange County, for resolution of any dispute  related to the Terms. 

### SITE POLICIES, MODIFICATION, AND SEVERABILITY 

Your access to or use of the Site or Services is governed by the Terms and all policies and guidelines  published on the Site and as part of the Services and any applicable product documentation. We reserve  the right to make changes to the Site, Services, published policies and guidelines, and the Terms at any  time. If any provision of the Terms is deemed invalid, void, or for any reason unenforceable, that provision  shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.
`;

export default md;
