import React from 'react';

import MarkdownParser from '../../core/Transform/MarkdownParser';
import ContentBox from '../../core/Display/ContentBox';

import PrivacyPolicyMD from '../../text/privacy_policy';
import { setTitle } from '../../utils/browserUtils';
import './About.css';

function PrivacyPolicy() {
  setTitle('Privacy Policy');
  return (
    <div className="privacy-policy">
      <ContentBox>
        <MarkdownParser md={PrivacyPolicyMD} />
      </ContentBox>
    </div>
  );
}

export default PrivacyPolicy;
