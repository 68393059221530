import React from 'react';

import Button from '../../../core/Input/Button';
import Tooltip from '../../../core/Display/Tooltip';

const TOOLTIP_TEXT = [
  'Because the list of data sources is quite large and does not',
  'change particularly rapidly, we cache it in order to improve',
  'load times. If it has been a while since it was last fetched,',
  'it will be fetched again. However, if it is necessary to have',
  'the latest sources, this button will trigger a refresh.',
].join(' ');

export default function ForceRefreshButton() {
  const onClick = () => {
    localStorage.setItem('reportDataSources', undefined);
    localStorage.setItem('reportDataSourcesTimestamp', undefined);

    window.location.reload();
  };

  return (
    <Button onClick={onClick}>
      Force Refresh Sources
      <Tooltip text={TOOLTIP_TEXT} />
    </Button>
  );
}
