import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  Outlet,
} from 'react-router-dom';

import { formatQuestions } from '../../core/Utils/objectUtils';

import BACKGROUND_IMAGE from '../../images/DMM_reporthero_2X.png';
import SFA_BACKGROUND_IMAGE from '../../images/SFA_reporthero_2X.png';

import UserContext from '../../UserContext';
import NONE, { REPORT_PATH, ON_SFA } from '../../constants';

import AuthRedirect from '../Common/AuthRedirect';
import BackgroundImage from '../Common/BackgroundImage';

import ReportContext, { ReportContextProvider } from './ReportContext';
import SelectPage from './SelectPage/SelectPage';
import ResultPage from './ResultPage/ResultPage';
import ReportRetriever from './RerunReport/ReportRetriever';
import RerunReport from './RerunReport/RerunReport';

import './Report.css';

export function RetrieveReportNavigate() {
  const { id } = useParams();
  if (id) return <Navigate to={`${REPORT_PATH}/retrieve/${id}`} />;
  return <Navigate to={`${REPORT_PATH}/retrieve`} />;
}

export function CreateReportNavigate() {
  const { search } = useLocation();
  return (
    <Navigate
      to={{
        pathname: `${REPORT_PATH}/create/options`,
        search,
      }}
    />
  );
}

export function Pages({
  page = 1,
  analysis = '',
  updateAnalysis = () => {},
  components = [],
  enableNext = () => {},
  disableNext = () => {},
}) {
  switch (page) {
    case 1:
      return (
        <SelectPage
          enableNext={() => enableNext()}
          disableNext={() => disableNext()}
        />
      );
    case 2:
      return (
        <ResultPage
          analysis={analysis}
          updateAnalysis={updateAnalysis}
          components={components}
        />
      );
    default:
      return (
        <>
          Error
        </>
      );
  }
}

function useReportForm() {
  const { getReportFormURL, receivedURLs } = useContext(UserContext);
  const { setQuestions } = useContext(ReportContext);
  const [form, setForm] = useState();
  const [options, setOptions] = useState();

  /*
   * Get the form that must be returned to the backend to make a report
   * and use the default values
   */
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (receivedURLs) {
      axios.get(getReportFormURL(), { cancelToken: source.token })
        .then((resp) => resp.data['Report form'])
        .then((data) => {
          // Set form to default values
          const defaultForm = {};
          Object.values(data).forEach((val) => {
            let defaultVal = NONE;
            if (val.typecast === 'list') defaultVal = [NONE];
            if (val.default) defaultVal = val.default;
            defaultForm[val.fld_nm] = defaultVal;
          });
          setForm(defaultForm);
          setOptions(data);
          const formattedQuestions = formatQuestions(data);
          formattedQuestions.freq.fullChoices = {
            ...formattedQuestions.freq.choices,
          };
          setQuestions(formattedQuestions);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          throw error;
        });
    }
    return () => {
      source.cancel('Query umounted: useQueryForm() cancelled');
    };
  }, [receivedURLs]);
  return [form, options];
}

function Report() {
  /* Form represents the options currently selected by the user,
   * and options is the options and their various values sent by the backend.
   */
  const [form] = useReportForm();
  // APIs and Categories the user has selected
  const {
    dataSources,
    getChosenSourceIDs,
    answerQuestion,
  } = useContext(ReportContext);

  useEffect(() => {
    if (!form) return;
    answerQuestion('api_ids', getChosenSourceIDs(), undefined);
  }, [dataSources]);

  return (
    <Routes>
      <Route
        path="/"
      >
        <Route index element={<CreateReportNavigate />} />
        <Route
          path="create"
          element={(
            <AuthRedirect>
              <Outlet />
            </AuthRedirect>
          )}
        >
          <Route index element={<CreateReportNavigate />} />
          <Route path="options" element={<SelectPage />} />
          <Route path="results" element={<ResultPage />} />
        </Route>
        <Route path="retrieve">
          <Route
            index
            element={(
              <AuthRedirect>
                <RerunReport />
              </AuthRedirect>
            )}
          />
          <Route path=":id" element={<ReportRetriever fullPage />} />
        </Route>
        <Route path=":id" element={<RetrieveReportNavigate />} />
      </Route>
    </Routes>
  );
}

function ReportWrapper() {
  return (
    <ReportContextProvider>
      <BackgroundImage
        color={ON_SFA ? 'var(--color-primary)' : '#293d87'}
        image={ON_SFA ? SFA_BACKGROUND_IMAGE : BACKGROUND_IMAGE}
      >
        <Report />
      </BackgroundImage>
    </ReportContextProvider>
  );
}

Pages.propTypes = {
  page: propTypes.number,
  analysis: propTypes.string,
  updateAnalysis: propTypes.func,
  components: propTypes.instanceOf(Array),
  enableNext: propTypes.func,
  disableNext: propTypes.func,
};

export default ReportWrapper;
