import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
} from 'react-simple-maps';
import { Link, Navigate } from 'react-router-dom';

import SectionTitle from '../../core/Display/SectionTitle';
import Dropdown from '../../core/Input/Dropdown';

import NONE, { getPath, REPORT_PATH } from '../../constants';
import { setTitle } from '../../utils/browserUtils';

import { createStateChoices } from './SFAStates';
import GEO_SOURCE from './canadaprov.json';
import './SFAState.css';
import BackButton from './BackButton';

const DEFAULT = NONE;
const STRINGS = {
  instructions: 'Choose your province or territory'.toUpperCase(),
  confirm: 'Begin',
};

const COLORS = {
  normal: 'var(--color-10)',
  highlight: 'var(--color-primary)',
};

function NationalDataLink() {
  return (
    <div className="national-data-link">
      {'or '}
      <Link className="" to={`${REPORT_PATH}?filter_country=CAN`}>
        View National Data
      </Link>
    </div>
  );
}

function SFAStates() {
  const [state, setState] = useState(DEFAULT);
  const [stateChoices] = useState(createStateChoices(
    GEO_SOURCE.features,
    'Select Province or Territory',
  ));
  setTitle('US State Data');

  if (state !== DEFAULT) {
    const stateCode = state.substring(3);
    return <Navigate push to={`${getPath('REPORT.CREATE.OPTIONS')}?filter_province=${stateCode}`} />;
  }
  return (
    <div className="sfa-state-nonmap sizing-small">
      <BackButton />
      <SectionTitle text={STRINGS.instructions} />
      <Dropdown
        options={stateChoices}
        value={state}
        onChange={({ target }) => { setState(target.value); }}
        sorted
      />
      <ComposableMap
        projection="geoAlbers"
        className="us-map"
        projectionConfig={{
          center: [4.05, 58.5],
          scale: 870,
        }}
      >
        <Geographies geography={GEO_SOURCE}>
          {({ geographies }) => geographies.map((geography) => (
            <Geography
              key={geography.rsmKey}
              geography={geography}
              stroke="white"
              tabIndex="-1"
              style={{
                default: {
                  fill: state === geography.properties.iso_3166_2
                    ? COLORS.highlight
                    : COLORS.normal,
                  transition: 'fill 0.2s',
                  outline: 'none',
                },
                hover: {
                  fill: COLORS.highlight,
                  transition: 'fill 0.2s',
                  cursor: 'pointer',
                  outline: 'none',
                },
                pressed: {
                  outline: 'none',
                },
              }}
              onClick={() => { setState(geography.properties.iso_3166_2); }}
            />
          ))}
        </Geographies>
      </ComposableMap>
      <NationalDataLink />
    </div>
  );
}
export default SFAStates;
