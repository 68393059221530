import React from 'react';
import PropTypes from 'prop-types';

import './ListEntry.css';

import { cropText } from '../../utils/stringUtils';

import Aligner from '../Common/AlignerV2';
import Flex from '../Common/FlexV2';
import Text from '../Common/Text';

function ListEntry({ text, button = null }) {
  return (
    <Flex className="list-entry">
      <Aligner width={80} className="list-entry-aligner">
        <Text
          variant="h6"
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {cropText(text)}
        </Text>
      </Aligner>
      <Aligner width={20} className="list-entry-aligner">
        <Flex gap={20} direction="row-reverse">
          {button}
        </Flex>
      </Aligner>
    </Flex>
  );
}

export default ListEntry;

ListEntry.propTypes = {
  text: PropTypes.string.isRequired,
  button: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
