import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toasterContext } from '../../core/Display/Toaster';
import { equivalent } from '../../core/Utils/objectUtils';

import UserContext from '../../UserContext';
import { HOME_PATH, NULL_USER } from '../../constants';

import LoadingAnimation from '../Common/LoadingAnimation';

const SIGNOUT_MSG = 'You have been logged out.';
function SignOut() {
  const { addToast } = useContext(toasterContext);
  const { user, logOut } = useContext(UserContext);
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => { logOut(); }, []);

  useEffect(() => {
    if (!equivalent(user, NULL_USER)) { return; }
    setLoggedOut(true);
  }, [{ ...user }]);

  useEffect(() => {
    if (!loggedOut) return;
    addToast({ type: 'success', text: SIGNOUT_MSG });
  }, [loggedOut]);

  const redirect = <Navigate to={HOME_PATH} />;

  if (loggedOut) { return redirect; }
  return <LoadingAnimation />;
}

export default SignOut;
export { SIGNOUT_MSG };
