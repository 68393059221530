import React, { useState, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import ContentBox from '../../core/Display/ContentBox';
import InlineMessage from '../../core/Display/InlineMessage';
import TextField from '../../core/Input/TextField';
import Button from '../../core/Input/Button';

import { HOME_PATH } from '../../constants';
import { setTitle } from '../../utils/browserUtils';

import './Signup.css';
import UserContext from '../../UserContext';

const UNKNOWN_SIGNUP_ERROR_MSG = "We're sorry, we encountered a problem. Please try again later.";
const SUBMIT_BUTTON_TEXT = 'Sign up';

/*
 * Handles 4xx and 5xx responses from the login call.
 */
function handleSignupError(error, setError) {
  if (error.response) setError(error.response.data.message);
  else {
    setError(UNKNOWN_SIGNUP_ERROR_MSG);
  }
}

/*
 * Signup Function
 */
async function signup(details, setUser, setUserID, setError, getSignupURL) {
  return axios.post(getSignupURL(), details)
    .then((resp) => resp.data)
    .then((data) => {
      setUserID(details.email);
      setUser(data);
    })
    .catch((error) => { handleSignupError(error, setError); });
}

const TITLE = 'Create your account';
const FORM = {
  first_name: {
    label: 'First name',
    required: true,
    type: 'text',
  },
  last_name: {
    label: 'Last name',
    required: true,
    type: 'text',
  },
  email: {
    label: 'Email',
    required: true,
    type: 'email',
  },
  password: {
    label: 'Password',
    required: true,
    type: 'password',
  },
  org: {
    label: 'Organization',
    required: false,
    type: 'text',
  },
};

function validateField(id, value) {
  if (FORM[id].required && !value) {
    return 'Please fill out this field.';
  }
  if (id === 'email' && !String(value).toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  ) {
    return 'Please enter a valid email address.';
  }

  return undefined;
}

function SignupForm() {
  setTitle('Sign Up');
  const {
    loggedIn,
    setUser,
    setUserID,
    getSignupURL,
  } = useContext(UserContext);
  const [error, setError] = useState('');
  const [details, setDetails] = useState({});
  const [warnings, setWarnings] = useState({});
  const location = useLocation();
  const redirectLocation = (location.state ? location.state.redirectLink : HOME_PATH);

  const submitHandler = async (e) => {
    e.preventDefault();
    signup(details, setUser, setUserID, setError, getSignupURL);
  };

  if (loggedIn()) return <Navigate to={redirectLocation} />;

  function hasWarnings() {
    const keys = Object.keys(warnings);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (warnings[key]) return true;
    }
    return false;
  }

  return (
    <ContentBox classes={['login_box']}>
      <form onSubmit={submitHandler}>
        <div className="FORM-inner">
          <SignupFormHdr />
          {error && <InlineMessage type="error" text={error} />}
          {Object.keys(FORM).map((id) => (
            <TextField
              fullWidth
              error={warnings[id]}
              helperText={warnings[id]}
              key={id}
              id={id}
              label={FORM[id].label}
              onChange={(e) => {
                setDetails({ ...details, [id]: e.target.value });
              }}
              onBlur={() => {
                setWarnings({ ...warnings, [id]: validateField(id, details[id]) });
              }}
              onFocus={() => {
                setWarnings({ ...warnings, [id]: undefined });
              }}
              value={details[id] || ''}
              required={FORM[id].required}
              type={FORM[id].type}
            />
          ))}
          <Button
            className="button--wide signup__btn"
            type="submit"
            fullWidth
            disabled={hasWarnings()}
          >
            {SUBMIT_BUTTON_TEXT}
          </Button>
        </div>
      </form>
    </ContentBox>
  );
}

function SignupFormHdr() {
  return <h2 className="signup_header">{TITLE}</h2>;
}

export {
  TITLE,
  FORM,
  SUBMIT_BUTTON_TEXT,
  UNKNOWN_SIGNUP_ERROR_MSG,
};
export default SignupForm;
