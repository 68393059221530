import React, { useState, useContext } from 'react';
import axios from 'axios';

import InlineMessage from '../../core/Display/InlineMessage';
import TextField from '../../core/Input/TextField';
import Button from '../../core/Input/Button';

import UserContext from '../../UserContext';
import Flex from '../Common/FlexV2';
import Aligner from '../Common/AlignerV2';
import './ResetForm.css';

function handleLoginError(error, setErrorMessage) {
  setErrorMessage(error.response.data.errorMessage);
}

function sendReset(getResetPWURL, details, setErrorMessage, setSuccessMessage) {
  return axios.patch(getResetPWURL(), details)
    .then(setSuccessMessage('Password has been reset, please try logging in with your new password.'))
    .catch((error) => { handleLoginError(error, setErrorMessage); });
}

function ResetPassword() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const token = queryParams.get('pw_reset_token');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  // NOTE: CURRENT CONSTANTS ARE A TEMPORARY FIX!!! FIX W/ BACKEND
  const [details, setDetails] = useState({
    new_password: '', user_id: id, pw_reset_token: token,
  });
  const [passwordConf, setPasswordConf] = useState('');

  const { getResetPWURL } = useContext(UserContext);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (details.new_password === passwordConf) {
      sendReset(getResetPWURL, details, setErrorMessage, setSuccessMessage);
    } else {
      setErrorMessage('The entered passwords do not match');
    }
  };

  return (
    <Flex>
      <Aligner width="25" />
      <Aligner width="50">
        <div className="reset_box">
          <form onSubmit={submitHandler}>
            <div className="form-inner">
              <ResetFormHdr />
              {errorMessage && <InlineMessage type="error" text={errorMessage} />}
              {successMessage && <InlineMessage type="success" text={successMessage} />}
              <TextField
                id="password_enter"
                type="password"
                label="Enter your new password"
                onChange={(e) => setDetails({ ...details, new_password: e.target.value })}
                value={details.new_password}
                required
                fullWidth
              />
              <TextField
                id="password_cofirm"
                type="password"
                label="Confirm your new password"
                onChange={({ target }) => { setPasswordConf(target.value); }}
                value={passwordConf}
                required
                fullWidth
              />
              <Button fullWidth type="submit"> Reset password </Button>
            </div>
          </form>
        </div>
      </Aligner>
      <Aligner width="25" />
    </Flex>
  );
}

function ResetFormHdr() {
  return <h3 className="signup_header"> Enter your new password </h3>;
}

export default ResetPassword;
