import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../core/Input/Button';

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Button
      color="tertiary"
      direction="backward"
      onClick={() => { navigate(-1); }}
      title="Return to previous page"
    />
  );
}
