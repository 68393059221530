import NONE from '../../../constants';

const isEmptyArray = (object) => Array.isArray(object) && object.length === 0;
const questionAnswered = (question) => (question.value
  && question.value !== NONE
  && !isEmptyArray(question.value));
const questionUnanswered = (question) => !questionAnswered(question);
const questionRequired = (question) => !question.optional && !question.instructions;
const onlyRequiredQuestions = (array) => array.filter(questionRequired);
const onlyUnansweredQuestions = (array) => array.filter(questionUnanswered);
const missingValues = (object) => {
  const array = Object.values(object);
  const questionsMissingAnswers = onlyUnansweredQuestions(array);
  return onlyRequiredQuestions(questionsMissingAnswers);
};
const requiredValuesMissing = (object) => missingValues(object).length > 0;

export default requiredValuesMissing;
