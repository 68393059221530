import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LOGOUT_PATH } from '../../constants';
import UserContext from '../../UserContext';
import Logo from '../../images/LogoColor';

function SignOutLink() {
  const { userID } = useContext(UserContext);
  if (!userID) return null;
  return (
    <div className="sign-out-link">
      {`Signed in as ${userID}`}
      <Link to={LOGOUT_PATH}>Sign out</Link>
    </div>
  );
}

export default function Header() {
  return (
    <header>
      <Logo />
      <SignOutLink />
    </header>
  );
}
