import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

import { capitalizeString } from '../../Utils/stringUtils';

import Button from '../../Input/Button';
import './Tooltip.css';

/* eslint no-unused-vars: warn */

function isValidURL(s) {
  if (typeof s !== 'string') { return false; }
  let url = s.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g); // eslint-disable-line
  return (url !== null);
}

const KEYS_TO_INCLUDE = [
  'display_name',
  'description',
  'choices',
  'units',
  'source',
  'link',
];
function objectToTooltipString(object, objectKey) {
  if (objectKey === 'display_name') return null;
  if (!object) return null;
  if (typeof object === 'string') {
    if (isValidURL(object)) return <a href={object}>{object}</a>;
    return object;
  }
  if (typeof object !== 'object') return `${object}`;
  const keys = Object.keys(object)
    .filter((key) => (objectKey === 'choices'
      || KEYS_TO_INCLUDE.includes(key)));

  const renderKey = (key) => {
    switch (key) {
      case 'display_name': return null;
      case 'description': return <strong>Description:</strong>;
      case 'choices': return <strong>Values:</strong>;
      default:
        return (
          <strong>
            {capitalizeString(key)}
            :
          </strong>
        );
    }
  };

  if (keys.length === 1) {
    const [key] = keys;
    return objectToTooltipString(object[key], key);
  }
  const children = keys.map((key) => {
    const label = renderKey(key);
    if (!label) return null;
    const value = objectToTooltipString(object[key], key);
    if (!value) return null;
    return (
      <li key={key}>
        {label}
        {' '}
        {value}
      </li>
    );
  }).filter((child) => child);
  if (children.length === 0) return null;
  return <ul>{children}</ul>;
}

function InfoTooltip({
  text = undefined,
  tooltipIcon = '*',
  containsLinks = false,
  buttonClasses = [],
  textClasses = [],
}) {
  let tooltipText = objectToTooltipString(text);
  if (!tooltipText) return null;

  /* Take entirety of text, split it into lines
   * for each line, put each link into <a> tags,
   * and all other text into <p> tags
   */
  if (containsLinks) {
    const labelArr = [];
    const lines = text.split('\n');
    let ln = 0;
    lines.forEach((line) => {
      let plainText = '';
      const words = line.split(' ');
      words.forEach((word) => {
        if (isValidURL(word)) {
          labelArr.push(<p key={`line${ln}`}>{plainText}</p>);
          ln += 1;
          plainText = '';
          labelArr.push(<a key={`line${ln}`} href={word} target="_blank" rel="noreferrer">{word}</a>);
          ln += 1;
        } else {
          plainText = plainText.concat(word, ' ');
        }
      });
      if (plainText !== '') {
        labelArr.push(<p key={`line${ln}`}>{plainText}</p>);
        ln += 1;
      }
    });
    tooltipText = (<div key="inf-tt-text-div">{ labelArr }</div>);
  }

  return (
    <Tooltip
      key="info-tooltip"
      className="info-tooltip"
      title={(
        <span key="inf-tt-span" style={{ whiteSpace: 'pre-line' }} className={textClasses.join(' ')}>
          {tooltipText}
        </span>
      )}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#ffffff',
            color: 'inherit',
            border: '1px solid #000000',
            font: 'inherit',
          },
        },
      }}
    >
      <div key="inf-tt-div">
        <Button variant="icon" classes={['info-tooltip-button'].concat(buttonClasses)}>{tooltipIcon}</Button>
      </div>
    </Tooltip>
  );
}

export default InfoTooltip;

InfoTooltip.propTypes = {
  tooltipIcon: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  containsLinks: PropTypes.bool,
  buttonClasses: PropTypes.arrayOf(PropTypes.string),
  textClasses: PropTypes.arrayOf(PropTypes.string),
};
