import React, { Suspense } from 'react';
import propTypes from 'prop-types';

import defaultLogo from './LogoColor.png';
import SFALogo from './sfa-variant/LogoColor.png';
import SFALogoWhite from './sfa-variant/SFA-logo-white.webp';
import { APP_STYLE } from '../constants';

function LogoColor({ color = undefined }) {
  let image = defaultLogo;
  switch (APP_STYLE) {
    case 'sfa-variant':
      if (color === 'white') image = SFALogoWhite;
      else image = SFALogo;
      break;
    case 'cat-variant': return <span className="logo">COSMOS + TAXIS</span>;
    default:
  }
  return (
    <Suspense fallback={null}>
      <img src={image} alt="logo" className="logo" />
    </Suspense>
  );
}
LogoColor.propTypes = { color: propTypes.oneOf(['white', undefined]) };

export default LogoColor;
