import React, {
  createContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

const FormContext = createContext();

function FormContextProvider({ children = null }) {
  const [questions, setQuestions] = useState({ });

  const answerQuestion = (fieldName, answer, typecast) => {
    let typecastAnswer = answer;
    switch (typecast) {
      case 'int':
        typecastAnswer = parseInt(typecastAnswer, 10);
        break;
      default:
    }
    if (!questions[fieldName]) questions[fieldName] = {};
    questions[fieldName].error = !typecastAnswer && !questions[fieldName].optional;
    questions[fieldName].value = typecastAnswer;
    setQuestions({ ...questions });
  };

  const valueFormat = {
    questions,
    setQuestions,
    answerQuestion,
  };

  const [value, setValue] = useState({ ...valueFormat });

  useEffect(() => {
    setValue({ ...valueFormat });
  }, [questions]);

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
}

FormContextProvider.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
};

export { FormContextProvider };
export default FormContext;
