import React from 'react';
import PropTypes from 'prop-types';
import './OldLineGraph.css';
import './chart-dragdata';

import DateField from '../../../core/Input/DateField';
import Button from '../../../core/Input/Button';

export const SINGLE_AXIS_TEXT = 'Single Axis';
export const MULTI_AXES_TEXT = 'Multi. Axes';

export function multiAxesButtonText(isMultiAxes) {
  if (isMultiAxes) {
    return SINGLE_AXIS_TEXT;
  }
  return MULTI_AXES_TEXT;
}

const CUSTOM_DATE_SELECTOR_STYLE = {
  container: (provided) => ({
    ...provided,
    minWidth: '10em',
  }),
};

class DateRange {
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }
}

function MultipleAxesToggleButton({
  isMultiAxes,
  toggleFunc,
}) {
  return (
    <div>
      <Button
        variant="hollow"
        type="button"
        onClick={() => toggleFunc()}
      >
        {multiAxesButtonText(isMultiAxes)}
      </Button>
    </div>
  );
}

MultipleAxesToggleButton.propTypes = {
  isMultiAxes: PropTypes.bool.isRequired,
  toggleFunc: PropTypes.func.isRequired,
};

function DateSelector({
  select,
  dateRange,
  setDateRange,
  min,
  max,
}) {
  const label = select === 'start' ? 'Start date' : 'End date';
  return (
    <DateField
      label={label}
      id={`graph-${select}-date`}
      styles={CUSTOM_DATE_SELECTOR_STYLE}
      value={`${dateRange[select]}`}
      minDate={min}
      maxDate={max}
      onChange={(newValue) => {
        if (select === 'start') {
          setDateRange(new DateRange(newValue, dateRange.end));
        } else {
          setDateRange(new DateRange(dateRange.start, newValue));
        }
      }}
    />
  );
}

DateSelector.propTypes = {
  select: PropTypes.string.isRequired,
  dateRange: PropTypes.instanceOf(Object).isRequired,
  setDateRange: PropTypes.instanceOf(Function).isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
};

function datesToStrings(dates) {
  return dates.map((date) => `${date}`);
}

function DateRangeSelector({
  type,
  dateRange,
  setDateRange,
  dates,
  isMultiAxes,
  toggleMultipleAxes,
}) {
  const stringDates = datesToStrings(dates);
  const min = stringDates[0];
  const max = stringDates[stringDates.length - 1];
  return (
    <>
      <DateSelector
        min={min}
        max={max}
        select="start"
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <DateSelector
        min={min}
        max={max}
        select="end"
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      {type === 'line' && (
        <MultipleAxesToggleButton
          isMultiAxes={isMultiAxes}
          toggleFunc={toggleMultipleAxes}
        />
      )}
    </>
  );
}

DateRangeSelector.propTypes = {
  type: PropTypes.string.isRequired,
  dateRange: PropTypes.instanceOf(Object).isRequired,
  setDateRange: PropTypes.instanceOf(Function).isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  isMultiAxes: PropTypes.bool.isRequired,
  toggleMultipleAxes: PropTypes.func.isRequired,
};

export { DateRange };
export default DateRangeSelector;
