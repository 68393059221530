import React from 'react';
import ReactGA from 'react-ga4';
import Toaster from './core/Display/Toaster';

import ThemeSelector from './theme/ThemeSelector';
import ErrorPage from './ErrorPage';
import Routes from './apps/Routes';
import Report from './components/Report/Report';
import { UserContextProvider } from './UserContext';

import { SecurityContextProvider } from './components/SecurityContext';

const APP_VARIANTS = {
  query_only: <QueryOnlyApp />,
  full_web: <FullWebApp />,
};

const TRACKING_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
if ([0, undefined].includes(process.env.JEST_WORKER_ID) && TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

function App() {
  return (
    <UserContextProvider>
      <SecurityContextProvider>
        <ThemeSelector>
          {APP_VARIANTS[process.env.REACT_APP_VARIANT]}
        </ThemeSelector>
      </SecurityContextProvider>
    </UserContextProvider>
  );
}

function QueryOnlyApp() {
  return <Report />;
}

/*
 * The full website.
 */
function FullWebApp() {
  return (
    // we are using React Error Boundaries here:
    <ErrorPage>
      <Toaster anchor="bottomLeft">
        <div className="App">
          <div className="background" />
          <Routes />
        </div>
      </Toaster>
    </ErrorPage>
  );
}

export default App;
