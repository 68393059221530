import React from 'react';
import PropTypes from 'prop-types';
import '../Input.css';
import Label from '../Label';

export default function NumberField({
  classes = [],
  defaultValue = undefined,
  disabled = false,
  error = false,
  helperText = undefined,
  id = undefined,
  label = undefined,
  maximum = 99999,
  minimum = 0,
  name = undefined,
  onChange = undefined,
  required = false,
  sx = {},
  onFocus = () => {},
  onBlur = () => {},
}) {
  const containerClassName = [...classes, 'input-container'];
  const inputClassName = [];

  if (error) inputClassName.push('error text-field-error');
  const style = { ...sx };

  if (label && !id) throw new Error('NumberFields with labels must have an id property');

  return (
    <div className={containerClassName.join(' ')}>
      {label
      && (
        <Label
          className="input-label"
          htmlFor={id}
          id={`${label.replace(/ /g, '-')}`}
          text={label}
          helperText={helperText}
        />
      )}
      <input
        type="number"
        value={defaultValue}
        disabled={disabled}
        max={maximum}
        min={minimum}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        style={style}
      />
    </div>
  );
}

NumberField.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.node,
  maximum: PropTypes.number,
  minimum: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.instanceOf(Object),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
