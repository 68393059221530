import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import ContentBox from '../../../core/Display/ContentBox';

import Graph from '../../Common/Graph';

import bucketValueData from './bucketValueData';

function getLatest(rows) {
  let latestDate = '1900-01-01';
  let latestRows = [];
  rows.forEach((row) => {
    if (row.factor_date < latestDate) return;
    if (row.factor_date > latestDate) {
      latestDate = row.factor_date;
      latestRows = [];
    }
    latestRows.push(row);
  });
  return latestRows;
}

function SharedGraph({
  title,
  rows,
  columns,
}) {
  return (
    <ContentBox classes={['rounded-corners']}>
      <Graph
        title={title}
        rows={rows}
        columns={columns}
        xAxisID="agency"
        stackX
        filterZeroTooltips
        disableAllOptions
      />
    </ContentBox>
  );
}
SharedGraph.propTypes = {
  title: propTypes.string.isRequired,
  rows: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
  columns: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
};

export function CurrentCoupon({ allRows = [] }) {
  const { columns, rows } = useMemo(
    () => bucketValueData({ rows: getLatest(allRows), interval: 0.05 }),
    [allRows],
  );
  return (
    <SharedGraph
      title="Unpaid Balance relative to Current Coupon"
      rows={rows}
      columns={columns}
    />
  );
}
CurrentCoupon.propTypes = {
  allRows: propTypes.instanceOf(Array).isRequired,
};

export function LTV({ allRows }) {
  const { columns, rows } = useMemo(
    () => bucketValueData({
      rows: getLatest(allRows),
      bucketValueKey: 'loan_to_value',
      bucketValueLabel: 'LTV',
      interval: 5,
    }),
    [allRows],
  );
  return (
    <SharedGraph
      title="Unpaid Balance relative to LTV"
      rows={rows}
      columns={columns}
    />
  );
}
LTV.propTypes = {
  allRows: propTypes.instanceOf(Array).isRequired,
};

export default function CouponGraphs({ rows }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem',
        marginBottom: '1rem',
        overflow: 'auto',
      }}
    >
      <CurrentCoupon allRows={rows} />
      <LTV allRows={rows} />
    </div>
  );
}
CouponGraphs.propTypes = {
  rows: propTypes.instanceOf(Array).isRequired,
};
