import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  HOME_PATH,
  APP_STYLE,
} from '../../constants';
import Logo from '../../images/LogoColor';

import Navbar from '../Navbar/Navbar';
import SignInOutButton from '../Navbar/SignInOutButton';

import './header.css';

function SFAHeader() {
  return (
    <header className="sfa-header">
      <div className="header-content">
        <Link to={HOME_PATH} className="title">
          <Suspense fallback={null}>
            <Logo />
          </Suspense>
        </Link>
        <div className="sfa-tagline">
          Structured finance powers business growth, homeownership, and access to credit for all.
        </div>
        <SignInOutButton setNavOpen={() => {}} />
      </div>
    </header>
  );
}

function shouldUseSFAHeader() {
  return APP_STYLE === 'sfa-variant';
}

function Header({ navbarPages }) {
  if (shouldUseSFAHeader()) return <SFAHeader />;
  return (
    <header>
      <div className="header-content">
        <Link to={HOME_PATH} className="title">
          <Suspense fallback={null}>
            <Logo />
          </Suspense>
        </Link>
        <Navbar pagesToRender={navbarPages} />
      </div>
    </header>
  );
}

Header.propTypes = {
  // TODO: consider assigning a more-specific type to navbarPages
  navbarPages: PropTypes.instanceOf(Object).isRequired,
};

export default Header;
