import React from 'react';

import {
  Routes,
  Route,
} from 'react-router-dom';

import HMDARaw from '../HMDA/HMDARaw';
import HMDAAggregate from '../HMDA/HMDAAggregate';

import { SFA_HMDA } from '../../constants';
import { setTitle } from '../../utils/browserUtils';

import './SFAHMDA.css';
import BackButton from './BackButton';

const RAW = 'raw';
const AGGR = 'aggr';
const ABSOLUTE_RAW = `${SFA_HMDA}/${RAW}`;
const ABSOLUTE_AGGR = `${SFA_HMDA}/${AGGR}`;

function OptionsPages() {
  return (
    <div className="sizing-small">
      <BackButton noText />
      <div className="sfa-hmda-wrapper">
        <div>
          <HMDARaw
            optionsPath={SFA_HMDA}
            resultsPath={RAW}
          />
        </div>
        <div className="center-text or" aria-hidden>OR</div>
        <div>
          <HMDAAggregate
            optionsPath={SFA_HMDA}
            resultsPath={AGGR}
          />
        </div>
      </div>
    </div>
  );
}

function SFAHMDA() {
  setTitle('HMDA Data');
  return (
    <Routes>
      <Route
        index
        element={<OptionsPages />}
      />
      <Route
        path={`${RAW}/*`}
        element={<HMDARaw optionsPath={ABSOLUTE_RAW} resultsPath={ABSOLUTE_RAW} />}
      />
      <Route
        path={`${AGGR}/*`}
        element={<HMDAAggregate optionsPath={ABSOLUTE_AGGR} resultsPath={ABSOLUTE_AGGR} />}
      />
    </Routes>
  );
}
export default SFAHMDA;
