import React from 'react';
import propTypes from 'prop-types';

import Text from '../Common/Text';

function DeleteConfirmationText({ name = '' }) {
  return (
    <Text variant="p">
      Are you sure you want to delete
      <span
        style={name
          ? { color: 'var(--color-primary)', fontWeight: '600' }
          : undefined}
      >
        {` ${name || 'this'}`}
      </span>
      ?
    </Text>
  );
}
DeleteConfirmationText.propTypes = { name: propTypes.string };

export default DeleteConfirmationText;
