import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { oldDataSetToNew } from '../../../core/Utils/objectUtils';
import ContentBox from '../../../core/Display/ContentBox';
import LoadingAnimation from '../../../core/Display/LoadingAnimation';
import InlineMessage from '../../../core/Display/InlineMessage';
import Button from '../../../core/Input/Button';

import UserContext from '../../../UserContext';
import { canEditReport } from '../../../utils/networkUtils';
import { setTitle } from '../../../utils/browserUtils';

import ReportContext from '../ReportContext';
import DisplayReport from '../DisplayReport/DisplayReport';
import GraphView from '../DisplayReport/DataViews/GraphView';
import { useOffsets, useGraphDataSet } from '../DisplayReport/Common/DragHooks';

const DISPLAY = {
  Pending: 'Pending',
  Report: 'Report',
  Graph: 'Graph',
  Error: 'Error',
};

const ERR_MSG = 'Could not load report.';

function EditReportButton() {
  const { setMode } = useContext(ReportContext);
  return (
    <Button
      onClick={() => {
        setMode('createNew');
      }}
    >
      Edit Report
    </Button>
  );
}

function ReportRetrieverContent({
  display,
  dataSet,
  form,
  canEdit,
  title,
  reportID = '',
  userColumns,
  setUserColumns,
}) {
  const [offsets, updateOffsets, resetOffsets] = useOffsets();
  const [graphDataSet, updateGraphDataSet, resetGraphDataSet] = useGraphDataSet(dataSet);

  const reformattedDataSet = useMemo(
    () => {
      const columnsDefined = Object.keys(graphDataSet).length > 0;
      return columnsDefined ? oldDataSetToNew(graphDataSet) : null;
    },
    [graphDataSet],
  );

  useEffect(() => {
    resetGraphDataSet(dataSet);
  }, [display]);

  switch (display) {
    case DISPLAY.Pending:
      return (
        <LoadingAnimation />
      );
    case DISPLAY.Report:
      return (
        <>
          <DisplayReport
            dataSet={dataSet}
            form={form}
            canEdit={canEdit}
            title={title}
            reportID={reportID}
            userColumns={userColumns}
            setUserColumns={setUserColumns}
          />
          {canEdit
          && <EditReportButton />}
        </>
      );
    case DISPLAY.Graph:
      if (!reformattedDataSet) return <LoadingAnimation />;
      return (
        <>
          <GraphView
            dataSet={reformattedDataSet}
            offsets={offsets}
            title={title}
            onDrag={(key, value) => updateOffsets(key, value)}
            onDragEnd={() => updateGraphDataSet(offsets)}
            resetOffsets={() => { resetOffsets(); resetGraphDataSet(dataSet); }}
          />
          {reportID
          && (
          <Button url={`/Report/${reportID}`}>
            View Full Report
          </Button>
          )}
        </>
      );
    case DISPLAY.Error:
      return (
        <InlineMessage type="error" text={ERR_MSG} />
      );
    default:
      return (
        <InlineMessage type="error" text={ERR_MSG} />
      );
  }
}

ReportRetrieverContent.propTypes = {
  display: propTypes.string.isRequired,
  dataSet: propTypes.instanceOf(Object).isRequired,
  form: propTypes.instanceOf(Object).isRequired,
  canEdit: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  reportID: propTypes.string,
  userColumns: propTypes.instanceOf(Array).isRequired,
  setUserColumns: propTypes.func.isRequired,
};

function ReportRetriever({
  graphOnly = false,
  reportid = '',
  fullPage = false,
}) {
  const [reportID] = useState((reportid || useParams().id));
  const { getRetrieveReportURL, receivedURLs } = useContext(UserContext);
  const [display, setDisplay] = useState(DISPLAY.Pending);
  const {
    title,
    setTitle: setReportTitle,
    addSource,
    setDataViews,
    userColumns,
    setUserColumns,
    setAnalysis,
  } = useContext(ReportContext);
  const [canEdit, setCanEdit] = useState(false);
  const [dataSet, setDataSet] = useState({});
  const [form, setForm] = useState({});
  const { userID } = useContext(UserContext);
  if (fullPage) setTitle(title);

  // Fetch report from backend
  useEffect(() => {
    if (!receivedURLs) { return () => {}; }
    let isMounted = true;
    const source = axios.CancelToken.source();
    canEditReport(userID, reportID)
      .then((response) => { setCanEdit(response); })
      .catch(() => { setCanEdit(false); });

    axios.get(getRetrieveReportURL().concat('/', reportID), { cancelToken: source.token })
      .then(({ data }) => {
        if (!isMounted) { return; }
        const dataSources = data.rpt_data.params.api_ids.split(',');
        setReportTitle(data.rpt_title);
        setAnalysis(data.rpt_text);
        setDataSet(data.rpt_data);
        setForm(data.rpt_data.params);
        dataSources.forEach((sourceID) => { addSource(sourceID); });
        setDataViews(data.sections);
        setDisplay((graphOnly ? DISPLAY.Graph : DISPLAY.Report));
        setUserColumns(data.user_cols ? data.user_cols : []);
      })
      .catch(() => {
        if (!isMounted) { return; }
        setDisplay(DISPLAY.Error);
      });
    return () => {
      isMounted = false;
      source.cancel('ReportRetriever unmounted');
    };
  }, [receivedURLs]);

  return (
    <ContentBox
      classes={graphOnly ? ['transparent', 'no-shadow'] : undefined}
    >
      <ReportRetrieverContent
        display={display}
        dataSet={dataSet}
        form={form}
        canEdit={canEdit}
        title={title}
        reportID={reportID}
        userColumns={userColumns}
        setUserColumns={setUserColumns}
      />
    </ContentBox>
  );
}

export { ERR_MSG };
export default ReportRetriever;

ReportRetriever.propTypes = {
  reportid: propTypes.string,
  graphOnly: propTypes.bool,
  fullPage: propTypes.bool,
};
