export default function searchToQuery(search) {
  const noQuestion = search.substr(1);
  const terms = noQuestion.split('&');

  let state = '';
  let year = '';
  const params = {};

  terms.forEach((term) => {
    const [key, value] = term.split('=');
    if (key === 'path_keys') return;
    if (key === 'state_code') { state = value; return; }
    if (key === 'activity_year') { year = value; return; }
    params[key] = value;
  });

  return [`${state}/${year}`, params];
}
