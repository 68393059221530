/* This could really use some work but it's fine for now */
export function indexToOrdinal(index) {
  const tensTable = [
    '',
    '',
    'twenty',
    'thirty',
    'fourty',
  ];
  const exactTensTable = [
    '',
    '',
    'twentieth',
    'thirtieth',
    'fourtieth',
    'fiftieth',
    'sixtieth',
    'seventieth',
  ];
  const lowNumberTable = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];
  let ordinalNumber = index + 1;
  let ordinalString = '';
  if (ordinalNumber < 100 && ordinalNumber > 19) {
    const tens = Math.floor(ordinalNumber / 10);
    const remainder = ordinalNumber % 10;
    if (remainder === 0) return exactTensTable[tens];
    ordinalString += `${tensTable[tens]}-`;
    ordinalNumber -= tens * 10;
  }
  if (ordinalNumber < 20) {
    ordinalString += lowNumberTable[ordinalNumber - 1];
  }
  return `${ordinalString}`;
}

export function isExternalLink(href) {
  if (typeof href !== 'string') throw new Error('isExternalLink expected href to be string.');
  const { hostname } = window.location;
  return !href.includes(hostname);
}

export function ensureTrailingSlash(text) {
  if (text.slice(-1) === '/') return text;
  return text.concat('/');
}

export function cropText(text, maxLength = 100) {
  if (!text.substring) { return text; }
  if (text.length < maxLength) { return text; }
  const finalDecorator = '...';
  return text.substring(0, maxLength - finalDecorator.length).concat(finalDecorator);
}

export function footnotesToString(footnotes) {
  if (!footnotes) return '';
  if (typeof footnotes !== 'object') return '';
  let string = '';
  Object.keys(footnotes).forEach((key) => {
    const label = key.slice(0, 1).toUpperCase()
      .concat(key.slice(1));
    const text = footnotes[key];
    string = string.concat(`${label}: ${text}\n\n`);
  });
  return string;
}

export function randomString(
  length,
  useNumbers = true,
  caseSensitive = true,
) {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const LETTERS = letters.toUpperCase();
  const numbers = '0123456789';

  let characters = letters;
  if (caseSensitive) characters = characters.concat(LETTERS);
  if (useNumbers) characters = characters.concat(numbers);

  let string = '';
  for (let i = 0; i < length; i += 1) {
    const index = Math.floor(Math.random() * characters.length);
    string = string.concat(characters[index]);
  }
  return string;
}

export function capitalizeString(string) {
  const firstLetter = string.slice(0, 1).toUpperCase();
  const remainder = string.slice(1);
  return firstLetter.concat(remainder);
}

/* eslint no-bitwise: off */
export function cyrb128(str) {
  let h1 = 1779033703;
  let h2 = 3144134277;
  let h3 = 1013904242;
  let h4 = 2773480762;
  for (let i = 0, k; i < str.length; i += 1) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  return [
    (h1 ^ h2 ^ h3 ^ h4) >>> 0,
    (h2 ^ h1) >>> 0,
    (h3 ^ h1) >>> 0,
    (h4 ^ h1) >>> 0,
  ];
}
