import React from 'react';

import PageTitle from '../../../../core/Display/PageTitle';

import DataSearch from '../../DataSearch';

export default function People() {
  return (
    <>
      <PageTitle text="People" />
      <DataSearch pageName="People" headerHidden />
    </>
  );
}
