import NONE from '../../constants';

const REPORT_MODES = {
  createNew: 'createNew',
  HMDARaw: 'HMDARaw',
  HMDAAggregate: 'HMDAAggregate',
  viewSaved: 'viewSaved',
  viewSavedList: 'viewSavedList',
};

const DISPLAY = {
  Pending: 'Pending',
  Data: 'Data',
  Stats: 'Stats',
  Correlations: 'Correlations',
  Graph: 'Graph',
  Report: 'Report',
  Moments: 'Moments',
  AddText: 'AddText',
  Rearrange: 'Rearrange',
  Save: 'Save',
  Error: 'Error',
};

const DATA_VIEW_NAMES = {
  Graph: 'Graph',
  Data: 'Data Table',
  Stats: 'Statistical Summary',
  Correlations: 'Correlations',
  Moments: 'Notable Moments',
};

const DEF_REPORT_COMPONENTS = [
  {
    id: '3',
    name: DATA_VIEW_NAMES.Graph,
    enabled: true,
    draggable: true,
    view: DISPLAY.Graph,
    text: '',
  },
  {
    id: '0',
    name: DATA_VIEW_NAMES.Data,
    enabled: true,
    draggable: true,
    view: DISPLAY.Data,
    text: '',
  },
  {
    id: '1',
    name: DATA_VIEW_NAMES.Stats,
    enabled: true,
    draggable: true,
    view: DISPLAY.Stats,
    text: '',
  },
  {
    id: '2',
    name: DATA_VIEW_NAMES.Correlations,
    enabled: true,
    draggable: true,
    view: DISPLAY.Correlations,
    text: '',
  },
  {
    id: '4',
    name: DATA_VIEW_NAMES.Moments,
    enabled: true,
    draggable: true,
    view: DISPLAY.Moments,
    text: '',
  },
];

const EMPTY_DATA_SOURCE = {
  categoryID: NONE,
  searchText: '',
  pickByCategory: true,
  sourceID: NONE,
};

export {
  DISPLAY,
  DATA_VIEW_NAMES,
  DEF_REPORT_COMPONENTS,
  REPORT_MODES,
  EMPTY_DATA_SOURCE,
};
