import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Link,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../core/Input/Button';
import ContentBox from '../../core/Display/ContentBox';
import Table from '../../core/Display/Table';
import InlineMessage from '../../core/Display/InlineMessage';
import LoadingAnimation from '../../core/Display/LoadingAnimation';

import {
  formatQuestions,
  questionsToQuery,
  questionsToQueryString,
} from '../../core/Utils/objectUtils';

import UserContext from '../../UserContext';
import {
  HMDA_RAW_PATH,
} from '../../constants';
import {
  getHMDAStateForm,
  getHMDAStateData,
  getHMDAColumnInfo,
} from '../../utils/networkUtils';
import { setTitle } from '../../utils/browserUtils';

import Questions from '../../core/Input/Questions';

import FormContext, { FormContextProvider } from '../Common/FormContext';

import searchToQuery from './searchToQuery';
import TitlePrompt from './TitlePrompt';
import BackButton from './BackButton';

const VIEWS = {
  error: 'error',
  loading: 'loading',
  content: 'content',
};

function Options({ pageLink, pageName }) {
  const { receivedURLs } = useContext(UserContext);
  const {
    questions,
    setQuestions,
    answerQuestion,
  } = useContext(FormContext);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (!receivedURLs) return;
    getHMDAStateForm()
      .then((form) => {
        setQuestions(formatQuestions(form));
        setLoading(false);
      })
      .catch(({ message }) => { setErrorMessage(message); });
  }, [receivedURLs]);

  const [path, params] = questionsToQuery(questions);

  if (errorMessage) return <InlineMessage type="error" text={errorMessage} />;
  if (loading) return <LoadingAnimation />;
  return (
    <>
      <TitlePrompt type="raw" />
      <Questions
        questions={questions}
        responseSetter={answerQuestion}
        horizontal
      />
      <Link
        to={{
          pathname: pageLink(1),
          search: questionsToQueryString(questions),
          state: { path, params },
        }}
      >
        <Button direction="forward">{pageName(1)}</Button>
      </Link>
    </>
  );
}

Options.propTypes = {
  pageName: PropTypes.func.isRequired,
  pageLink: PropTypes.func.isRequired,
};

function Results() {
  const [view, setView] = useState(VIEWS.loading);
  const [errorMessage, setErrorMessage] = useState();
  const { receivedURLs } = useContext(UserContext);
  setTitle('HMDA Individual Mortgage Data');

  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  const loc = useLocation();
  const [path, params] = searchToQuery(loc.search);

  const formatRows = (data) => {
    setRows(data[Object.keys(data)[0]]);
  };
  const formatColumns = (data) => {
    const keys = Object.keys(data);
    const formatted = keys.map((id) => ({
      ...data[id],
      id,
      linkActivate: data[id].linkActivate || data[id]['link-activate'],
      name: data[id].name || data[id].display_name,
      description: {
        choices: data[id].choices,
        description: data[id].description,
      },
      type: data[id].type || data[id]['field-type'],
    }));
    setColumns(formatted);
  };

  useEffect(() => {
    if (!rows) return;
    if (!columns) return;
    if (errorMessage) return;
    setView(VIEWS.content);
  }, [rows, columns]);

  useEffect(() => {
    if (!receivedURLs) return;
    getHMDAStateData(path, params)
      .then((data) => { formatRows(data); })
      .catch(({ message }) => { setView(VIEWS.error); setErrorMessage(message); });
    getHMDAColumnInfo()
      .then((data) => { formatColumns(data); })
      .catch(({ message }) => { setView(VIEWS.error); setErrorMessage(message); });
  }, [receivedURLs]);

  return (
    <>
      {view === VIEWS.error
      && <InlineMessage type="error" text={errorMessage} />}
      {view === VIEWS.loading
      && <LoadingAnimation />}
      {view === VIEWS.content
      && (
        <Table
          rows={rows}
          columns={columns}
        />
      )}
      <BackButton />
    </>
  );
}

function HMDARaw({
  optionsPath = HMDA_RAW_PATH,
  resultsPath = HMDA_RAW_PATH,
}) {
  const HMDAPages = [
    { name: 'Options', link: `${optionsPath}/options` },
    { name: 'Results', link: `${resultsPath}/results` },
  ];

  const pageName = (index) => HMDAPages[index]
    && HMDAPages[index].name;

  const pageLink = (index) => HMDAPages[index]
    && HMDAPages[index].link;

  return (
    <FormContextProvider>
      <ContentBox classes={['outlined', 'transparent']}>
        <Routes>
          <Route
            index
            element={(
              <Options
                pageName={(index) => pageName(index)}
                pageLink={(index) => pageLink(index)}
              />
            )}
          />
          <Route
            path="options"
            element={(
              <Options
                pageName={(index) => pageName(index)}
                pageLink={(index) => pageLink(index)}
              />
            )}
          />
          <Route
            path="results"
            element={(
              <Results
                pageName={() => pageName()}
                pageLink={(index) => pageLink(index)}
              />
            )}
          />
        </Routes>
      </ContentBox>
    </FormContextProvider>
  );
}

HMDARaw.propTypes = {
  optionsPath: PropTypes.string,
  resultsPath: PropTypes.string,
};

export default HMDARaw;
