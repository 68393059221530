import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './core/Display/Dialog';
import Text from './core/Display/Text';
import Button from './core/Input/Button';

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <>
          <div className="background" />
          <Dialog
            centerAll
            footerContent={(
              <div style={{ textAlign: 'center' }}>
                <a href="/">
                  <Button onClick={() => { this.state.hasError = false; }}>
                    OK
                  </Button>
                </a>
              </div>
            )}
          >
            <Text variant="h3">
              {'We\'re sorry, we encountered a problem.'}
            </Text>
          </Dialog>
        </>
      );
    }
    const { children } = this.props;
    return children;
  }
}

export default ErrorPage;

ErrorPage.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
