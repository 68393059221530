import React, { useState, useEffect, useContext } from 'react';
import { Navigate, Link, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';

import { toasterContext } from '../../core/Display/Toaster';
import ContentBox from '../../core/Display/ContentBox';
import InlineMessage from '../../core/Display/InlineMessage';
import Button from '../../core/Input/Button';
import TextField from '../../core/Input/TextField';

import * as usr from '../../user';
import { simpleAuthorized } from '../Common/AuthRedirect';
import './LoginForm.css';
import {
  NULL_USER,
  SIGNUP_PATH,
  RESET_PATH,
} from '../../constants';
import UserContext from '../../UserContext';
import { setTitle } from '../../utils/browserUtils';

export const SUCCESS_MESSAGE = 'Successfully logged in.';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

/*
 * Handles 4xx and 5xx responses from the login call.
 */
function handleLoginError(error, setError) {
  if (error.response) {
    setError(error.response.data.message);
  } else {
    setError(DEFAULT_ERROR_MESSAGE);
  }
}

function LoginRedirect({ redirectLink }) {
  const { addToast } = useContext(toasterContext);
  useEffect(() => {
    addToast({ type: 'success', text: SUCCESS_MESSAGE });
  }, []);
  return <Navigate to={redirectLink} />;
}
LoginRedirect.propTypes = { redirectLink: propTypes.string.isRequired };

/*
 * Used to handle form submit event.
 */
function submitForm(event, details, setUser, setUserID, setError, getLoginURL) {
  event.preventDefault();
  usr.login(details.email, details.password, setUser, setUserID, handleLoginError, setError, getLoginURL); // eslint-disable-line
}

function LoginForm() {
  setTitle('Sign In');
  const {
    user,
    setUser,
    userID,
    setUserID,
    getLoginURL,
    getAuthURL,
    receivedURLs,
  } = useContext(UserContext);
  const [error, setError] = useState('');
  const [details, setDetails] = useState({ email: '', password: '' });
  const routerLocation = useLocation();
  useEffect(() => {
    if (user !== NULL_USER && !userID) {
      setUserID(details.email);
    }
  }, [user]);

  // If user was previously on a different page, redirect location points there, if not it points
  // to the home page
  const redirectLink = (routerLocation.state ? routerLocation.state.redirectLink : '/');

  // If user is logged in return them to either homepage, or their previous page
  if (simpleAuthorized(user, getAuthURL, receivedURLs)) {
    return <LoginRedirect redirectLink={redirectLink} />;
  }

  return (
    <ContentBox classes={['login_box']}>
      <form onSubmit={(event) => submitForm(
        event,
        details,
        setUser,
        setUserID,
        setError,
        getLoginURL,
      )}
      >
        <LoginFormHdr />
        {(error !== '') ? (<InlineMessage type="error" text={error} />) : ''}
        <TextField
          id="email"
          label="Email"
          onChange={(e) => setDetails({ ...details, email: e.target.value })}
          value={details.email}
          fullWidth
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          onChange={(e) => setDetails({ ...details, password: e.target.value })}
          value={details.password}
          fullWidth
        />
        <SigninButton />
        <SignupLink redirectLink={redirectLink} />
        <ResetLink />
      </form>
    </ContentBox>
  );
}

function LoginFormHdr() {
  return <h2 className="login_header">Log in to your account</h2>;
}

function SigninButton() {
  const { receivedURLs } = useContext(UserContext);
  return (
    <Button
      className="button--wide login_box__btn"
      type="submit"
      fullWidth
      disabled={!receivedURLs}
    >
      Log in
    </Button>
  );
}

function SignupLink({ redirectLink }) {
  return (
    <p className="login_signup_msg">
      Don&#700;t have an account?
      <br />
      <Link
        to={{
          pathname: SIGNUP_PATH,
          state: { redirectLink },
        }}
      >
        Sign up
      </Link>
    </p>
  );
}

SignupLink.propTypes = {
  redirectLink: propTypes.string.isRequired,
};

function ResetLink() {
  return (
    <p className="pswd_reset_msg">
      Forgot your password?
      <br />
      <Link to={RESET_PATH}> Reset your password </Link>
    </p>
  );
}

export default LoginForm;
