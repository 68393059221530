import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DISPLAY } from '../constants';

import Flex from '../../Common/FlexV2';
import DisplayReport from '../DisplayReport/DisplayReport';

function Content({
  analysis = '',
  dataSet,
  display = DISPLAY.AddText,
  form,
  updateAnalysis = () => {},
}) {
  const [userColumns, setUserColumns] = useState([]);
  switch (display) {
    case DISPLAY.Report:
      return (
        <Flex direction="column" gap={5}>
          <DisplayReport
            dataSet={dataSet}
            reportText={analysis}
            setReportText={updateAnalysis}
            canEdit
            form={form}
            userColumns={userColumns}
            setUserColumns={setUserColumns}
          />
        </Flex>
      );
    default:
      return (<>Error</>);
  }
}

export default Content;

Content.propTypes = {
  analysis: PropTypes.string,
  dataSet: PropTypes.instanceOf(Object).isRequired,
  display: PropTypes.string,
  form: PropTypes.instanceOf(Object).isRequired,
  updateAnalysis: PropTypes.func,
};
