import React from 'react';
import propTypes from 'prop-types';

import OldGraph from './OldLineGraph';

function reformatColumns(columns, xAxisID) {
  const xAxis = columns.find(({ id }) => id === xAxisID);
  const others = columns.filter(({ id }) => id !== xAxisID);
  return [xAxis].concat(others);
}

function reformatRows(rows, columns, xAxisID) {
  return rows.map((row) => reformatColumns(columns, xAxisID)
    .map(({ id }) => row[id]));
}

function LineGraph({
  rows,
  columns,
  xAxisID = 'year',
  isMultiAxes,
  variant = 'dmm',
}) {
  const dataSet = {
    data: reformatRows(rows, columns, xAxisID),
    cols: reformatColumns(columns, xAxisID),
  };
  return (
    <OldGraph
      dataSet={dataSet}
      isMultiAxes={isMultiAxes}
      variant={variant}
    />
  );
}
LineGraph.propTypes = {
  rows: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
  columns: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
  xAxisID: propTypes.string,
  isMultiAxes: propTypes.bool.isRequired,
  variant: propTypes.string,
};

export default LineGraph;
