import React, { useState } from 'react';
import propTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import Button from '../Button';
import Label from '../Label';
import { Question } from '../Questions';

import { formatQuestions } from '../../Utils/objectUtils';

import './ObjectArray.css';

function Headers({ questions }) {
  return (
    <thead>
      {Object.values(questions).map(({ question }) => <th>{question}</th>)}
    </thead>
  );
}
Headers.propTypes = {
  questions: propTypes.instanceOf(Object).isRequired,
};

function Item({ item, questions, removeFunction }) {
  return (
    <tr className="object-array-item">
      {Object.values(questions).map(({ fld_nm: key }) => <td>{item[key]}</td>)}
      <td>
        <Button variant="icon" onClick={removeFunction}>
          <CloseIcon />
        </Button>
      </td>
    </tr>
  );
}
Item.propTypes = {
  item: propTypes.instanceOf(Object).isRequired,
  questions: propTypes.instanceOf(Object).isRequired,
  removeFunction: propTypes.func.isRequired,
};

export default function ObjectArray({
  label,
  id,
  value,
  onChange,
  subfields,
}) {
  const [questions, setQuestions] = useState(formatQuestions(subfields));
  const responseSetter = (fieldName, newValue) => {
    const newQuestions = { ...questions };
    newQuestions[fieldName].value = newValue;
    setQuestions(newQuestions);
  };

  const add = () => {
    const entry = {};
    Object.values(questions).forEach((question) => {
      entry[question.fld_nm] = question.value;
      responseSetter(question.fld_nm, '');
    });
    onChange([...value, entry]);
  };
  const remove = (index) => {
    const newValue = value.filter((item, itemIndex) => index !== itemIndex);
    onChange(newValue);
  };

  return (
    <div className="object-array-wrapper">
      <Label text={label} htmlFor={id} />
      <table className="object-array-items">
        <Headers questions={questions} />
        <tbody>
          {value.map((item, index) => (
            <Item
              item={item}
              questions={questions}
              removeFunction={() => { remove(index); }}
            />
          ))}
          <tr className="object-array-fields">
            {Object.values(questions).map((field) => (
              <td>
                <Question
                  key={field.fld_nm}
                  question={field}
                  responseSetter={responseSetter}
                />
              </td>
            ))}
            <td>
              <Button variant="icon" onClick={add}>
                <AddIcon />
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
ObjectArray.propTypes = {
  label: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  value: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
  onChange: propTypes.func.isRequired,
  subfields: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
};
