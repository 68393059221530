import React from 'react';
import propTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// import { HMDA_RAW_PATH } from '../../../constants';

import SectionTitle from '../../core/Display/SectionTitle';
import Table from '../../core/Display/Table';

import './AggregateTable.css';

/*
const ignoredColumns = ['state_code', 'activity_year', 'country', 'division_code', 'region_code'];
*/

function AggregateTable({ aggregates, columns }) {
  if (!columns) return null;
  return (
    <div>
      {aggregates.map(({ name, data }) => (
        <React.Fragment key={name}>
          <SectionTitle text={name} />
          <Table
            key={name}
            rows={data}
            columns={columns}
            defaultSortedColumnID="activity_year"
          />
        </React.Fragment>
      ))}
    </div>
  );
}

AggregateTable.propTypes = {
  aggregates: propTypes.instanceOf(Object).isRequired,
  columns: propTypes.arrayOf(
    propTypes.instanceOf(Object),
  ).isRequired,
};

export default AggregateTable;
