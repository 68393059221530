export default function queryStringToObject(query) {
  if (typeof query !== 'string') throw Error('Expected string.');
  const paramArray = query.replace(/^\?/, '').split('&');
  const paramObject = {};
  paramArray.forEach((pair) => {
    const [key, value] = pair.split('=');
    const ignoredValues = ['All', 'undefined', 'null'];
    if (ignoredValues.includes(value)) return;
    paramObject[key] = value;
  });
  return paramObject;
}
