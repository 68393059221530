import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { isOnSafari } from '../../Utils/browserUtils';

import { CheckboxIcon, getTextColor } from '../Checkbox';
import Button from '../Button';
import TextField from '../TextField';
import '../Input.css';

/* An array of possible values for the user to select.
 * The `name` property is displayed to the user,
 * while the `id` property is stored in an array
 * (the `value` prop) which holds the selected options.
 */
const optionsProps = propTypes.arrayOf(
  propTypes.shape({ id: propTypes.string, name: propTypes.string }),
);

const alphabetize = ({ name: a }, { name: b }) => String(a).trim() > String(b).trim();

function Options({
  value,
  open,
  search,
  options,
  toggleOption,
  toggleAll,
  close,
  singleSelect,
  addOption,
  expandUpwards = false,
}) {
  if (!open) return null;

  const applySearch = () => {
    const lowerCaseSearch = search.toLowerCase();
    return options.filter(({ name }) => String(name).toLowerCase().includes(lowerCaseSearch));
  };
  const remainingOptions = useMemo(
    () => applySearch().sort(alphabetize),
    [search, options],
  );
  const getButtonStyle = (id) => getTextColor(value.includes(id));
  const allSelected = value.length === options.length;
  const noneSelected = value.length === 0;

  const containerClasses = ['multiple-select-options-container'];
  if (expandUpwards) containerClasses.push('upwards');

  return (
    <div className={containerClasses.join(' ')}>
      {!singleSelect && (
        <div className="multiple-select-options-top-bar">
          <button
            type="button"
            onClick={() => { toggleAll(true); }}
            style={getTextColor(allSelected)}
            className="multiple-select-options-button"
          >
            <CheckboxIcon checked={allSelected} />
            All
          </button>
          <button
            type="button"
            onClick={() => { toggleAll(false); }}
            style={getTextColor(noneSelected)}
            className="multiple-select-options-button"
          >
            <CheckboxIcon checked={noneSelected} />
            None
          </button>
          {addOption && (
            <Button varian="icon" onClick={() => addOption()}><AddIcon /></Button>
          )}
          <Button variant="icon" onClick={close}><CloseIcon /></Button>
        </div>
      )}
      {singleSelect && (
        <div className="multiple-select-options-top-bar">
          {addOption && (
            <Button variant="icon" onClick={() => addOption()}><AddIcon /></Button>
          )}
          <Button variant="icon" onClick={close}><CloseIcon /></Button>
        </div>
      )}
      <div className="multiple-select-options-list">
        {remainingOptions.map(({ id, name }) => (
          <div key={id} className="multiple-select-option">
            <button
              type="button"
              style={getButtonStyle(id)}
              onClick={() => { toggleOption(id); }}
              className="multiple-select-options-button"
            >
              <CheckboxIcon checked={value.includes(id)} />
              {String(name)}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
Options.propTypes = {
  value: propTypes.arrayOf(propTypes.string).isRequired,
  open: propTypes.bool.isRequired,
  search: propTypes.string.isRequired,
  options: optionsProps.isRequired,
  toggleOption: propTypes.func.isRequired,
  toggleAll: propTypes.func.isRequired,
  close: propTypes.func.isRequired,
  singleSelect: propTypes.bool.isRequired,
  addOption: propTypes.bool.isRequired,
  expandUpwards: propTypes.bool,
};

function renderPlaceholder(value, options, singleSelect = false) {
  if (value.length === 0) return 'None';
  if ((value.length === options.length) && !singleSelect) return 'All';
  const names = value.map((valueID) => {
    const option = options.find(({ id }) => id === valueID);
    if (!option) return undefined;
    return option.name;
  }).filter((name) => name);
  return names.join(', ');
}

/*
 *The Multiple Select component is a cross between a `<select>`
 * element and a set of `'<input type="checkbox">` elements.
 * It displays like a `select` when closed, but upon being clicked
 * allows users to select some subset of the options, rather than just 1.
 */
function MultipleSelect({
  options,
  id,
  label,
  onChange,
  value = [],
  optional = false,
  helperText = '',
  disabled = false,
  singleSelect = false,
  addOption = undefined,
  expandUpwards = false,
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const close = () => { setSearch(''); setOpen(false); };
  const handleTextInput = ({ target }) => { setSearch(target.value); };
  const toggleOption = (optionID) => {
    if (singleSelect) {
      const newValue = !value.includes(optionID)
        ? [optionID]
        : [];
      setSearch('');
      onChange(newValue);
      close();
      return;
    }
    const newValue = !value.includes(optionID)
      ? [...value, optionID].sort(alphabetize)
      : value.filter((valueID) => valueID !== optionID).sort(alphabetize);
    onChange(newValue);
  };
  const toggleAll = (select) => {
    if (singleSelect) return;
    if (!select) { onChange([]); return; }
    onChange(options.map((option) => option.id));
  };
  const handleBlur = ({ currentTarget }) => {
    requestAnimationFrame(() => {
      const thisOrChildIsFocused = currentTarget.contains(document.activeElement);
      if (thisOrChildIsFocused) return;
      if (isOnSafari) return;
      close();
    });
  };
  const pruneInvalidOptions = () => {
    const newValue = value.filter((valueID) => {
      const option = options.find(({ id: optionID }) => optionID === valueID);
      return option;
    });
    if (newValue.length === value.length) return;
    onChange(newValue);
  };

  useEffect(() => { pruneInvalidOptions(); }, [options]);

  return (
    <div id={id} className="multiple-select-wrapper" onBlur={handleBlur}>
      <TextField
        id={`${id}-text-field`}
        label={label}
        value={search}
        onChange={handleTextInput}
        placeholder={renderPlaceholder(value, options, singleSelect)}
        helperText={helperText}
        error={!optional && value.length === 0}
        onFocus={() => { setOpen(true); }}
        disabled={disabled}
      />
      <Options
        value={value}
        search={search}
        options={options}
        open={open}
        toggleOption={toggleOption}
        toggleAll={toggleAll}
        close={close}
        singleSelect={singleSelect}
        addOption={addOption}
        expandUpwards={expandUpwards}
      />
    </div>
  );
}
MultipleSelect.propTypes = {
  options: optionsProps.isRequired,
  id: propTypes.string.isRequired, // An ID unique to this input.
  /* A descriptive label for the input.
   * This is required for accessibility - it should be apparent what the input does.
   */
  label: propTypes.string.isRequired,
  optional: propTypes.bool, // If true, no error will be displayed when no input is given.
  /* An array of strings, where each string corresponds to the `id` property of some object
   * in the `options` array.
   */
  value: propTypes.arrayOf(propTypes.string),
  /* This function is called whenever an option is toggled, and is passed
   * an array of strings, where each string corresponds to the `id` property of some object
   * in the `options` array.
   * */
  onChange: propTypes.func.isRequired,
  helperText: propTypes.string,
  disabled: propTypes.bool,
  singleSelect: propTypes.bool,
  addOption: propTypes.func,
  expandUpwards: propTypes.bool,
};

export default MultipleSelect;
