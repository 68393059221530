import React from 'react';

import MarkdownParser from '../../core/Transform/MarkdownParser';
import ContentBox from '../../core/Display/ContentBox';

import ToSMD from '../../text/ToS';
import { setTitle } from '../../utils/browserUtils';
import './About.css';

function TermsOfService() {
  setTitle('Terms of Service');
  return (
    <div className="privacy-policy">
      <ContentBox>
        <MarkdownParser md={ToSMD} />
      </ContentBox>
    </div>
  );
}

export default TermsOfService;
