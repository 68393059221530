import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import { getColor } from '../../../constants';
import CustomLegend from './CustomLegend';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function BarChart({
  rows,
  columns,
  xAxisID = 'year',
  stackX = false,
  filterZeroTooltips = false,
}) {
  if (typeof window !== 'undefined') {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }

  const [enabledSets, setEnabledSets] = useState(columns.map(() => true));

  const years = useMemo(() => rows.map((row) => row[xAxisID]), [columns, rows, xAxisID]);

  const visibleDataSets = useMemo(() => {
    const dataColumns = columns.filter(({ id }) => id !== xAxisID);
    const dataSets = dataColumns.map(({ id, name, order }, index) => ({
      label: name,
      data: years.map((year, yearIndex) => rows[yearIndex][id]),
      backgroundColor: getColor(index + 1),
      stack: stackX ? undefined : `Stack ${index}`,
      order,
    }));
    return dataSets;
  }, [columns]);

  const filterDisabled = (prefilterData) => prefilterData
    .filter((set, index) => enabledSets[index]);

  const chartData = {
    labels: years,
    datasets: filterDisabled(visibleDataSets),
  };
  const tooltipFilter = (tooltipItem) => {
    if (filterZeroTooltips && tooltipItem.formattedValue === '0') return false;
    return tooltipItem.dataset.label.toLowerCase() !== xAxisID.toLowerCase()
      && tooltipItem.formattedValue !== 'NaN';
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: tooltipFilter,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: { stacked: stackX },
      y: { stacked: !stackX },
    },
  };

  const toggleSet = (index) => {
    enabledSets[index] = !enabledSets[index];
    setEnabledSets([...enabledSets]);
  };

  return (
    <>
      <CustomLegend
        columns={columns}
        enabledSets={enabledSets}
        toggleSet={toggleSet}
        isMultiAxes={false}
        xAxisID={xAxisID}
        isBar
      />
      <Bar
        data={chartData}
        options={options}
      />
    </>
  );
}

export default BarChart;

BarChart.propTypes = {
  rows: propTypes.arrayOf(
    propTypes.instanceOf(Object),
  ).isRequired,
  columns: propTypes.arrayOf(
    propTypes.instanceOf(Object),
  ).isRequired,
  xAxisID: propTypes.string,
  stackX: propTypes.bool,
  filterZeroTooltips: propTypes.bool,
};
