const name = 'Prasad Yalamanchi';
const role = 'Advisor';
const text = `Prasad is an entrepreneur associated with multiple start-ups in the areas
of Knowledge Based systems. Currently he is the CEO of the company he founded,
Lead Semantics, an NLP and Semantic Graph company headquartered in Chapel
Hill, North Carolina, with an engineering center in India. Lead Semantics
develops and sells TextDistil, a semantic neural language pipeline for ‘Fact’
extraction from ‘Text’ and ingesting the output semantic triples into a target
knowledge graph. TextDistil includes a visual graph debugger, FactCheck, and an
intelligent document corpus management with support for advanced search.`;

const prasadYalamanchi = { name, role, text };

export default prasadYalamanchi;
