import React from 'react';
import propTypes from 'prop-types';

import './GridAligner.css';

function GridAligner({
  children = null,
  classNames = [],
  columnMinWidth = '300px',
  gridGap = '30px',
}) {
  const classes = ['grid-aligner'].concat(classNames).join(' ');
  const style = {
    gridTemplateColumns: `repeat(auto-fit, minmax(${columnMinWidth}, 1fr))`,
    gridGap,
  };
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}

GridAligner.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  classNames: propTypes.arrayOf(propTypes.string),
  columnMinWidth: propTypes.string,
  gridGap: propTypes.string,
};

export default GridAligner;
