import NONE from '../../../constants';
import deepCopy from './deepCopy';

function formatQuestion(question, index) {
  const copy = deepCopy(question);
  copy.index = index;

  if (Array.isArray(copy.choices)) {
    const choicesObject = {};
    copy.choices.forEach((choice) => {
      choicesObject[choice] = choice;
    });
    copy.choices = choicesObject;
  }

  const optionalSelectAndNoDefault = (copy.optional
    && copy.choices
    && !copy.default
    && !copy.choices[NONE]);

  const optionalSelectAndDefault = (copy.optional
    && copy.choices
    && copy.default
    && !copy.choices.default);

  const mandatory = !copy.optional;

  if (optionalSelectAndNoDefault) {
    copy.choices[NONE] = NONE;
    copy.value = NONE;
    copy.default = NONE;
  } else if (optionalSelectAndDefault && !copy.multiple) {
    const defaultIsKey = Object.keys(copy.choices).includes(copy.default);
    const defaultIsValue = !defaultIsKey;

    if (defaultIsKey) { copy.value = copy.default; }
    if (defaultIsValue) {
      copy.choices.default = copy.default;
      copy.value = 'default';
    }
  }
  if (mandatory) {
    copy.value = copy.default;
    if (!copy.value && copy.choices) [copy.value] = Object.keys(copy.choices);
  }
  return copy;
}

export default function formatQuestions(questionsList) {
  const questionsObject = {};
  questionsList.forEach((question, index) => {
    const formattedQuestion = formatQuestion(question, index);
    questionsObject[formattedQuestion.fld_nm] = formattedQuestion;
  });
  return questionsObject;
}
