import React from 'react';
import PropTypes from 'prop-types';

export default function Aligner({
  className = '',
  children = null,
  width = 100,
  flex = false,
}) {
  return (
    <div
      style={{
        display: flex ? 'flex' : 'block',
        flexBasis: `${width}%`,
        flexGrow: 1,
        flexShrink: 1,
      }}
      className={className}
    >
      {children}
    </div>
  );
}

Aligner.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  flex: PropTypes.bool,
};
