import propTypes from 'prop-types';

const optionsProps = propTypes.shape({
  noEdit: propTypes.bool, // disabled adding columns
  noCSV: propTypes.bool, // disableds downloading as CSV
  allowAdd: propTypes.shape({ // options for adding rows
    isEnabled: propTypes.oneOfType([ // whether button is shown
      propTypes.bool,
      propTypes.func, // this should return a bool
    ]),
    form: propTypes.oneOfType([
      propTypes.instanceOf(Object), // form object
      propTypes.func, // function to retrieve form object
    ]),
    onSubmit: propTypes.func,
  }),
  allowEdit: propTypes.shape({ // options for editing rows
    isEnabled: propTypes.oneOfType([ // whether button is shown
      propTypes.bool,
      propTypes.func, // this should return a bool
    ]),
    form: propTypes.oneOfType([
      propTypes.instanceOf(Object), // form object
      propTypes.func, // function to retrieve form object
    ]),
    onSubmit: propTypes.func,
  }),
  allowDelete: propTypes.shape({ // options for deleting rows
    isEnabled: propTypes.oneOfType([ // whether button is shown
      propTypes.bool,
      propTypes.func, // this should return a bool
    ]),
    form: propTypes.oneOfType([
      propTypes.instanceOf(Object), // form object
      propTypes.func, // function to retrieve form object
    ]),
    onSubmit: propTypes.func,
  }),
});

export default optionsProps;
