const md = `
# About Us
### One night in the year 2020, Gene Callahan had a Eureka moment that ultimately resulted in DataMixMaster, the technology that "makes the world  your database."

The idea was born mostly formed and followed on the heels of a string of
expletives from his wife, Elen, after she'd completed another exhausting round
of data wrestling. In this Eureka moment (allegedly he actually shouted
"Eureka"... so far no documentary evidence has been able to substantiate this
claim), it occurred to Gene that, indeed, he had a solution for a problem that
everyone who works with data sources has to master one way or another. One way,
the traditional way, the way that his wife was using, is the brute force
method: spend your time or spend your money. Lots of time cramming, jamming,
and manipulating data so you can actually do something with it. If your own
time isn't the thing, you pay lots of money to other people to spend their time
wrassling the data. DataMixMaster is the "another" way - invent technology to
do what technology does best: repetitive tasks at scale to release humans to do
the creative work they are meant to do. Gene's solution would not only make his
wife's (and by extension, his) life easier, but could change the life of data
analysts everywhere for the better. An intuitive product that puts data
wrestling in the same category as blacksmithing - keep doing it if you like it,
but it's just not necessary any more.

This technology is patent pending; number: 63/357704.
`;

export default md;
