import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import UserContext from '../../../../UserContext';

import InlineMessage from '../../../../core/Display/InlineMessage';
import LoadingAnimation from '../../../../core/Display/LoadingAnimation';
import Dialog from '../../../../core/Display/Dialog';
import Text from '../../../../core/Display/Text';

import TextField from '../../../../core/Input/TextField';
import Button from '../../../../core/Input/Button';

import useSaveReport from './useSaveReport';
import generateURL from './generateURL';

/*
 * Given a duration in days, returns the date
 * on which the report will expire in the format
 * DD/MM/YYYY
 */
function calculateExpireDate(duration) {
  const expire = new Date();
  expire.setDate(expire.getDate() + duration);
  const dd = String(expire.getDate()).padStart(2, '0');
  const mm = String(expire.getMonth() + 1).padStart(2, '0');
  const yyyy = expire.getFullYear();
  return `${yyyy}/${mm}/${dd}`;
}
export { calculateExpireDate };

/*
 * Allows user to compose an email in their browser before clicking the mailto link
 * 'email' is an object with the properties 'recipients', 'subject', and 'body'
 * 'setEmail' changes the state of EmailPage
 * The 'Use Contact List' button sets 'email.recipients' equal to the saved contact
 * list of the user.
 */
function EmailForm({
  email,
  setEmail,
  recipList,
  setErrorNonfatal,
}) {
  const [recipFormVal, setRecipFormVal] = useState(email.recipients);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <TextField
          label="Recipients"
          id="EmailRecipients"
          helperText="Insert a comma-separated list of email adresses, or press 'Use Contact List' to use a saved list of recipients."
          value={recipFormVal}
          fullWidth
          onChange={(e) => {
            setEmail({
              recipients: e.target.value,
              subject: email.subject,
              body: email.body,
            });
            setRecipFormVal(e.target.value);
          }}
        />
        <Button onClick={() => {
          if (recipList) {
            setEmail({
              recipients: recipList,
              subject: email.subject,
              body: email.body,
            });
            setRecipFormVal(recipList);
          } else {
            setErrorNonfatal('There are no saved recipients for this account.');
          }
        }}
        >
          Use Contact List
        </Button>
      </div>
      <TextField
        label="Subject"
        id="EmailSubject"
        fullWidth
        defaultValue={email.subject}
        onChange={(e) => setEmail({
          recipients: email.recipients,
          subject: e.target.value,
          body: email.body,
        })}
      />
      <TextField
        label="Body"
        id="EmailBody"
        fullWidth
        defaultValue={email.body}
        multiline
        minRows={5}
        onChange={(e) => setEmail({
          recipients: email.recipients,
          subject: email.subject,
          body: e.target.value,
        })}
      />
    </div>
  );
}

function EmailPage({
  form,
  title = 'Report',
  userText = '',
  components = [],
  closeHandler = () => {},
  defaultReportID = '',
}) {
  const {
    user,
    userID,
    getSaveReportURL,
    getRecipListURL,
    receivedURLs,
  } = useContext(UserContext);
  const [reportID, setReportID] = useState(defaultReportID);
  const [reportURL, setReportURL] = useState(generateURL(reportID));
  const [email, setEmail] = useState({
    recipients: '',
    subject: title,
    body: '',
  });
  const [mailto, setMailto] = useState('');
  const [recipList, setRecipList] = useState('');
  const [errorFatal, setErrorFatal] = useState('');
  const [errorNonfatal, setErrorNonfatal] = useState('');

  useEffect(() => {
    const repURL = generateURL(reportID);
    const emailComp = {
      recipients: '',
      subject: title,
      body: repURL,
    };
    setReportURL(repURL);
    setEmail(emailComp);
  }, [reportID]);

  // Call backend to get report ID
  useEffect(() => {
    if (!receivedURLs) { return; }
    const report = {
      text: userText,
      title,
      components,
    };
    useSaveReport(
      form,
      getSaveReportURL,
      report,
      user,
      userID,
    )
      .then((data) => {
        const repID = data;
        setReportID(repID);
      })
      .catch((err) => {
        if (!reportID) setErrorFatal(err.message);
      });
  }, [receivedURLs]);

  // Call backend to get user recipient list
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (receivedURLs) {
      axios.get(getRecipListURL().concat('/', userID), { cancelToken: source.token })
        .then((resp) => resp.data)
        .then((data) => {
          let strList = '';
          data.forEach((adr) => {
            if (strList !== '') { strList = strList.concat(','); }
            strList = strList.concat(adr);
          });
          setRecipList(strList);
        })
        .catch(() => setErrorNonfatal('There was an error retrieving the recipient list. Try again later.'));
    }
    return () => {
      source.cancel('EmailPage unmounted: user recipient list request cancelled');
    };
  }, [receivedURLs]);

  // Set mailto link whenever email is updated
  useEffect(() => {
    const { recipients, subject, body } = email;
    const recipientField = 'mailto:'.concat(recipients);
    const subjectField = '?subject='.concat(subject);
    const bodyField = '&body='.concat(body.replace(/\n/g, '%0D%0A'));
    setMailto(recipientField.concat(subjectField, bodyField));
  }, [email]);

  if (errorFatal) {
    return (
      <InlineMessage type="error" text={errorFatal} />
    );
  }
  const canBeEmailed = (reportID && email);
  if (canBeEmailed) {
    return (
      <div>
        {errorNonfatal && (
          <Dialog
            footerContent={(
              <Button onClick={() => setErrorNonfatal('')}>OK</Button>
            )}
          >
            <Text>{errorNonfatal}</Text>
          </Dialog>
        )}
        <EmailForm
          email={email}
          setEmail={setEmail}
          recipList={recipList}
          setErrorNonfatal={setErrorNonfatal}
        />
        <div style={{ display: 'flex', gap: '20px' }}>
          <Button onClick={() => {
            navigator.clipboard.writeText(reportURL);
          }}
          >
            Copy Report URL
          </Button>
          <a href={mailto} target="_blank" rel="noopener noreferrer">
            <Button onClick={closeHandler}>Send Email</Button>
          </a>
        </div>
      </div>
    );
  }
  return (<LoadingAnimation />);
}

EmailPage.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  userText: PropTypes.string,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      enabled: PropTypes.bool,
      text: PropTypes.string,
    }),
  ),
  closeHandler: PropTypes.func,
  defaultReportID: PropTypes.string,
};

EmailForm.propTypes = {
  email: PropTypes.shape({
    recipients: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
  setEmail: PropTypes.func.isRequired,
  recipList: PropTypes.string.isRequired,
  setErrorNonfatal: PropTypes.func.isRequired,
};

export default EmailPage;
