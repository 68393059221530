import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../core/Display/Dialog';
import ContentBox from '../../core/Display/ContentBox';
import MarkdownParser from '../../core/Transform/MarkdownParser';

import Text from '../Common/Text';
import Flex from '../Common/FlexV2';
import Aligner from '../Common/AlignerV2';
import './About.css';
import { MOBILE } from '../../constants';
import { useScrollbarWidth, setTitle } from '../../utils/browserUtils';

import text from '../../text/about_text';
import eugeneCallahan from '../../text/members/EugeneCallahan';
// import seanCallahan from '../../text/members/SeanCallahan';
import kristianNikolov from '../../text/members/KristianNikolov';
// import samEbersole from '../../text/members/SamEbersole';
import prasadYalamanchi from '../../text/members/PrasadYalamanchi';
import scottJohnson from '../../text/members/ScottJohnson';
import boazKaufman from '../../text/members/BoazKaufman';
import ashishRaghute from '../../text/members/AshishRaghute';
import OpportunitySummary from '../../documents/SlickSheet.pdf';

const imageDir = '/images';

function MemberDisplay({
  img = '',
  format = 'right',
  children = [],
  alt = 'worker',
}) {
  const imageLast = (format === 'right');

  const DESC = (
    <Aligner key="member-text" width="65">
      <Text variant="body1">
        {children}
      </Text>
    </Aligner>
  );

  const IMG = (
    <Aligner key="member-photo" width="35">
      <img className="headshot_photo" src={img} alt={alt} />
    </Aligner>
  );

  const CONTENT = [IMG, DESC];
  const classNames = ['profile-box'];
  if (imageLast) classNames.push('image_last');

  return (
    <ContentBox>
      <div className={classNames.join(' ')}>
        {CONTENT}
      </div>
    </ContentBox>
  );
}

function About() {
  const [viewingDescription, setViewingDescription] = useState(null);
  setTitle('About');

  const aboutText = <MarkdownParser md={text} />;
  const workers = {
    EugeneCallahan: {
      ...eugeneCallahan,
      image: 'GeneCallahan.jpg',
    },
    // SeanCallahan: {
    //   ...seanCallahan,
    //   image: 'SeanCallahan.jpg',
    // },
    KristianNikolov: {
      ...kristianNikolov,
      image: 'KristianNikolov.jpg',
    },
    // SamEbersole: {
    //   ...samEbersole,
    //   image: 'SamEbersole.jpg',
    // },
    BoazKaufman: {
      ...boazKaufman,
      image: 'BoazKaufman.jpg',
    },
  };
  const advisors = {
    Prasad: {
      ...prasadYalamanchi,
      image: 'Prasad.jpg',
    },
    ScottJohnson: {
      ...scottJohnson,
      image: 'ScottJohnson.jpg',
    },
    AshishRaghute: {
      ...ashishRaghute,
      image: 'AshishRaghute.jpg',
    },
  };

  const getProfiles = (ourTeam) => {
    const profiles = [];
    const people = ourTeam ? workers : advisors;
    Object.keys(people).forEach((key) => {
      const { name, role } = people[key];
      profiles.push((
        <button key={key} onClick={() => setViewingDescription(people[key])} type="button">
          <MemberDisplay img={`${imageDir}/${people[key].image}`} alt={key}>
            <h2>
              <span className="role">{role}</span>
              {name}
            </h2>
          </MemberDisplay>
        </button>
      ));
    });
    return profiles;
  };

  return (
    <div
      className="about-page-wrapper"
      style={{
        width: `calc(100dvw - ${useScrollbarWidth() / 2}px)`,
      }}
    >
      <Flex direction="column">
        <ContentBox classes={['about-main']}>
          <div className="about_box" direction={MOBILE ? 'column' : 'row'}>
            <Aligner width="40">
              <Text variant="body1" component="div">
                {aboutText}
                <a
                  href={OpportunitySummary}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'lightblue', text_decoration: 'underline' }}
                >
                  {'Click here for a longer overview of our company outlook. '}
                </a>
              </Text>
            </Aligner>
          </div>
        </ContentBox>
        <ContentBox classes={['our-team']}>
          <div className="about-us-title">
            <Text>
              Our Team
            </Text>
          </div>
          <div className="about-us-profiles">
            { getProfiles(true) }
          </div>
          <div className="about-us-title">
            <Text>
              Our Advisors
            </Text>
          </div>
          <div className="about-us-profiles">
            { getProfiles(false) }
            {viewingDescription && (
              <Dialog
                closeFn={() => setViewingDescription(null)}
                title={viewingDescription.name}
                supertitle={viewingDescription.role}
                className="about-us-details"
              >
                <MarkdownParser md={viewingDescription.description || viewingDescription.text} />
              </Dialog>
            )}
          </div>
        </ContentBox>
      </Flex>
    </div>
  );
}

MemberDisplay.propTypes = {
  children: PropTypes.node,
  format: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
};

export default About;
