import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import { getErrorMessage } from '../../../core/Utils/objectUtils';

import Dialog from '../../../core/Display/Dialog';
import InlineMessage from '../../../core/Display/InlineMessage';

import MarkdownEditor from '../../../core/Input/MarkdownEditor';
import Button from '../../../core/Input/Button';
import ButtonGroup from '../../../core/Input/ButtonGroup';

import {
  getJournalText,
  editJournalText,
} from '../../../utils/networkUtils';
import UserContext from '../../../UserContext';

import SecurityContext from '../../SecurityContext';

const ERROR_MESSAGES = {
  FAILED_LOAD: 'Unable to retrieve content. Please try again later.',
  INVALID_STATE: (state) => `EditableText in an invalid state ${state}`,
};
const STATES = {
  LOADING: 0,
  ERROR: 1,
  DISPLAY: 2,
  EDITING: 3,
};

function ContentForm({
  initialContent,
  setState,
  setContent,
  className = '',
}) {
  const [text, setText] = useState(initialContent.text);
  const [submitted, setSubmitted] = useState(false);

  const [result, setResult] = useState({
    code: undefined,
    message: undefined,
  });

  const onCancel = () => { setState(STATES.DISPLAY); };
  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    editJournalText(initialContent.title, text)
      .then((response) => { setResult({ code: response.status, message: response.data }); })
      .catch((response) => { setResult({ code: response.status, message: response.data }); });
  };
  const confirmSuccess = () => {
    setContent({ title: initialContent.title, text });
    setState(STATES.DISPLAY);
  };
  const confirmError = () => { setState(STATES.DISPLAY); };

  const dialogType = () => {
    if (!submitted) return 'none';
    if (!result.code) return 'pending';
    if (result.code === 200) return 'success';
    return 'error';
  };

  return (
    <>
      {dialogType() === 'pending' && <Dialog title="Submitting changes..." />}
      {dialogType() === 'success' && <Dialog title="Success!" closeFn={confirmSuccess} />}
      {dialogType() === 'error' && <Dialog title="Error" closeFn={confirmError} />}
      <form className={className} onSubmit={onSubmit}>
        <MarkdownEditor
          value={text}
          onChange={setText}
        />
        <ButtonGroup>
          <Button type="button" color="primary" onClick={onCancel}>Cancel</Button>
          <Button type="submit" color="secondary">Save</Button>
        </ButtonGroup>
      </form>
    </>
  );
}
ContentForm.propTypes = {
  initialContent: propTypes.shape({
    title: propTypes.string,
    text: propTypes.string.isRequired,
  }).isRequired,
  setState: propTypes.func.isRequired,
  setContent: propTypes.func.isRequired,
  className: propTypes.string,
};

function EditButton({ setState }) {
  return <Button color="secondary" onClick={() => { setState(STATES.EDITING); }}>Edit</Button>;
}
EditButton.propTypes = {
  setState: propTypes.func.isRequired,
};

export default function EditableText({
  className = '',
  specifiedItem,
  noTitle = false,
}) {
  const { receivedURLs } = useContext(UserContext);
  const { setPage, isValidUser } = useContext(SecurityContext);
  const [policyDefined, setPolicyDefined] = useState(false);
  const [state, setState] = useState(STATES.LOADING);
  const [content, setContent] = useState();
  const [errors, setErrors] = useState([]);

  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (!receivedURLs) return;
    setPage('journal', () => { setPolicyDefined(true); });
    getJournalText({ specifiedItem })
      .then((data) => {
        setState(data ? STATES.DISPLAY : STATES.ERROR);
        setContent(data);
      })
      .catch(() => {
        setState(STATES.ERROR);
      });
  }, [receivedURLs]);
  useEffect(() => {
    if (!policyDefined) return;
    isValidUser('create')
      .then((response) => {
        if (response !== true) throw Error(`Received ${response} in response to isValidUser`);
        setCanEdit(true);
      })
      .catch((error) => {
        setErrors([...errors, getErrorMessage(error)]);
        setCanEdit(false);
      });
  }, [policyDefined]);

  switch (state) {
    case STATES.ERROR: return <InlineMessage type="error" text={ERROR_MESSAGES.FAILED_LOAD} />;
    case STATES.DISPLAY:
      return (
        <section className={className}>
          {!noTitle && <h2>{content.title}</h2>}
          <br />
          <div>
            <Markdown>{content.text}</Markdown>
          </div>
          {canEdit && <EditButton setState={setState} />}
        </section>
      );
    case STATES.EDITING:
      return (
        <ContentForm
          initialContent={content}
          setState={setState}
          setContent={setContent}
          className={className}
        />
      );
    case STATES.LOADING: return 'Loading...';
    default: return <InlineMessage type="error" text={ERROR_MESSAGES.INVALID_STATE(state)} />;
  }
}
EditableText.propTypes = {
  className: propTypes.string,
  specifiedItem: propTypes.string.isRequired,
  noTitle: propTypes.bool,
};
