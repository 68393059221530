import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Link,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  formatQuestions,
  questionsToQuery,
  objMissingRequiredField,
  questionsToQueryString,
} from '../../core/Utils/objectUtils';

import Questions from '../../core/Input/Questions';
import Button from '../../core/Input/Button';
import ContentBox from '../../core/Display/ContentBox';
import InlineMessage from '../../core/Display/InlineMessage';
import LoadingAnimation from '../../core/Display/LoadingAnimation';

import UserContext from '../../UserContext';
import {
  HMDA_AGGREGATE_PATH,
} from '../../constants';
import {
  getHMDAAggregateForm,
  getHMDAAggregateData,
  getHMDAAggregateFields,
  getHMDAColumnInfo,
} from '../../utils/networkUtils';
import { setTitle } from '../../utils/browserUtils';

import Graph from '../Common/Graph';

import FormContext, { FormContextProvider } from '../Common/FormContext';

import AggregateTable from './AggregateTable';
import TitlePrompt from './TitlePrompt';
import BackButton from './BackButton';
import searchToQuery from './searchToQuery';

const VIEWS = {
  error: 'error',
  loading: 'loading',
  content: 'content',
};
const MAX_COLUMNS = 20;

function cleanMarkdown(regions) {
  if (!regions) return null;
  return regions.map((region) => {
    const { data } = region;
    const newRows = data.map((row) => {
      const newRow = {};
      const keys = Object.keys(row);
      keys.forEach((key) => {
        const value = row[key];
        const isString = typeof value === 'string';
        if (!isString) { newRow[key] = value; return; }
        const newValue = value.replace(/\[(.*)\]\(.*\)/, '$1');
        newRow[key] = Number(newValue);
      });
      return newRow;
    });
    return { ...region, data: newRows };
  });
}

function formatAggregatesForGraph(aggregates) {
  const rows = [];
  aggregates.forEach(({ name, data }) => {
    data.forEach((row) => {
      const keys = Object.keys(row);
      const year = row.activity_year;
      const newRow = { year };
      keys.forEach((key) => {
        if (key === 'activity_year') return;
        newRow[`${name}-${key}`] = row[key];
      });

      const index = rows
        .findIndex(({ year: rowYear }) => rowYear === year);
      if (index === -1) { rows.push(newRow); return; }
      rows[index] = { ...rows[index], ...newRow };
    });
  });
  return rows;
}

function formatFieldsForGraph(aggregates, fields) {
  const columns = [{ id: 'year', name: 'Year', tags: {} }];

  aggregates.forEach((aggregate) => {
    const { name: regionName, data } = aggregate;

    const [firstRow] = data;
    if (typeof firstRow !== 'object') return;
    const keys = Object.keys(firstRow);

    keys.forEach((key) => {
      const field = fields.find(({ id }) => id === key);
      if (!field) return;
      if (field.id === 'activity_year') return;
      const id = `${regionName}-${field.id}`;
      const name = `${regionName}: ${field.name}`;
      const tags = {
        Region: regionName,
      };
      const [fieldName, aggregateName] = field.name.split(':');
      if (fieldName) tags.Field = fieldName;
      if (aggregateName) tags.Aggregate = field.name;
      columns.push({ id, name, tags });
    });
  });
  return columns;
}

function getInitialFilters(graphColumns) {
  const REGION = 'Region';
  const FIELD = 'Field';
  const AGGREGATE = 'Aggregate';
  const requiredTags = [REGION, FIELD, AGGREGATE];
  for (let i = 0; i < graphColumns.length; i += 1) {
    const { tags } = graphColumns[i];
    const keys = Object.keys(tags);
    if (requiredTags.every((tag) => keys.includes(tag))) {
      return {
        [REGION]: [tags[REGION]],
        [FIELD]: [tags[FIELD]],
      };
    }
  }
  return {};
}

function Options({ pageLink, pageName }) {
  const { receivedURLs } = useContext(UserContext);
  const {
    questions,
    setQuestions,
    answerQuestion,
  } = useContext(FormContext);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const [preformatQuestions, setPreformatQuestions] = useState();
  const [preformatFields, setPreformatFields] = useState();

  const loc = useLocation();
  let { state } = loc;
  if (!state) {
    const [path, params] = searchToQuery(loc.search);
    state = {
      path,
      params,
    };
  }

  useEffect(() => {
    if (!preformatQuestions) return;
    if (!preformatFields) return;
    const formattedQuestions = formatQuestions(preformatQuestions);

    const fieldNames = Object.keys(preformatFields);
    fieldNames.forEach((fieldName) => {
      const { choices } = formattedQuestions.fields;
      if (!choices[fieldName]) return;
      const displayName = preformatFields[fieldName].name
        || fieldName;
      choices[fieldName] = displayName;
    });
    const { params } = state;
    Object.keys(params).forEach((param) => {
      if (!param) return;
      const paramValue = params[param];
      if (formattedQuestions[param]) {
        formattedQuestions[param].value = paramValue;
      }
    });
    setQuestions(formattedQuestions);
    setLoading(false);
  }, [preformatQuestions, preformatFields]);

  useEffect(() => {
    if (!receivedURLs) return;
    getHMDAAggregateForm()
      .then((form) => {
        setPreformatQuestions(form);
      })
      .catch(({ message }) => { setErrorMessage(message); });
    getHMDAColumnInfo()
      .then((fields) => { setPreformatFields(fields); })
      .catch(({ message }) => { setErrorMessage(message); });
  }, [receivedURLs]);

  const [path, params] = questionsToQuery(questions);

  if (errorMessage) return <InlineMessage type="error" text={errorMessage} />;
  if (loading) return <LoadingAnimation />;
  return (
    <>
      <TitlePrompt type="aggregate" />
      <Questions
        questions={questions}
        responseSetter={answerQuestion}
        horizontal
      />
      <Link
        to={{
          pathname: pageLink(1),
          search: questionsToQueryString(questions),
          state: { path, params },
        }}
      >
        <Button
          direction="forward"
          disabled={objMissingRequiredField(questions)}
        >
          {pageName(1)}
        </Button>
      </Link>
    </>
  );
}

Options.propTypes = {
  pageName: PropTypes.func.isRequired,
  pageLink: PropTypes.func.isRequired,
};

function Results() {
  const [aggregates, setAggregates] = useState();
  const [fields, setFields] = useState([]);
  const [view, setView] = useState(VIEWS.loading);
  const [errorMessage, setErrorMessage] = useState();
  const { receivedURLs } = useContext(UserContext);
  setTitle('HMDA Aggregate Data');

  const loc = useLocation();
  const params = searchToQuery(loc.search)[1];

  const filterUnusedFields = useMemo(() => {
    const fallback = [];
    if (!fields) return fallback;
    if (!fields.length) return fallback;

    if (!aggregates) return fallback;
    if (!aggregates.length) return fallback;
    const [firstRegion] = aggregates;
    if (!firstRegion.data) return fallback;
    if (!Array.isArray(firstRegion.data)) return fallback;
    if (!firstRegion.data.length) return fallback;
    const [firstRow] = firstRegion.data;
    const usedFields = Object.keys(firstRow);
    return fields.filter(({ id }) => usedFields.includes(id));
  }, [aggregates, fields]);

  const formatData = (allData) => {
    allData.forEach(({ data }) => {
      data.sort(({ activity_year: a }, { activity_year: b }) => a > b);
    });
    return allData;
  };

  useEffect(() => {
    if (!receivedURLs) return;
    getHMDAAggregateData('', params)
      .then((data) => {
        const formattedData = formatData(data['Mortgage Aggregates']);
        if (!Object.keys(formattedData).length) {
          setView(VIEWS.error);
          setErrorMessage('Received no data.');
          return;
        }
        setAggregates(formattedData);
      })
      .catch(({ message }) => {
        setView(VIEWS.error);
        setErrorMessage(message);
      });
    getHMDAAggregateFields()
      .then((data) => {
        setFields(Object.keys(data).map((id) => {
          const field = {
            ...data[id],
            name: data[id].name || data[id].display_name,
            id,
            type: data[id].type || data[id]['field-type'],
          };
          delete field.description;
          return field;
        }));
      })
      .catch(({ message }) => {
        setView(VIEWS.error);
        setErrorMessage(message);
      });
  }, [receivedURLs]);

  const cleanedMarkdown = useMemo(
    () => cleanMarkdown(aggregates),
    [aggregates],
  );

  const graphRows = useMemo(
    () => {
      if (!aggregates) return [];
      return formatAggregatesForGraph(cleanedMarkdown);
    },
    [aggregates],
  );

  const graphColumns = useMemo(
    () => {
      if (!aggregates || !fields) return [];
      return formatFieldsForGraph(aggregates, fields);
    },
    [aggregates, fields],
  );

  const graphInitialFilters = useMemo(
    () => getInitialFilters(graphColumns),
    [graphColumns],
  );

  useEffect(() => {
    if (!aggregates) return;
    if (errorMessage) return;
    if (!Object.keys(graphInitialFilters).length && graphColumns.length > MAX_COLUMNS) return;
    setView(VIEWS.content);
  }, [aggregates, graphInitialFilters]);

  return (
    <>
      {view === VIEWS.error
      && <InlineMessage type="error" text={errorMessage} />}
      {view === VIEWS.loading
      && <LoadingAnimation />}
      {view === VIEWS.content
      && (
        <>
          <Graph
            rows={graphRows}
            columns={graphColumns}
            initialFilters={graphInitialFilters}
            maxColumns={MAX_COLUMNS}
          />
          <AggregateTable
            aggregates={aggregates}
            columns={filterUnusedFields}
          />
        </>
      )}
      <BackButton />
    </>
  );
}

function HMDAAggregate({
  optionsPath = HMDA_AGGREGATE_PATH,
  resultsPath = HMDA_AGGREGATE_PATH,
}) {
  const HMDAPages = [
    { name: 'Options', link: `${optionsPath}/options` },
    { name: 'Results', link: `${resultsPath}/results` },
  ];

  const pageName = (index) => HMDAPages[index]
    && HMDAPages[index].name;

  const pageLink = (index) => HMDAPages[index]
    && HMDAPages[index].link;

  return (
    <FormContextProvider>
      <ContentBox classes={['outlined', 'transparent']}>
        <Routes>
          <Route
            index
            element={(
              <Options
                pageName={(index) => pageName(index)}
                pageLink={(index) => pageLink(index)}
              />
            )}
          />
          <Route
            path="options"
            element={(
              <Options
                pageName={(index) => pageName(index)}
                pageLink={(index) => pageLink(index)}
              />
            )}
          />
          <Route
            path="results"
            element={(
              <Results
                pageName={() => pageName()}
                pageLink={(index) => pageLink(index)}
              />
            )}
          />
        </Routes>
      </ContentBox>
    </FormContextProvider>
  );
}

HMDAAggregate.propTypes = {
  optionsPath: PropTypes.string,
  resultsPath: PropTypes.string,
};

export default HMDAAggregate;
