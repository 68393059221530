const name = 'Scott Johnson';
const role = 'Advisor';
const text = `Scott Johnson is a practicing software engineer, entrepreneur and multiple time
startup founder.
Starting from his first company, NTERGAID, a PC hypertext
linking firm that shipped working software four years before the web, Scott has
created companies ranging from Feedster (blog search), AppData (app analytics)
and Anziani (eldercare software).  Among his non profit work are Covid Near Me
which provided local Covid analytics before Covid hit the mainstream and Pizza
for Ukraine which has sent over 2,500 pizzas to the Ukraine to feed the hungry.
From a technical perspective, Scott works with Ruby, Rails, Python and other
technologies to build large Internet applications.`;

const scottJohnson = { name, role, text };

export default scottJohnson;
