import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ButtonGroup from '../../core/Input/ButtonGroup';
import Button from '../../core/Input/Button';

import { REPORT_PATH } from '../../constants';

const PAGE_NAMES = [
  'Modify Report',
  'Generate Report',
];

const OPTIONS_PATH = `${REPORT_PATH}/create/options`;
const RESULTS_PATH = `${REPORT_PATH}/create/results`;
function PageButtons({
  nextDisabled = undefined,
  prevDisabled = undefined,
  currentPage = 'options',
}) {
  return (
    <ButtonGroup fullWidth>
      {currentPage === 'results' && (
        <>
          <Link to={OPTIONS_PATH}>
            <Button color="tertiary" direction="backward" disabled={prevDisabled}>
              {PAGE_NAMES[0]}
            </Button>
          </Link>
          <div />
        </>
      )}
      {currentPage === 'options' && (
        <>
          <div />
          <Link to={RESULTS_PATH}>
            <Button color="secondary" direction="forward" disabled={nextDisabled}>
              {PAGE_NAMES[1]}
            </Button>
          </Link>
        </>
      )}
    </ButtonGroup>
  );
}

PageButtons.propTypes = {
  nextDisabled: propTypes.bool,
  prevDisabled: propTypes.bool,
  currentPage: propTypes.string,
};

export default PageButtons;
