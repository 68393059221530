import React, { useContext, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import ButtonGroup from '../../core/Input/ButtonGroup';

import UserContext from '../../UserContext';
import {
  deleteMailingListEntry,
} from '../../utils/networkUtils';

import Dialog from '../../core/Display/Dialog';
import LoadingAnimation from '../../core/Display/LoadingAnimation';
import Text from '../../core/Display/Text';
import TextField from '../../core/Input/TextField';
import Button from '../../core/Input/Button';

import Flex from '../Common/FlexV2';

import DataViewHeader from '../Report/DisplayReport/Common/DataViewHeader';

import ListEntry from './ListEntry';
import DeleteConfirmationText from './DeleteConfirmationText';
import {
  addMailingListEntry,
  fetchMailingList,
} from './utils';

function MailingListView() {
  const {
    userID,
    receivedURLs,
    getRecipListURL,
    getRecipAddURL,
  } = useContext(UserContext);
  const [mailingList, setMailingList] = useState(null);
  const [editing, setEditing] = useState(false);
  const [fieldInput, setFieldInput] = useState('');
  const [message, setMessage] = useState('');

  const [addressToDelete, setAddressToDelete] = useState('');
  const [deleting, setDeleting] = useState(false);

  const generateList = () => {
    if (!mailingList) { return null; }
    const list = mailingList.map((addr) => {
      if (addr === '') { return null; }
      const onClick = () => { setAddressToDelete(addr); };
      const button = (
        <Button
          variant="icon"
          color="tertiary"
          onClick={onClick}
          title="Remove Recipient"
        >
          <DeleteIcon />
        </Button>
      );
      return <ListEntry key={addr} text={addr} button={button} />;
    });
    return list;
  };

  useEffect(() => {
    if (!receivedURLs) { return; }
    fetchMailingList(getRecipListURL, userID)
      .then((resp) => {
        setMailingList(resp.data);
      })
      .catch((err) => { setMailingList(err.message); });
  }, [receivedURLs]);

  const editHandler = (e) => { setFieldInput(e.target.value); };
  const confirmHandler = () => {
    addMailingListEntry(getRecipAddURL, userID, fieldInput)
      .then((resp) => {
        const nlist = [...mailingList];
        nlist.push(resp);
        setMailingList(nlist);
      })
      .catch((err) => { setMessage(err.message); });
    setFieldInput('');
    setEditing(false);
  };
  const cancelHandler = () => {
    setFieldInput('');
    setEditing(false);
  };

  const confirmChildren = deleting
    ? <LoadingAnimation />
    : <DeleteConfirmationText name={addressToDelete} />;

  const cancelDeleteHandler = () => {
    setAddressToDelete('');
  };

  const pruneAddress = (addr) => {
    const nlist = [];
    mailingList.forEach((entry) => {
      if (entry === addr) { return; }
      nlist.push(entry);
    });
    setMailingList(nlist);
  };

  const deleteSuccess = (addr) => {
    setDeleting(false);
    setAddressToDelete('');
    pruneAddress(addr);
  };
  const deleteFailure = () => {
    setMessage('Something went wrong');
    setDeleting(false);
    setAddressToDelete('');
  };

  const confirmDeleteHandler = () => {
    setDeleting(true);
    deleteMailingListEntry(userID, mailingList, addressToDelete)
      .then((resp) => { deleteSuccess(resp); })
      .catch(() => { deleteFailure(); });
  };

  return (
    <DataViewHeader title="Mailing List">
      <Flex direction="column">
        {message
        && (
          <Dialog
            centerAll
            footerContent={(
              <Button onClick={() => { setMessage(''); }}>OK</Button>
            )}
          >
            <Text>{message}</Text>
          </Dialog>
        )}
        {addressToDelete
        && (
          <Dialog
            centerAll
            closeFn={cancelDeleteHandler}
            footerContent={(
              <ButtonGroup center>
                <Button onClick={cancelDeleteHandler}>Cancel</Button>
                <Button onClick={confirmDeleteHandler}>Confirm</Button>
              </ButtonGroup>
            )}
          >
            {confirmChildren}
          </Dialog>
        )}
        {editing
        && (
        <Dialog
          closeFn={cancelHandler}
          footerContent={(
            <ButtonGroup center>
              <Button onClick={cancelHandler}>Cancel</Button>
              <Button onClick={confirmHandler}>Confirm</Button>
            </ButtonGroup>
          )}
        >
          <TextField fullWidth value={fieldInput} onChange={editHandler} />
        </Dialog>
        )}
        <Flex direction="column">
          {generateList()}
        </Flex>
        <Flex direction="row-reverse">
          <Button onClick={() => { setEditing(true); }}>Add Recipient</Button>
        </Flex>
      </Flex>
    </DataViewHeader>
  );
}

export default MailingListView;
