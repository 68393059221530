import React, { useState } from 'react';
import propTypes from 'prop-types';

import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import SectionTitle from '../../../../core/Display/SectionTitle';
import Text from '../../../../core/Display/Text';
import Button from '../../../../core/Input/Button';

import './DataViewHeader.css';

import { cropText } from '../../../../utils/stringUtils';

function ToggleButton({ open, setOpen }) {
  return (
    <Button
      variant="icon"
      onClick={() => setOpen(!open)}
      className="dvh_close"
      title={open ? 'Hide section' : 'View section'}
    >
      {open ? <ArrowDropDown /> : <ArrowLeft />}
    </Button>
  );
}
ToggleButton.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
};

function DataViewHeader({
  title,
  children = null,
  defaultOpen = false,
  enabled = true,
  onToggle = () => {},
  text = '',
}) {
  const [open, setOpen] = useState(defaultOpen);
  const openClass = open ? 'open' : 'closed';

  return (
    <section className="dvh">
      <div className={`dvh_div dvh_header ${openClass}`}>
        {false
        && (
        <div className="dvh_item dvh_toggle">
          <input
            className="dvh_checkbox"
            type="checkbox"
            defaultChecked={enabled}
            onChange={onToggle}
          />
        </div>
        )}
        <div className="dvh_item dvh_text">
          <SectionTitle text={title} />
        </div>
        <div className="dvh_item dvh_text">
          {!open && <Text>{cropText(text)}</Text>}
        </div>
        <div className="dvh_item dvh_button">
          <ToggleButton open={open} setOpen={setOpen} />
        </div>
      </div>
      <div className={`dvh_div dvh_body ${openClass}`}>
        {children}
      </div>
    </section>
  );
}

DataViewHeader.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
  defaultOpen: propTypes.bool,
  enabled: propTypes.bool,
  onToggle: propTypes.func,
  text: propTypes.string,
  title: propTypes.string.isRequired,
};

export default DataViewHeader;
export { cropText };
