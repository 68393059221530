import React from 'react';

import UserIDView from './UserIDView';
import MailingListView from './MailingListView';
import SavedReportsView from './SavedReportsView';

import { setTitle } from '../../utils/browserUtils';

function Profile() {
  setTitle('Profile');
  return (
    <div className="content-box">
      <UserIDView />
      <MailingListView />
      <SavedReportsView />
    </div>
  );
}

export default Profile;
