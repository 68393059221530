import { useState } from 'react';

/*
 * offsets: an object where each key corresponds
 *   to the label of a dataset and each value is
 *   the amount by which that dataset is offset
 * updateOffsets(key, value): sets offsets[key]
 *   equal to value, but does not update graphDataSet.
 *   This is a callback function used while the data
 *   is being dragged.
 * resetOffsets(): sets all values in offsets to 0.
 */
function useOffsets() {
  const [offsets, setOffsets] = useState({});

  const updateOffsets = (key, value) => {
    const newOffsets = offsets;
    newOffsets[key] = value;
    if (value === 0) { delete newOffsets[key]; }
    setOffsets(newOffsets);
  };

  const resetOffsets = () => {
    const newOffsets = offsets;
    Object.keys(newOffsets).forEach((key) => delete newOffsets[key]);
    setOffsets(newOffsets);
  };

  return [offsets, updateOffsets, resetOffsets];
}

/*
 * graphDataSet: the dataSet passed into GraphView,
 *   which reflects the offsets
 * updateGraphDataSet(offsets): recalculates what graphDataSet
 *   should be, based on dataSet and offsets. This is
 *   a callback function used whenever the user stops
 *   dragging.
 * resetGraphDataSet(newDataSet): sets graphDataSet equal
 *   to newDataSet
 */
function useGraphDataSet(dataSet) {
  const [graphDataSet, setGraphDataSet] = useState(JSON.parse(JSON.stringify(dataSet)));

  const updateGraphDataSet = (offsets) => {
    const newGraphDataSet = JSON.parse(JSON.stringify(graphDataSet)); // Deep copy
    Object.keys(offsets).forEach((setName) => {
      let setIndex = -1;
      // Get index of set
      graphDataSet.cols.forEach((element, index) => {
        if (element.name === setName) {
          setIndex = index;
        }
      });

      // Early return if no offsetting needs to be done
      if (setIndex === -1) {
        return;
      }
      if (!graphDataSet.data[0][setIndex] && graphDataSet.data[0][setIndex] != null) {
        return;
      }
      if (!offsets[setName]) {
        return;
      }

      // Perform offsetting
      const delta = offsets[setName];
      newGraphDataSet.data.forEach((element, index) => {
        // Go in reverse if delta is negative to avoid
        // erasing data we just wrote
        const signedIndex = (delta < 0) ? index : newGraphDataSet.data.length - index - 1;
        const newIndex = signedIndex + delta;
        // Check that new position is in range
        if (newIndex < 0 || newIndex > newGraphDataSet.data.length - 1) {
          return;
        }
        newGraphDataSet.data[newIndex][setIndex] = dataSet.data[signedIndex][setIndex];
        newGraphDataSet.data[signedIndex][setIndex] = null;
      });
    });
    setGraphDataSet(newGraphDataSet);
  };

  const resetGraphDataSet = (newDataSet) => {
    setGraphDataSet(newDataSet);
  };

  return [graphDataSet, updateGraphDataSet, resetGraphDataSet];
}

export { useOffsets, useGraphDataSet };
