import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';
import {
  ComposableMap,
  Geographies,
  Geography,
} from 'react-simple-maps';
import {
  Link,
  useParams,
  Navigate,
} from 'react-router-dom';

import { formatCellValue } from '../../core/Display/Table';
import columnProps from '../../core/Display/Table/columnProps';
import LoadingAnimation from '../../core/Display/LoadingAnimation';
import ContentBox from '../../core/Display/ContentBox';
import MarkdownParser, { interpretLink } from '../../core/Transform/MarkdownParser';

import UserContext from '../../UserContext';
import { SFA_STATE, REPORT_PATH } from '../../constants';
import {
  getUSStateFields,
  getUSStateReport,
  getUSStateReportFields,
  getUSStateRetrieve,
} from '../../utils/networkUtils';
import { capitalizeString, isExternalLink } from '../../utils/stringUtils';
import { setTitle } from '../../utils/browserUtils';

import GridAligner from '../Common/GridAligner';
import Graph from '../Common/Graph';

import { ReportContextProvider } from '../Report/ReportContext';
import ReportRetriever from '../Report/RerunReport/ReportRetriever';

import GEO_SOURCE from './us-albers.json';
import './SFAState.css';

const GRAPH_YEARS = {
  debt: 2022,
  delinquency: 2022,
  residential_balance: 2023,
  multifamily_balance: 2023,
};
const STRINGS = {
  description: 'Structured Finance helps build strong communities with affordable mortgages, access to homeownership, and a more inclusive economy.',
  returnLink: '< BACK TO MAP',
  reportLink: 'SFA data on this state*',
  reportTooltip: 'View and manipulate a variety of data sources about this state',
  dataCommonsLink: 'This state at Data Commons',
  dataCommonsTooltip: 'External link to Google\'s Data Commons Place Explorer',
};

const STATE_PROJECTIONS = {
  AK: { center: [-17.6, 26], scale: 3350 },
  AL: { center: [9, 32.625], scale: 6800 },
  AR: { center: [3.5, 34.75], scale: 9000 },
  AZ: { center: [-15.5, 34.15], scale: 5300 },
  CA: { center: [-23.5, 37.5], scale: 3000 },
  CO: { center: [-9.5, 39.05], scale: 7500 },
  CT: { center: [23.3, 41.51], scale: 25000 },
  DC: { center: [18.97, 38.8922], scale: 170000 },
  DE: { center: [20.5, 39.13], scale: 24500 },
  FL: { center: [12.5, 27.85], scale: 5400 },
  GA: { center: [12.5, 32.8], scale: 7500 },
  HI: { center: [-8.25, 25.9], scale: 8300 },
  IA: { center: [2.6, 42], scale: 9500 },
  ID: { center: [-18.5, 45.43], scale: 4450 },
  IL: { center: [6.85, 39.75], scale: 6000 },
  IN: { center: [9.75, 39.75], scale: 8000 },
  KS: { center: [-2.5, 38.5], scale: 7500 },
  KY: { center: [10.3, 37.75], scale: 7750 },
  LA: { center: [4.5, 31.03], scale: 8500 },
  MA: { center: [24.475, 42.25], scale: 16500 },
  MD: { center: [18.7, 38.85], scale: 12500 },
  ME: { center: [26.5, 45.2], scale: 7400 },
  MI: { center: [10, 44.85], scale: 5400 },
  MN: { center: [2, 46.4], scale: 5800 },
  MO: { center: [3.75, 38.35], scale: 7300 },
  MS: { center: [6.2, 32.63], scale: 6900 },
  MT: { center: [-13.75, 47], scale: 5600 },
  NC: { center: [16.14, 35.5], scale: 6500 },
  ND: { center: [-4.395, 47.5], scale: 8750 },
  NE: { center: [-3.7, 41.5], scale: 6950 },
  NH: { center: [24.5, 43.963], scale: 12400 },
  NJ: { center: [21.25, 40.125], scale: 14000 },
  NM: { center: [-10, 34.425], scale: 5800 },
  NV: { center: [-20.5, 38.65], scale: 4400 },
  NY: { center: [20.5, 43], scale: 7000 },
  OH: { center: [13.15, 40.325], scale: 9000 },
  OK: { center: [-2.65, 35.725], scale: 6600 },
  OR: { center: [-24.65, 44.1175], scale: 6350 },
  PA: { center: [18.3375, 40.8], scale: 9875 },
  RI: { center: [24.45, 41.5945], scale: 38300 },
  SC: { center: [14.95, 33.6], scale: 11000 },
  SD: { center: [-4.3, 44.25], scale: 8200 },
  TN: { center: [9.95, 35.75], scale: 6750 },
  TX: { center: [-4, 31.25], scale: 3000 },
  UT: { center: [-15.65, 39.5], scale: 6100 },
  VA: { center: [16.6, 37.75], scale: 7250 },
  VT: { center: [23.5, 43.9], scale: 13500 },
  WA: { center: [-24.55, 47.45], scale: 8500 },
  WI: { center: [6, 44.765], scale: 7450 },
  WV: { center: [15.5, 38.85], scale: 9500 },
  WY: { center: [-11.5, 43.0275], scale: 7400 },
};

function TestPanel({ number }) {
  return (
    <ReportContextProvider>
      <ReportRetriever
        reportid={`SFA Trends ${number}`}
        graphOnly
      />
    </ReportContextProvider>
  );
}
TestPanel.propTypes = { number: propTypes.number.isRequired };

function getStateInfo(code) {
  const states = GEO_SOURCE.objects.us.geometries;
  const state = states
    .find(({ properties }) => properties.iso_3166_2 === code);
  return state;
}

function convertFieldsForStacking(fields, yearData) {
  const newFields = fields
    .map((field) => {
      const newField = { ...field };
      newField.order = newField.id.includes('debt') ? 1 : 0;
      return newField;
    })
    .concat([{ id: 'category', name: 'Category' }]);
  const newData = [];
  fields.forEach(({ id }) => {
    if (yearData === undefined || yearData[id] === undefined) return;
    const idCategory = id.split('_').slice(0, -1).map(capitalizeString).join(' ');
    if (!idCategory) return;
    const row = newData.find(({ category }) => category === idCategory);
    if (!row) {
      newData.push({ category: idCategory, [id]: yearData[id] });
    } else {
      row[id] = yearData[id];
    }
  });
  return { columns: newFields, rows: newData };
}

function StateInfoLink({ markdown }) {
  const { text, url, title } = interpretLink(markdown);
  const revisedMarkdown = title === `(${url})`
    ? `[${text}*](${url} "${STRINGS.dataCommonsTooltip}")`
    : markdown;
  return (
    <div className="state-info-line">
      <span>
        <MarkdownParser
          md={revisedMarkdown}
          openNewTab={isExternalLink}
          className="inline-block"
        />
      </span>
    </div>
  );
}
StateInfoLink.propTypes = { markdown: propTypes.string.isRequired };

function StateInfoLine({ column, row }) {
  const { name, id, markdown } = column;
  const applyTransformations = (rawValue) => {
    switch (id) {
      case 'more_info': return rawValue.replace('More Info', 'Data Commons');
      default: return rawValue;
    }
  };
  const value = applyTransformations(row[id]);

  if (markdown) return <StateInfoLink markdown={value} />;
  return (
    <p className="state-info-line">
      <span>{`${name}: `}</span>
      <span>{formatCellValue(value, column)}</span>
    </p>
  );
}
StateInfoLine.propTypes = {
  column: columnProps.isRequired,
  row: propTypes.instanceOf(Object).isRequired,
};

function StateInfoPanel({ stateCode, stateStats, stateFields }) {
  if (!Object.keys(stateStats).length) return null;
  if (!Array.isArray(stateFields)) return null;

  const displayedFieldIDs = [
    'capital',
    'pop2020',
    'median_income2020',
    'more_info',
  ];
  const displayedFields = stateFields.filter(({ id }) => displayedFieldIDs.includes(id));

  return (
    <div className="sfa-state-stats">
      {displayedFields.map((column) => (
        <StateInfoLine key={column.id} column={column} row={stateStats} />
      ))}
      <div className="state-info-line">
        <span>
          <p style={{ textDecoration: 'underline' }}>
            <Link title={STRINGS.reportTooltip} to={`${REPORT_PATH}?filter_state_code=${stateCode}`}>
              {STRINGS.reportLink}
            </Link>
          </p>
        </span>
      </div>
    </div>
  );
}
StateInfoPanel.propTypes = {
  stateCode: propTypes.string.isRequired,
  stateStats: propTypes.instanceOf(Object).isRequired,
  stateFields: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
};

function GraphWrapper({ graph }) {
  return (
    <ContentBox classes={['transparent', 'no-shadow', 'sfa-state-graph-box']}>
      {(graph.entitle.includes('undefined'))
        ? <LoadingAnimation />
        : (
          <>
            <Graph
              title={graph.entitle}
              subtitle={graph.unit}
              columns={graph.data.columns}
              rows={graph.data.rows}
              xAxisID="category"
              disableAllOptions
              stackX
              filterZeroTooltips
            />
            <MarkdownParser
              md={graph.citation ? `Source: ${graph.citation}` : ''}
              openNewTab={isExternalLink}
            />
          </>
        )}
    </ContentBox>
  );
}
GraphWrapper.propTypes = {
  graph: propTypes.shape({
    entitle: propTypes.string,
    unit: propTypes.string,
    data: propTypes.arrayOf(propTypes.instanceOf(Object)),
    citation: propTypes.string,
  }).isRequired,
};

function SFAStates() {
  const { receivedURLs } = useContext(UserContext);
  const [fields, setFields] = useState([]);
  const [debtReport, setDebtReport] = useState({});
  const [delinquencyReport, setDelinquencyReport] = useState({});
  const [residentialBalanceReport, setResidentialBalanceReport] = useState({});
  const [multifamilyBalanceReport, setMultifamilyBalanceReport] = useState({});
  const [stateStats, setStateStats] = useState({});
  const [stateFields, setStateFields] = useState([]);

  const { stateCode: anyCaseStateCode } = useParams();
  if (typeof anyCaseStateCode !== 'string') {
    return <Navigate to={SFA_STATE} />;
  }
  const stateCode = anyCaseStateCode.toUpperCase();
  if (!Object.keys(STATE_PROJECTIONS).includes(stateCode)) {
    return <Navigate to={SFA_STATE} />;
  }

  const [stateInfo] = useState(getStateInfo(stateCode));
  setTitle(`${stateInfo.properties.name} Data`);

  useEffect(() => {
    if (!receivedURLs) return;
    getUSStateReport('debt', stateCode, GRAPH_YEARS.debt)
      .then(setDebtReport)
      .catch(() => {});
    getUSStateReport('delinquency', stateCode, GRAPH_YEARS.delinquency)
      .then(setDelinquencyReport)
      .catch(() => {});
    getUSStateReport('residential_balance', stateCode, GRAPH_YEARS.residential_balance)
      .then(setResidentialBalanceReport)
      .catch(() => {});
    getUSStateReport('multifamily_balance', stateCode, GRAPH_YEARS.multifamily_balance)
      .then(setMultifamilyBalanceReport)
      .catch(() => {});
    getUSStateReportFields()
      .then((data) => {
        const newFields = Object.keys(data).map((id) => ({ id, ...data[id] }));
        setFields(newFields);
      })
      .catch(() => {});
    getUSStateRetrieve(stateCode)
      .then(setStateStats)
      .catch(() => {});
    getUSStateFields()
      .then((data) => {
        const dataObj = data[Object.keys(data)[0]];
        const newStateFields = Object.keys(dataObj).map((id) => ({ id, ...dataObj[id] }));
        setStateFields(newStateFields);
      })
      .catch(() => {});
  }, [receivedURLs]);

  const graphs = useMemo(
    () => [
      {
        key: 'debtReport',
        entitle: `${debtReport.title}, ${GRAPH_YEARS.debt}`,
        data: convertFieldsForStacking(fields, debtReport.data),
        citation: debtReport.cite,
        unit: debtReport.units,
      },
      {
        key: 'delinquencyReport',
        entitle: `${delinquencyReport.title}, ${GRAPH_YEARS.delinquency}`,
        data: convertFieldsForStacking(fields, delinquencyReport.data),
        citation: delinquencyReport.cite,
        unit: delinquencyReport.units,
      },
      {
        key: 'residentialBalanceReport',
        entitle: `${residentialBalanceReport.title}, ${GRAPH_YEARS.residential_balance}`,
        data: convertFieldsForStacking(fields, residentialBalanceReport.data),
        citation: residentialBalanceReport.cite,
        unit: residentialBalanceReport.units,
      },
      {
        key: 'multiFamilyBalanceReport',
        entitle: `${multifamilyBalanceReport.title}, ${GRAPH_YEARS.multifamily_balance}`,
        data: convertFieldsForStacking(fields, multifamilyBalanceReport.data),
        citation: multifamilyBalanceReport.cite,
        unit: multifamilyBalanceReport.units,
      },
    ],
    [
      fields,
      debtReport,
      delinquencyReport,
      residentialBalanceReport,
      multifamilyBalanceReport,
    ],
  );
  // 'entitle' used to be 'title' but it caused a bug that generated extra blank graphs

  return (
    <div className="sfa-state-wrapper sizing-small">
      <ContentBox
        classes={[
          'outlined',
          'sfa-state-info-box',
          'no-shadow',
        ]}
      >
        <div className="sfa-state-map">
          <ComposableMap
            projection="geoAlbers"
            projectionConfig={STATE_PROJECTIONS[stateCode]}
          >
            <Geographies geography={GEO_SOURCE}>
              {({ geographies }) => geographies.map((geography) => {
                const geoCode = geography.properties.iso_3166_2;
                if (geoCode !== stateCode) return null;
                return (
                  <Geography
                    key={geography}
                    geography={geography}
                    fill="var(--color-8)"
                  />
                );
              })}
            </Geographies>
          </ComposableMap>
        </div>
        <div>
          <div className="sfa-state-nonmap">
            <h1 className="capitalize center-text">
              {stateInfo.properties.name}
            </h1>
          </div>
          <StateInfoPanel stateCode={stateCode} stateStats={stateStats} stateFields={stateFields} />
          <div className="sfa-state-return-link">
            <Link to={SFA_STATE}>{STRINGS.returnLink}</Link>
          </div>
        </div>
      </ContentBox>
      <div>
        <GridAligner
          columnMinWidth="400px"
          gridGap="0px"
        >
          {graphs.map((graph) => <GraphWrapper key={graph.key} graph={graph} />)}
        </GridAligner>
      </div>
    </div>
  );
}
export default SFAStates;
