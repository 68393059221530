import React from 'react';

function Contact() {
  return (
    <>
      Contact test
    </>
  );
}

export default Contact;
