import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import PageTitle from '../../../core/Display/PageTitle';
import SectionTitle from '../../../core/Display/SectionTitle';
import Table from '../../../core/Display/Table';
import InlineMessage from '../../../core/Display/InlineMessage';
import ContentBox from '../../../core/Display/ContentBox';
import InfoTooltip from '../../../core/Display/Tooltip';

import { equivalent, getMultipleSelectDefaultValue, queryStringToObject } from '../../../core/Utils/objectUtils';

import Questions, { DEFAULT } from '../../../core/Input/Questions';

import './SelectPage.css';

import ForceRefreshButton from './ForceRefreshButton';

import ReportContext from '../ReportContext';
import PageButtons from '../PageButtons';
import {
  SELECT_INSTRUCTION,
  SELECT_RADIO_OPTIONS,
  SELECT_SOURCES_ARE_INCOMPATIBLE,
} from '../strings';

import ReportTitle from '../Common/ReportTitle';

import ProgressIndicator from '../../Common/ProgressIndicator';

import UserContext from '../../../UserContext';
import { PAGE_TITLES, getSiteVariant } from '../../../constants';
import { cropText } from '../../../utils/stringUtils';
import { setTitle as setPageTitle } from '../../../utils/browserUtils';
import {
  fetchAPIs,
  getDataSourceCategories,
  getDataSrcForm,
  getDataSourceFields,
} from '../../../utils/networkUtils';

const TABLE_OPTIONS = { noEdit: true, noCSV: true };
const SELECT_CONTEXT = createContext();

function Instructions() {
  const {
    dataSources,
    sourcesAreIncompatible,
  } = useContext(ReportContext);
  if (dataSources.length === 0) return <InlineMessage type="hint" text={SELECT_INSTRUCTION} />;
  if (sourcesAreIncompatible()) {
    return <InlineMessage text={SELECT_SOURCES_ARE_INCOMPATIBLE} type="error" />;
  }
  return null;
}

function Settings() {
  const {
    dataSourceOptions,
    dataSources,
    questions,
    answerQuestion,
  } = useContext(ReportContext);

  /* Kludgy, but I couldn't find a better way to do this,
   * (At least not quickly / easily). Basically, the problem
   * was that when someone changed settings, it would work,
   * but the change wouldn't be visually shown to the user,
   * so it would look as if it didn't work. `questions` was
   * changing, but `questions` doesn't belong to `Settings`,
   * so it wasn't causing `Settings` to re-render. By adding
   * something in the state of `Settings` which changes whenever
   * `questions` does, this forces a re-render when a user
   * changes a setting.
   * Sam, 9.13.2023 :)
   */
  const [iterator, setIterator] = useState(0);

  const resetOptions = (question) => {
    const { choices, fullChoices } = question;
    const choiceKeys = Object.keys(fullChoices);
    if (!dataSources.length
      && equivalent(choices, fullChoices)) {
      questions.fullChoices = choices;
      answerQuestion('freq', choiceKeys[0]);
      return true;
    }
    return false;
  };
  const validateOptions = (question) => {
    const { choices, fullChoices } = question;
    if (!dataSources.length) return fullChoices;
    const choiceKeys = Object.keys(fullChoices);
    const selectedFrequencies = dataSources
      .map(({ sourceID }) => {
        const { stype } = dataSourceOptions[sourceID];
        return stype;
      })
      .reduce((list, item) => {
        if (list.includes(item)) return list;
        return list.concat([item]);
      }, []);
    const newChoices = {};
    choiceKeys.forEach((key) => {
      if (!selectedFrequencies.includes(key)) return;
      newChoices[key] = fullChoices[key];
    });
    if (equivalent(choices, newChoices)) return newChoices;
    return newChoices;
  };
  const validateAnswer = (question) => {
    const { choices, value } = question;
    const choiceKeys = Object.keys(choices);
    const newValue = choiceKeys.includes(value)
      ? value
      : choiceKeys[0];
    answerQuestion('freq', newValue);
  };

  useEffect(() => {
    const question = questions.freq;

    if (!question) return;
    if (Object.keys(dataSourceOptions).length <= 1) return;
    if (resetOptions(question)) return;

    const newChoices = validateOptions(question);
    question.choices = newChoices;

    validateAnswer(question);
  }, [dataSources, questions, dataSourceOptions]);

  const filteredQuestions = {};
  Object.keys(questions).forEach((fieldName) => {
    const excludedFields = [
      'api_ids',
      'title',
      'start_date',
      'end_date',
      'sort',
    ];
    if (excludedFields.includes(fieldName)) return;
    filteredQuestions[fieldName] = questions[fieldName];
  });

  return (
    <Questions
      questions={filteredQuestions}
      responseSetter={(id, value, typecast) => {
        answerQuestion(id, value, typecast);
        setIterator(iterator + 1);
      }}
    />
  );
}

function useLocationFailsafe() {
  /*
   * useLocation was failing in testing, but not in the browser.
   * This basically just says "there's no search query" if
   * location is undefined.
   * */
  try {
    return useLocation();
  } catch {
    return { search: '' };
  }
}

function parseInitialFilters(query) {
  if (typeof query !== 'object') return {};

  const keys = Object.keys(query);
  const filterKeys = keys
    .filter((key) => key.includes('filter_'));
  const filters = {};
  filterKeys.forEach((key) => {
    const newKey = key.slice(7);
    filters[newKey] = query[key];
  });

  return filters;
}

function SelectPage() {
  setPageTitle(PAGE_TITLES.CreateReport);
  const { receivedURLs } = useContext(UserContext);
  const {
    title,
    setTitle,
    dataSourceFilters,
    setDataSourceFilters,
    dataSources,
    addSource,
    addSources,
    removeSource,
    dataCategoryOptions,
    setDataCategoryOptions,
    dataSourceOptions,
    setDataSourceOptions,
    sourcesValidated,
    sourcesAreIncompatible,
    questions,
  } = useContext(ReportContext);
  const { search: urlQuery } = useLocationFailsafe();
  const query = useMemo(() => queryStringToObject(urlQuery), [urlQuery]);
  useEffect(() => {
    if (!Object.keys(dataSourceOptions).length) return;
    if (dataSources.length) return;
    if (!query.api_ids) return;
    addSources(query.api_ids.split(','));
  }, [dataSourceOptions]);

  const [fetchingData, setFetchingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [defaultFilters, setDefaultFilters] = useState({});

  const [fieldsObject, setFieldsObject] = useState({});
  const [addTableColumns, setAddTableColumns] = useState([]);
  const [removeTableColumns, setRemoveTableColumns] = useState([]);

  const [nextDisabled, setNextDisabled] = useState(true);

  const resetFilter = () => {
    const updatedFilters = {};
    Object.keys(dataSourceFilters).forEach((key) => {
      const field = dataSourceFilters[key];
      updatedFilters[key] = field;
      updatedFilters[key].value = defaultFilters[key];
    });
    setDataSourceFilters(updatedFilters);
  };

  const getDefaults = (form) => {
    let defaults = {};
    form.forEach((question) => {
      const {
        fld_nm: fieldName,
        default: defaultValue,
        multiple,
        subfields,
      } = question;
      defaults[fieldName] = multiple
        ? getMultipleSelectDefaultValue(question)
        : defaultValue;
      if (subfields) {
        defaults = { ...defaults, ...getDefaults(subfields) };
      }
    });
    return defaults;
  };

  const getFormDefault = (fullForm) => {
    const defFilters = getDefaults(fullForm);
    setDefaultFilters(defFilters);
    return defFilters;
  };

  const initSourceFilters = (preformatForm) => {
    const defVals = getFormDefault(preformatForm);
    const updatedFilters = {};
    Object.values(preformatForm).forEach((field) => {
      updatedFilters[field.fld_nm] = field;
      updatedFilters[field.fld_nm].value = defVals[field.fld_nm];
    });
    Object.keys(defVals).forEach((key) => {
      if (!updatedFilters[key]) updatedFilters[key] = { value: defVals[key] };
    });
    const queryFilters = parseInitialFilters(query);
    Object.keys(queryFilters).forEach((key) => {
      const value = queryFilters[key];
      if (!updatedFilters[key]) updatedFilters[key] = {};
      updatedFilters[key].value = value;
    });
    setDataSourceFilters({ ...updatedFilters });
  };

  const handleFormChange = (fieldName, answer, typecast) => {
    let typecastAnswer = answer;
    switch (typecast) {
      case 'int':
        typecastAnswer = parseInt(typecastAnswer, 10);
        break;
      default:
    }
    if (!dataSourceFilters[fieldName]) dataSourceFilters[fieldName] = {};
    if (!dataSourceFilters[fieldName].value) dataSourceFilters[fieldName].value = {};
    dataSourceFilters[fieldName].value = typecastAnswer;
    setDataSourceFilters({ ...dataSourceFilters });
  };

  const isChecked = (sourceID) => dataSources
    .find(({ sourceID: id }) => id === sourceID) !== undefined;

  const sourcesToRows = () => {
    const keys = Object.keys(dataSourceOptions);
    const rows = keys.map((key) => {
      const option = dataSourceOptions[key];
      const shortenedDescription = cropText(option.descr || '');
      const wasShortened = option.descr && option.descr !== shortenedDescription;
      const rowDescription = wasShortened
        ? (
          <span>
            {shortenedDescription}
            <InfoTooltip text={option.descr} />
          </span>
        ) : shortenedDescription;
      const row = { searchableDescription: option.descr || '' };
      addTableColumns.forEach(({ id: columnID }) => {
        if (columnID === 'descr') { row.descr = rowDescription; return; }
        let value = String(option[columnID]);
        const falsyValues = ['undefined', 'false', 'null'];
        if (falsyValues.includes(value)) value = '';
        value = value.trim();
        row[columnID] = value;
      });
      row.id = key;
      row.actions = isChecked(key);
      return row;
    });
    return rows;
  };
  const [sourceRows, setSourceRows] = useState(sourcesToRows());

  const toggleSource = (sourceID) => {
    const selected = dataSources.find(({ sourceID: id }) => id === sourceID);
    if (selected) {
      removeSource(sourceID);
    } else { addSource(sourceID); }
  };

  const formatColumns = () => {
    const keys = Object.keys(fieldsObject);
    const newAddTableColumns = [];
    const newRemoveTableColumns = [];
    keys.forEach((key) => {
      const columnObject = {
        ...fieldsObject[key],
        id: key,
      };

      if (key !== 'Add') {
        newAddTableColumns.push(columnObject);
        newRemoveTableColumns.push(columnObject);
        return;
      }

      const addTableColumnObject = {
        ...columnObject,
        id: 'actions',
        name: 'Add',
        noSort: true,
        onChange: ({ id }) => { toggleSource(id); },
      };
      const removeTableColumnObject = {
        ...columnObject,
        id: 'actions',
        name: 'Remove',
        noSort: true,
        onChange: ({ id }) => { toggleSource(id); },
      };
      newAddTableColumns.push(addTableColumnObject);
      newRemoveTableColumns.push(removeTableColumnObject);
    });
    setAddTableColumns(newAddTableColumns);
    setRemoveTableColumns(newRemoveTableColumns);
  };

  const contextValue = useMemo(
    () => ({
      toggleSource,
      isChecked,
    }),
    [dataSources],
  );

  useEffect(() => {
    sourceRows.forEach((row) => {
      row.actions = isChecked(row.id); // eslint-disable-line
    });
    setSourceRows([...sourceRows]);
  }, [dataSources]);

  const getFilterValue = (key) => {
    if (!dataSourceFilters) return undefined;
    if (!dataSourceFilters[key]) return undefined;
    return dataSourceFilters[key].value;
  };

  const valueMatchesFilter = (rowValue, filterField) => {
    const { multiple, value } = dataSourceFilters[filterField];
    if (value.length === 0) return true;
    if (value === DEFAULT) return true;
    if (!multiple) return rowValue === value;
    return value.includes(rowValue);
  };

  const filterSourceRows = () => sourceRows.filter((row) => {
    const filterCategories = getFilterValue('cat_code') || [];
    const rowHasCategoryFilter = getFilterValue('cat_code') === DEFAULT
      || !getFilterValue('cat_code').length
      || filterCategories.includes(row.cat_code)
      || filterCategories.includes(row.cat_code2);

    if (!rowHasCategoryFilter) return false;

    const searchText = getFilterValue('text').toLowerCase();
    if (getFilterValue('text') !== defaultFilters.text
      && !row.name.toLowerCase().includes(searchText)
      && !row.searchableDescription.toLowerCase().includes(searchText)) { return false; }
    const irregularFields = [
      'cat_code',
      'factor1',
      'text',
    ];
    const regularFields = Object.keys(dataSourceFilters)
      .filter((fieldName) => !irregularFields.includes(fieldName));
    for (let i = 0; i < regularFields.length; i += 1) {
      const fieldName = regularFields[i];
      const matches = valueMatchesFilter(row[fieldName], fieldName);
      if (!matches) return false;
    }
    return true;
  });

  useEffect(() => {
    if (!receivedURLs) return;
    getDataSourceCategories()
      .then((categories) => { setDataCategoryOptions(categories); })
      .catch(() => {});
    fetchAPIs()
      .then((sources) => { setDataSourceOptions(sources); })
      .catch(() => {});
    getDataSrcForm()
      .then((preformatForm) => {
        if (Object.keys(dataSourceFilters).length) return;
        initSourceFilters(preformatForm);
      })
      .catch(() => {});
    getDataSourceFields()
      .then(setFieldsObject)
      .catch(() => {});
  }, [receivedURLs]);

  useEffect(() => {
    formatColumns();
  }, [fetchingData]);

  useEffect(() => {
    setSourceRows(sourcesToRows());
  }, [addTableColumns]);

  const checkIfCanProcede = () => {
    if (sourcesAreIncompatible() || dataSources.length === 0) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  };

  useEffect(() => {
    if (Object.keys(dataCategoryOptions).length < 1) return;
    if (Object.keys(dataSourceOptions).length < 1) return;
    if (Object.keys(fieldsObject).length < 1) return;
    if (Object.keys(dataSourceFilters).length < 1) return;
    setFetchingData(false);
    checkIfCanProcede();
  }, [dataCategoryOptions, dataSourceOptions, fieldsObject, dataSourceFilters]);

  useEffect(() => {
    if (!loading) return;
    if (fetchingData) return;
    if (!sourcesValidated) return;
    setLoading(false);
  }, [fetchingData, loading, sourcesValidated]);

  // Disable next page button if no API has been selected
  useEffect(() => {
    checkIfCanProcede();
  }, [dataSources]);

  useEffect(() => {
    const addColumn = addTableColumns.find(({ id }) => id === 'actions');
    if (addColumn) addColumn.onChange = ({ id }) => { toggleSource(id); };
    const removeColumn = removeTableColumns.find(({ id }) => id === 'actions');
    if (removeColumn) removeColumn.onChange = ({ id }) => { toggleSource(id); };
    for (let i = 0; i < sourceRows.length; i += 1) {
      const row = sourceRows[i];
      row.actions = Boolean(dataSources
        .find(({ sourceID }) => sourceID === row.id));
    }
    setAddTableColumns([...addTableColumns]);
    setRemoveTableColumns([...removeTableColumns]);
  }, [dataSources]);

  if (loading) {
    return (
      <ContentBox classes={['select-page-progress-indicator-wrapper']}>
        <ProgressIndicator
          steps={[
            'Retrieving filter form',
            'Retrieving table headers',
            'Retrieving data sources',
            'Retrieving options form',
          ]}
          progress={[
            Object.keys(dataSourceFilters).length > 0,
            Object.keys(fieldsObject).length > 0,
            Object.keys(dataSourceOptions).length > 0,
            Object.keys(questions).length > 0,
          ]}
        />
      </ContentBox>
    );
  }

  return (
    <SELECT_CONTEXT.Provider value={contextValue}>
      <ContentBox classes={['glass', 'full-width']}>
        <div className="report-select-page-upper-panel">
          <div className="report-select-page-upper-panel-titles">
            <PageTitle text={PAGE_TITLES.CreateReport} />
            <SectionTitle text="Add Data Sets to Report" />
          </div>
          <div className="report-select-page-upper-panel-filter">
            <Questions
              horizontal
              questions={dataSourceFilters}
              responseSetter={(id, value, typecast) => {
                handleFormChange(id, value, typecast);
              }}
              reset={{ text: SELECT_RADIO_OPTIONS.reset, fn: resetFilter, var: getSiteVariant() }}
            />
          </div>
        </div>
      </ContentBox>
      <div style={{ height: '3rem' }} />
      <ContentBox>
        <div className="available-sources-title-bar">
          <SectionTitle text="Available Sources" />
          <ForceRefreshButton />
        </div>
        <Table
          columns={addTableColumns}
          rows={filterSourceRows()}
          options={TABLE_OPTIONS}
          keyFieldName="id"
          defaultSortedColumnID="name"
        />
      </ContentBox>
      <ContentBox>
        <SectionTitle text="Chosen Sources" />
        <Instructions />
        {dataSources.length > 0
          && (
            <Table
              columns={removeTableColumns}
              rows={dataSources.map(({ sourceID }) => sourceRows.find(({ id }) => id === sourceID))}
              options={TABLE_OPTIONS}
              keyFieldName="id"
              defaultSortedColumnI="name"
            />
          )}
      </ContentBox>
      <ContentBox>
        <ReportTitle text={title} onChange={setTitle} canEdit />
        <Settings />
        <PageButtons currentPage="options" nextDisabled={nextDisabled} />
      </ContentBox>
    </SELECT_CONTEXT.Provider>
  );
}

export default SelectPage;
