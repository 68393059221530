import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { deepCopy } from '../../core/Utils/objectUtils';

import UserContext from '../../UserContext';
import {
  getSecurityPolicy,
  getIsValidUser,
} from '../../utils/networkUtils';

const SecurityContext = createContext();
export default SecurityContext;

const FALLBACK = {
  read: {},
};

export function SecurityContextProvider({ children = null }) {
  const { userID, receivedURLs } = useContext(UserContext);

  const [page, setPageState] = useState();
  const [policy, setPolicy] = useState({});

  const setPage = (newPage, callback = () => {}) => {
    getSecurityPolicy(newPage)
      .then((newPolicy) => {
        setPolicy(newPolicy);
        setPageState(newPage);
        callback(newPolicy);
      })
      .catch(() => {
        setPageState(newPage);
        setPolicy(FALLBACK);
        callback(FALLBACK);
      });
  };

  const isValidUser = (action, protocol = page) => getIsValidUser(protocol, action, userID);
  const getPolicy = () => deepCopy(policy);

  useEffect(() => {
    if (!page) return;
    getSecurityPolicy(page).then(setPolicy).catch(() => { setPolicy(FALLBACK); });
  }, [page]);

  const value = useMemo(
    () => ({
      page,
      setPage,
      getPolicy,
      isValidUser,
      receivedURLs,
    }),
    [
      page,
      policy,
      receivedURLs,
    ],
  );
  return (
    <SecurityContext.Provider value={value}>
      {children}
    </SecurityContext.Provider>
  );
}

SecurityContextProvider.propTypes = { children: propTypes.node };
