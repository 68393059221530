import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import {
  formatQuestions,
  headersToColumns,
  resultsToArray,
  questionsToQueryString,
  queryStringToParams,
  queryStringToPath,
  requiredValuesMissing,
} from '../../core/Utils/objectUtils';

import Questions from '../../core/Input/Questions';

import ContentBox from '../../core/Display/ContentBox';
import InlineMessage from '../../core/Display/InlineMessage';
import Table from '../../core/Display/Table';

import {
  getCollectionInfo,
  // === Glossary ===
  getAddGlossaryItemForm,
  addGlossaryItem,
  editGlossaryItem,
  deleteGlossaryItem,
  // === Bibliography ===
  getAddBibliographyItemForm,
  addBibliographyItem,
  editBibliographyItem,
  deleteBibliographyItem,
} from '../../utils/networkUtils';
import { setTitle } from '../../utils/browserUtils';
import UserContext from '../../UserContext';
import BACKGROUND_IMAGE from '../../images/DMM_geohero_01_2X.png';

import BackgroundImage from '../Common/BackgroundImage';
import ProgressIndicator from '../Common/ProgressIndicator';
import SecurityContext from '../SecurityContext';

import './Collection.css';

const VALID_PAGE_NAMES = ['Glossary', 'Bibliography'];
const VALID_PAGE_PROTOCOLS = ['/Glossary', '/Bibliography'];
const LOADING_STEPS = [
  'Retrieving endpoint map',
  'Retrieving column headers',
  'Retrieving table data',
  'Checking permission to add entries',
  'Checking permission to edit entries',
  'Checking permission to delete entries',
];

export function getOptions(collectionEndpoint, setOptions, setSearchValid = () => {}) {
  getCollectionInfo(collectionEndpoint, 'form').then((data) => {
    const [firstKey] = Object.keys(data);
    const preFormatOptions = data[firstKey];
    const postFormatOptions = formatQuestions(preFormatOptions);
    setOptions(postFormatOptions);
    setSearchValid(!requiredValuesMissing(postFormatOptions));
  });
}

function Options({
  pageName,
  pageProtocol,
  onSubmit,
  setSearchValid,
}) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();
  const setOption = (fieldName, value) => setOptions({
    ...options,
    [fieldName]: { ...options[fieldName], value },
  });
  const { receivedURLs } = useContext(UserContext);

  useEffect(
    () => {
      if (!receivedURLs) return;
      setLoading(true);
      getOptions(pageProtocol, setOptions, setSearchValid);
    },
    [receivedURLs, pageName],
  );
  useEffect(
    () => {
      if (!options) return;
      if (loading) setLoading(false);
    },
    [options],
  );

  if (loading) return <ContentBox>Loading...</ContentBox>;
  return (
    <ContentBox classes={['search-options', 'glass', 'full-width']}>
      <h1>{pageName}</h1>
      {Object.keys(options).length > 0 && (
        <Questions
          questions={options}
          responseSetter={setOption}
          horizontal
          onSubmit={() => { onSubmit(options); setSearchValid(!requiredValuesMissing(options)); }}
          submitButtonText="Search"
        />
      )}
    </ContentBox>
  );
}
Options.propTypes = {
  pageName: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
  pageProtocol: propTypes.oneOf(VALID_PAGE_PROTOCOLS).isRequired,
  setSearchValid: propTypes.func.isRequired,
};

function getHeaders(pageProtocol, setHeaders) {
  getCollectionInfo(pageProtocol, 'fields').then((data) => {
    const [firstKey] = Object.keys(data);
    const headers = data[firstKey];
    setHeaders(headers);
  });
}

function getTableOptions(pageName, permissions) {
  return {
    noEdit: true,
    allowAdd: {
      isEnabled: permissions.add,
      form: pageName === 'Glossary' ? getAddGlossaryItemForm : getAddBibliographyItemForm,
      onSubmit: pageName === 'Glossary' ? addGlossaryItem : addBibliographyItem,
    },
    allowEdit: {
      isEnabled: permissions.edit,
      form: pageName === 'Glossary' ? getAddGlossaryItemForm : getAddBibliographyItemForm,
      onSubmit: pageName === 'Glossary' ? editGlossaryItem : editBibliographyItem,
    },
    allowDelete: {
      isEnabled: permissions.delete,
      form: [],
      onSubmit: pageName === 'Glossary' ? deleteGlossaryItem : deleteBibliographyItem,
    },
  };
}

function getKeyFieldName(headers) {
  return Object.keys(headers).find((key) => headers[key].isKeyField);
}

export function getResults(collectionEndpoint, setResults, location, resource = 'map') {
  const { search } = location;
  const params = queryStringToParams(search);
  const path = queryStringToPath(search);
  getCollectionInfo(
    collectionEndpoint,
    resource,
    path,
    params,
  ).then((data) => {
    const [rowsKey] = Object.keys(data);
    setResults(data[rowsKey]);
  });
}

function Results({ pageProtocol, pageName }) {
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState();
  const [results, setResults] = useState();
  const [permissions, setPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });
  const { receivedURLs } = useContext(UserContext);
  const { page, setPage, isValidUser } = useContext(SecurityContext);
  const location = useLocation();

  const setPermission = (action, enabled) => {
    permissions[action] = enabled;
    setPermissions({ ...permissions });
  };
  useEffect(
    () => {
      if (!receivedURLs) return;
      getHeaders(pageProtocol, setHeaders);
    },
    [receivedURLs, pageName],
  );
  useEffect(
    () => {
      setHeaders(undefined);
      setPermission('create', false);
      setPermission('update', false);
      setPermission('delete', false);
      setResults(undefined);
      setLoading(true);
    },
    [pageName],
  );
  useEffect(
    () => {
      if (!receivedURLs) return;
      if (page !== pageName) {
        setPage(pageName);
        return;
      }
      isValidUser('create', pageName)
        .then((enabled) => setPermission('add', enabled))
        .catch(() => setPermission('add', false));
      isValidUser('update', pageName)
        .then((enabled) => setPermission('edit', enabled))
        .catch(() => setPermission('edit', false));
      isValidUser('delete', pageName)
        .then((enabled) => setPermission('delete', enabled))
        .catch(() => setPermission('delete', false));
    },
    [receivedURLs, page, pageName],
  );
  useEffect(
    () => {
      if (!receivedURLs) return;
      getResults(pageProtocol, setResults, location);
    },
    [receivedURLs, location.search, pageName],
  );
  const loadingProgress = useMemo(
    () => [
      receivedURLs,
      headers !== undefined,
      results !== undefined,
      permissions.add !== undefined,
      permissions.edit !== undefined,
      permissions.delete !== undefined,
    ],
    [
      receivedURLs,
      headers,
      results,
      permissions.add,
      permissions.edit,
      permissions.delete,
    ],
  );
  useEffect(
    () => {
      for (let index = 0; index < loadingProgress.length; index += 1) {
        if (!loadingProgress[index]) return;
      }
      setLoading(false);
    },
    [loadingProgress],
  );

  if (loading) {
    return (
      <ContentBox classes={['outlined', 'search-results']}>
        <ProgressIndicator
          steps={LOADING_STEPS}
          progress={loadingProgress}
        />
      </ContentBox>
    );
  }
  const columns = headersToColumns(headers);
  const rows = resultsToArray(results, headers);
  return (
    <ContentBox classes={['outlined', 'search-results']}>
      <h2>SEARCH RESULTS</h2>
      <Table
        columns={columns}
        rows={rows}
        options={getTableOptions(pageName, permissions)}
        keyFieldName={getKeyFieldName(headers)}
      />
    </ContentBox>
  );
}
Results.propTypes = {
  pageName: propTypes.oneOf(VALID_PAGE_NAMES).isRequired,
  pageProtocol: propTypes.oneOf(VALID_PAGE_PROTOCOLS).isRequired,
};

function extractEndpoint(miscDataMenu, collectionName) {
  const collectionEndpoint = miscDataMenu.length > 0
    ? miscDataMenu
      .find(({ key }) => key === collectionName)
      .url
    : undefined;
  return collectionEndpoint;
}

function AmendSearchPrompt() {
  const instructions = 'Enter valid parameters and then press "Search" to view results.';
  return (
    <ContentBox>
      <InlineMessage type="hint" text={instructions} />
    </ContentBox>
  );
}

export default function DataSearch() {
  const { pageKey } = useParams();
  const pageName = String(pageKey).replaceAll('_', ' ');

  const { miscDataMenu } = useContext(UserContext);

  const [searchValid, setSearchValid] = useState(false);

  const pageProtocol = useMemo(
    () => {
      if (!miscDataMenu.length > 0) return undefined;
      return extractEndpoint(miscDataMenu, pageName);
    },
    [miscDataMenu, pageKey],
  );
  const navigate = useNavigate();
  setTitle(pageName);

  const onSubmit = (options) => {
    const queryString = questionsToQueryString(options);
    navigate(`./${queryString}`);
  };

  if (!miscDataMenu.length) {
    return (
      <ProgressIndicator
        steps={LOADING_STEPS}
        progress={LOADING_STEPS.map(() => false)}
      />
    );
  }
  return (
    <BackgroundImage image={BACKGROUND_IMAGE} color="#293d87" className="collection">
      <Options
        pageProtocol={pageProtocol}
        onSubmit={onSubmit}
        pageName={pageName}
        setSearchValid={setSearchValid}
      />
      <div style={{ height: '5rem' }} />
      {searchValid ? (
        <Results
          searchValid={searchValid}
          pageProtocol={pageProtocol}
          pageName={pageName}
        />
      ) : <AmendSearchPrompt />}
    </BackgroundImage>
  );
}
