import React from 'react';
import propTypes from 'prop-types';

function ButtonGroup({
  children,
  fullWidth = false,
  gap = 20,
  center = false,
}) {
  const style = {
    display: 'flex',
    width: fullWidth ? '100%' : 'fit-content',
    justifyContent: fullWidth ? 'space-between' : 'auto',
    gap: typeof gap === 'number' ? `${gap}px` : '20px',
    margin: center ? 'auto' : undefined,
    flexWrap: 'wrap',
  };
  return (
    <div style={style} className="button-group">
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]).isRequired,
  fullWidth: propTypes.bool,
  gap: propTypes.number,
  center: propTypes.bool,
};

export default ButtonGroup;
