function getNearestDateIndex(date, dates, roundLow = true) {
  if (dates.indexOf(date) !== -1) return dates.indexOf(date);
  const fallback = roundLow ? 0 : dates.length - 1;
  const safeReturn = (index) => {
    if (index === -1) return fallback;
    return index;
  };

  const intervalLowBound = String(dates[0]);
  const intervalHighBound = dates.length > 1 ? String(dates[1]) : intervalLowBound;
  const [yearLow, monthLow, dayLow] = intervalLowBound.split('-');
  const [yearHigh, monthHigh, dayHigh] = intervalHighBound.split('-');
  const yearDifference = Number(yearHigh) - Number(yearLow);
  const monthDifference = Number(monthHigh) - Number(monthLow);
  const dayDifference = Number(dayHigh) - Number(dayLow);
  /* Just an approximation */
  let interval = (365 * yearDifference);
  if (monthDifference) interval += (30 * monthDifference);
  if (dayDifference) interval += dayDifference;

  const [year, month, day] = `${date}`.split('-').map((str) => Number(str));
  if (interval === 365) return safeReturn(dates.indexOf(`${year}`));
  if (interval >= 90) {
    const quarter = `${roundLow
      ? Math.floor(month / 3) * 3 + 1
      : Math.ceil(month / 3) * 3 + 1}`.padStart(2, '0');
    const estimatedDate = `${year}-${quarter}`;
    const index = dates.indexOf(estimatedDate);
    return safeReturn(index);
  }
  if (interval >= 30) {
    const roundedMonth = `${(month < 12 && !roundLow)
      ? month + 1
      : month}`.padStart(2, '0');
    const estimatedDate = `${year}-${roundedMonth}`;
    const index = dates.indexOf(estimatedDate);
    return safeReturn(index);
  }
  for (let i = 1; i <= 14; i += 1) {
    const strMonth = `${month}`.padStart(2, '0');
    const strDay = roundLow
      ? `${day - i}`.padStart(2, '0')
      : `${day + i}`.padStart(2, '0');
    const estimatedDate = `${year}-${strMonth}-${strDay}`;
    const index = dates.indexOf(estimatedDate);
    if (index !== -1) return index;
  }
  return fallback;
}

export default getNearestDateIndex;
