export default function deepCopy(obj) {
  if (typeof obj !== 'object') return obj;
  if (!obj) { return JSON.parse(JSON.stringify(obj)); }
  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item));
  }
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[key] = deepCopy(obj[key]);
  });
  return newObj;
}
