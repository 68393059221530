import STATUSES from './statuses';
import RESPONSES from './responses';

const MANUSCRIPTS = [
  {
    title: '2023 Securitization Market Starts with Muted Supply and Still-Strong Investor Demand',
    authors: ['Elen Callahan', 'Jessica Steele'],
    timeUpdated: new Date('2024-02-03'),
    reviewers: [],
    status: STATUSES.SUBMITTED,
  },
  {
    title: 'CMBS Market Evolution and Emerging Risks',
    authors: ['Xudong An', 'et. al.'],
    timeUpdated: new Date('2024-07-06'),
    reviewers: [],
    status: STATUSES.SUBMITTED,
  },
  {
    title: 'Alt-A: The Forgotten Segment of the Mortgage Market',
    authors: ['Rajdeep Sengupta'],
    timeUpdated: new Date('2024-06-30'),
    reviewers: [
      { name: 'Elen Callahan', response: RESPONSES.PENDING },
      { name: 'Gene Callahan', response: RESPONSES.REJECTED },
      { name: 'Boaz Kaufman', response: RESPONSES.WITH_REVISIONS },
    ],
    status: STATUSES.UNDER_REFEREE,
  },
  {
    title: 'Authority to Issue and Sell Securities',
    authors: ['Michael J. McKenna'],
    timeUpdated: new Date('2024-05-27'),
    reviewers: [
      { name: 'Kristian Nikolov', response: RESPONSES.ACCEPTED },
      { name: 'Gene Callahan', response: RESPONSES.WITH_REVISIONS },
      { name: 'Sam Ebersole', response: RESPONSES.ACCEPTED },
    ],
    status: STATUSES.UNDER_REFEREE,
  },
  {
    title: 'ESG Investing through Indices',
    authors: ['Reid Steadman'],
    timeUpdated: new Date('2024-06-13'),
    reviewers: [
      { name: 'Kristian Nikolov', response: RESPONSES.ACCEPTED },
      { name: 'Gene Callahan', response: RESPONSES.WITH_REVISIONS },
      { name: 'Elen Callahan', response: RESPONSES.WITH_REVISIONS },
    ],
    status: STATUSES.UNDER_REVIEW,
  },
  {
    title: 'Consumer Asset-Backed Securities Primer',
    authors: ['Jean-Baptiste Carelus'],
    timeUpdated: new Date('2024-07-02'),
    status: STATUSES.ACCEPTED,
    reviewers: [
      { name: 'Kristian Nikolov', response: RESPONSES.ACCEPTED },
      { name: 'Sam Ebersole', response: RESPONSES.ACCEPTED },
      { name: 'Boaz Kaufman', response: RESPONSES.ACCEPTED },
    ],
  },
];

export default MANUSCRIPTS;
