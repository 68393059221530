import React, { useState } from 'react';
import propTypes from 'prop-types';

import { Editor, rootCtx, defaultValueCtx } from '@milkdown/core';
import { nord } from '@milkdown/theme-nord';
import { Milkdown, MilkdownProvider, useEditor } from '@milkdown/react';
import { commonmark } from '@milkdown/preset-commonmark';
import { listener, listenerCtx } from '@milkdown/plugin-listener';
import { menu, menuConfigCtx } from '@milkdown-lab/plugin-menu';

import '@milkdown-lab/plugin-menu/style.css';

import Dropdown from '../Dropdown';

import './MarkdownEditor.css';

const menuItems = [
  [
    {
      type: 'select',
      text: 'Heading',
      options: [
        { id: 3, content: 'Large Heading' },
        { id: 4, content: 'Medium Heading' },
        { id: 5, content: 'Small Heading' },
        { id: 0, content: 'Plain Text' },
      ],
      onSelect: (id) => (id ? ['WrapInHeading', id] : 'TurnIntoText'),
    },
  ],
  [
    {
      type: 'button',
      content: 'B',
      key: 'ToggleStrong',
    },
    {
      type: 'button',
      content: 'I',
      key: 'ToggleEmphasis',
    },
  ],
  [
    {
      type: 'button',
      content: 'Unordered List',
      key: 'WrapInBulletList',
    },
    {
      type: 'button',
      content: 'Ordered List',
      key: 'WrapInOrderedList',
    },
  ],
];

function MilkdownEditor({ value, onChange }) {
  useEditor((root) => Editor.make()
    .config(nord)
    .config((ctx) => { ctx.set(rootCtx, root); })
    .config((ctx) => { ctx.set(defaultValueCtx, value); })
    .config((ctx) => {
      ctx.get(listenerCtx).markdownUpdated((uselessArgument, markdown) => { onChange(markdown); });
    })
    .config((ctx) => {
      ctx.set(menuConfigCtx.key, {
        attributes: { class: 'milkdown-menu', 'data-menu': 'true' },
        items: menuItems,
      });
    })
    .use(listener)
    .use(menu)
    .use(commonmark));

  return <Milkdown />;
}
MilkdownEditor.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

function RawEditor({ value, onChange }) {
  return (
    <textarea
      value={value}
      onChange={({ target }) => { onChange(target.value); }}
      className="raw-markdown-editor"
    />
  );
}
RawEditor.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

const MODES = {
  milkdown: 'Editor',
  raw: 'Raw Markdown',
};
export default function MarkdownEditor({ value, onChange }) {
  const [mode, setMode] = useState('milkdown');
  return (
    <div>
      <Dropdown
        label="Input mode:"
        value={mode}
        options={Object.entries(MODES).map(([id, name]) => ({ id, name }))}
        onChange={({ target }) => { setMode(target.value); }}
      />
      {mode === 'raw' && <RawEditor value={value} onChange={onChange} />}
      {mode === 'milkdown' && (
        <MilkdownProvider>
          <MilkdownEditor value={value} onChange={onChange} />
        </MilkdownProvider>
      )}
    </div>
  );
}
MarkdownEditor.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};
