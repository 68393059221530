import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';

import PageTitle from '../../../core/Display/PageTitle';
import { getJournalMasthead } from '../../../utils/networkUtils';
import UserContext from '../../../UserContext';

import './Masthead.css';

const PEOPLE_PROPS = propTypes.instanceOf(Object);

function Section({ journalRole, people }) {
  if (!people) return null;

  const lastName = (person) => {
    const { name } = person;
    const parts = String(name).split(' ');
    return parts[parts.length - 1];
  };
  const relevantPeople = people[journalRole].sort((personA, personB) => {
    const a = lastName(personA);
    const b = lastName(personB);
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  if (!relevantPeople) return null;
  return (
    <section>
      <h3>{journalRole}</h3>
      <ul>
        {relevantPeople.map(({ name, bio }) => (
          <li>
            <span style={{ marginBottom: '1rem', fontWeight: 800, fontSize: '1.1rem' }}>{name}</span>
            <br />
            {bio}
          </li>
        ))}
      </ul>
    </section>
  );
}
Section.propTypes = {
  journalRole: propTypes.string.isRequired,
  people: PEOPLE_PROPS.isRequired,
};

export default function Masthead() {
  const { receivedURLs } = useContext(UserContext);
  const [people, setPeople] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (!receivedURLs) return;
    getJournalMasthead()
      .then(setPeople)
      .catch(() => { setErrorMessage(true); });
  }, [receivedURLs]);

  return (
    <div className="masthead">
      <PageTitle text="Masthead" />
      {errorMessage && 'Could not retrieve list of people.'}
      {!Object.keys(people).length && 'Loading...'}
      {Object.keys(people).map((role) => <Section journalRole={role} key={role} people={people} />)}
    </div>
  );
}
