import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../Display/Text';
import Tooltip from '../../Display/Tooltip';
import '../Input.css';

function Label({
  style = {
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
  text,
  htmlFor,
  helperText = '',
}) {
  return (
    <Text classes={['input-label']}>
      <label htmlFor={htmlFor} style={style}>
        {text}
      </label>
      {helperText && <Tooltip text={helperText} />}
    </Text>
  );
}

Label.propTypes = {
  style: PropTypes.instanceOf(Object),
  text: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

export default Label;
