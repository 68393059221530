import { REPORT_PATH } from '../../../../constants';

export default function generateURL(reportID) {
  if (!reportID) return '';
  if (typeof reportID !== 'string') return '';
  const { host, protocol } = window.location;
  const url = `${host}/${REPORT_PATH}/${reportID}`
    .replace(/\/{2}/g, '/')
    .replace(/ /g, '%20');
  return `${protocol}//${url}`;
}
