import axios from 'axios';

import { getURL } from '../urlMapUtils';

export const PLACEHOLDER = '{term}';
export const SCHEME = {
  create: { endpoint: 'create', method: 'PUT' },
  createForm: { endpoint: 'create/form', method: 'GET' },
  update: { endpoint: `edit/${PLACEHOLDER}`, method: 'PUT' },
  delete: { endpoint: `delete/${PLACEHOLDER}`, method: 'DELETE' },
  read: { endpoint: 'map', method: 'GET' },
  readOne: { endpoint: `retrieve/${PLACEHOLDER}`, method: 'GET' },
  readForm: { endpoint: 'form', method: 'GET' },
  readFields: { endpoint: 'fields', method: 'GET' },
};
export const VALID_ACTIONS = Object.keys(SCHEME);

export function rejectActionMessage(
  action,
  options = {},
) {
  const { caller = 'CRUD', variableName = 'action' } = options;
  return [
    `${caller}() called with invalid ${variableName} '${action}.'`,
    `Valid ${variableName}s are ${VALID_ACTIONS.join(', ')}.`,
  ].join(' ');
}

export function validateAction(action, options = {}) {
  const { caller = 'CRUD', variableName = 'action' } = options; // eslint-disable-line no-unused-vars
  if (VALID_ACTIONS.includes(action)) return;
  throw new Error(rejectActionMessage(action, options));
}
export function validateEndpointKeyAction(action, options = {}) {
  validateAction(action, { ...options, variableName: 'endpointKeyAction' });
}

export function constructBaseURL(endpointKey, endpointKeyAction) {
  if (!endpointKey) throw new Error('constructBaseURL called without endpointKey');
  validateEndpointKeyAction(endpointKeyAction, { caller: 'constructBaseURL' });

  const endpointValue = getURL(endpointKey, { errorWhenMissing: true });
  const endpointKeyActionValue = SCHEME[endpointKeyAction].endpoint;
  const charactersToOmit = endpointKeyActionValue.length + 1;
  const endpointWithoutAction = endpointValue.substring(0, endpointValue.length - charactersToOmit);
  return endpointWithoutAction;
}

export function getActorFunction(action) {
  const { method } = SCHEME[action];
  switch (method) {
    case 'GET': return (url, payload) => axios.get(url, { params: payload });
    case 'PUT': return (url, payload) => axios.put(url, payload);
    case 'POST': return (url, payload) => axios.post(url, payload);
    case 'DElETE': return (url, payload) => axios.delete(url, payload);
    default: throw new Error(`Invalid method '${method}'.`);
  }
}

export function constructURL(action, endpointKey, endpointKeyAction, term) {
  const baseURL = constructBaseURL(endpointKey, endpointKeyAction);
  const { endpoint } = SCHEME[action];
  const urlWithoutTerm = `${baseURL}/${endpoint}`;
  const url = term ? urlWithoutTerm.replace(PLACEHOLDER, term) : urlWithoutTerm;
  return url;
}

export function errorMessageNoData() {
  return 'Cannot extract data from request response: no data returned.';
}
export function extractAllData(response) {
  if (response.data === undefined) throw new Error(errorMessageNoData());
  return response.data;
}
export function errorMessageNOutOfRange(data, n) {
  return [
    `Cannot return nth value, where n = ${n}.`,
    `There are only ${Object.keys(data).length} keys in the data.`,
  ].join(' ');
}
export function extractNthValue(response, n) {
  const data = extractAllData(response);
  const keys = Object.keys(data);
  if (n > keys.length) throw new Error(errorMessageNOutOfRange(data, n));
  const index = n - 1;
  const key = keys[index];
  return data[key];
}
export function extractFirstValue(response) { return extractNthValue(response, 1); }
export function errorMessageInvalidKey(data, key) {
  return [
    `Cannot return value for key '${key}', as this key does not exist.`,
    `The keys for this object are ${Object.keys(data).join(', ')}.`,
  ].join(' ');
}
export function extractValue(response, key) {
  const data = extractAllData(response);
  const keys = Object.keys(data);
  if (!keys.includes(key)) throw new Error(errorMessageInvalidKey(data, key));
  return data[key];
}

export default function CRUD({
  action = 'read',
  payload,
  term,
  endpointKey,
  endpointKeyAction = 'read',
}) {
  validateAction(action);
  validateEndpointKeyAction(endpointKeyAction);

  const url = constructURL(action, endpointKey, endpointKeyAction, term);
  const actor = getActorFunction(action);

  return {
    allData: () => {},
    firstValue: () => actor(url, payload).then(extractFirstValue),
    nthValue: (index) => {}, // eslint-disable-line no-unused-vars
    value: (key) => {}, // eslint-disable-line no-unused-vars
  };
}
