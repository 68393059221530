import { React, useContext } from 'react';

import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContentBox from '../../core/Display/ContentBox';
import {
  getPath,
} from '../../constants';
import SignInOutButton from '../Navbar/SignInOutButton';

import './Home.css';

// import Text from '../Common/Text';
import Flex from '../Common/FlexV2';

import { setTitle, useScrollbarWidth } from '../../utils/browserUtils';
import Button from '../../core/Input/Button';
import UserContext from '../../UserContext';

/* eslint jsx-a11y/media-has-caption: warn */
function Home() {
  const { userID } = useContext(UserContext);
  setTitle('COSMOS + TAXIS - Home');

  return (
    <div
      className="cat-home-wrapper"
      style={{
        width: `calc(100dvw - ${useScrollbarWidth() / 2}px)`,
      }}
    >
      <Flex direction="column">
        <ContentBox classes={['cat-home-text']}>
          <div className="cat-home-links" direction="column">
            {!userID && <SignInOutButton />}
            <Link to={getPath('JOURNAL.DASHBOARD')}>
              <Button variant="hollow" startIcon={<DashboardIcon />}>
                Dashboard
              </Button>
            </Link>
          </div>
        </ContentBox>
      </Flex>
    </div>
  );
}

export default Home;
