import React, {
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { deepCopy } from '../../core/Utils/objectUtils';

import { parseDate } from '../../utils/dateUtils';
import NONE from '../../constants';

import FormContext, { FormContextProvider } from '../Common/FormContext';

import {
  DEF_REPORT_COMPONENTS,
} from './constants';

/* eslint-disable camelcase */

const ReportContext = createContext();

function ReportContextProviderInner({
  children = null,
}) {
  const { questions, setQuestions, answerQuestion } = useContext(FormContext);
  const [title, setTitle] = useState('');
  const [dataCategoryOptions, setDataCategoryOptionsState] = useState({ [NONE]: 'None' });
  const [dataSourceFilters, setDataSourceFilters] = useState({});
  const [dataSourceOptions, setDataSourceOptionsState] = useState({});
  const [dataSources, setDataSources] = useState([]);
  const [sourcesValidated, setSourcesValidated] = useState(true);
  const [dataViews, setDataViews] = useState(deepCopy(DEF_REPORT_COMPONENTS));
  const [userColumns, setUserColumns] = useState([]);
  const [analysis, setAnalysis] = useState('');

  const setDataCategoryOptions = (newOptions) => {
    const transformedOptions = {};
    transformedOptions[NONE] = NONE;
    const categoryKeys = Object.keys(newOptions);
    categoryKeys.forEach((key) => {
      if (key === 'HMDA'
        && process.env.REACT_APP_STYLE !== 'sfa-variant') return;
      transformedOptions[key] = newOptions[key];
    });
    setDataCategoryOptionsState(transformedOptions);
  };

  const setDataSourceOptions = (newOptions) => {
    const newOptionsCopy = deepCopy(newOptions);
    setDataSourceOptionsState(newOptionsCopy);
  };

  const getCategoryBySourceID = (sourceID) => {
    if (!dataSourceOptions[sourceID]) return undefined;
    return dataSourceOptions[sourceID].cat_code;
  };

  const filterSourceOptionsBySearchText = (searchText) => {
    if (!searchText) return [{ id: NONE, name: 'None' }];
    const searchTextUpper = searchText.toUpperCase();
    const options = [];
    Object.keys(dataSourceOptions).forEach((id) => {
      if (options.length > 500) return;
      if (id === NONE) return;
      const { name } = dataSourceOptions[id];
      if (!name.toUpperCase().includes(searchTextUpper)) return;
      options.push({ id, name });
    });
    options.push({ id: NONE, name: 'None' });
    return options;
  };

  const filterSourceOptionsByCategory = (categoryID) => {
    const dataSourceIDs = Object.keys(dataSourceOptions);
    const options = [{ id: NONE, name: 'None' }];
    if (categoryID === NONE) return options;
    dataSourceIDs.forEach((id) => {
      const { cat_code, cat_code2, name } = dataSourceOptions[id];
      if (cat_code !== categoryID && cat_code2 !== categoryID) return;
      options.push({ id, name });
    });
    const sortByName = (sourceA, sourceB) => {
      const nameA = sourceA.name.trim();
      const nameB = sourceB.name.trim();
      if (nameA === 'None') return -1;
      if (nameB === 'None') return 1;
      return nameA.localeCompare(nameB);
    };
    return options.sort(sortByName);
  };

  const getOptionsForSource = (dataSource) => {
    const { sourceID } = dataSource;
    if (sourceID === NONE
      && dataSource.categoryID === NONE
      && dataSource.searchText === '') {
      return [{ id: NONE, name: 'None' }];
    }
    const fallbackOptions = [
      { id: NONE, name: 'None' },
      { id: sourceID, name: sourceID },
    ];
    if (Object.keys(dataSourceOptions).length <= 1) return fallbackOptions;
    if (Object.keys(dataCategoryOptions).length <= 1) return fallbackOptions;

    const {
      pickByCategory,
      categoryID,
      searchText,
    } = dataSource;
    if (pickByCategory) return filterSourceOptionsByCategory(categoryID);
    return filterSourceOptionsBySearchText(searchText);
  };

  const validateSources = () => {
    dataCategoryOptions[NONE] = 'None';
    if (Object.keys(dataSourceOptions).length <= 1) return;
    if (Object.keys(dataCategoryOptions).length <= 1) return;

    const sourcesCopy = dataSources.filter(({ sourceID }) => {
      const validOptions = Object.keys(dataSourceOptions).includes(sourceID);
      const permitNone = sourceID !== NONE || dataSources.length === 1;
      return validOptions && permitNone;
    });

    sourcesCopy.forEach((source) => {
      const { sourceID, categoryID } = source;
      const updatedSource = source;
      if (!Object.keys(dataCategoryOptions).includes(categoryID) || categoryID === 'None') {
        updatedSource.categoryID = dataSourceOptions[sourceID].cat_code;
      }
    });

    setDataSources(sourcesCopy);
    setSourcesValidated(true);
  };

  const setCategoryID = (sourceIndex, categoryID) => {
    dataSources[sourceIndex].categoryID = categoryID;
    return dataSources[sourceIndex].categoryID;
  };

  const setSearchText = (sourceIndex, searchText) => {
    dataSources[sourceIndex].searchText = searchText;
    return dataSources[sourceIndex].searchText;
  };

  const setPickByCategory = (sourceIndex, pickByCategory) => {
    dataSources[sourceIndex].pickByCategory = pickByCategory;
    return dataSources[sourceIndex].pickByCategory;
  };

  const setSourceID = (sourceIndex, sourceID) => {
    dataSources[sourceIndex].sourceID = sourceID;
    return dataSources[sourceIndex].sourceID;
  };

  const addSource = (sourceID) => {
    const newDataSources = dataSources.concat({ sourceID });
    setDataSources(newDataSources);
  };

  const addSources = (sourceIDs) => {
    const newDataSources = [...dataSources];
    sourceIDs.forEach((sourceID) => {
      if (newDataSources.includes(sourceID)) return;
      if (!dataSourceOptions[sourceID]) return;
      newDataSources.push({ sourceID });
    });
    setDataSources(newDataSources);
  };

  const removeSource = (source) => {
    const newDataSources = dataSources
      .filter(({ sourceID: id }) => source !== id);
    setDataSources(newDataSources);
  };

  const getChosenSourceIDs = () => dataSources
    .map(({ sourceID }) => sourceID)
    .filter((sourceID) => (sourceID && sourceID !== NONE));

  const getDataViewByID = (dataViewID) => {
    const [dataView] = dataViews.filter(({ id }) => id === dataViewID);
    return dataView;
  };

  const setDataViewText = (id, text) => {
    const dataView = getDataViewByID(id);
    dataView.text = text;
    setDataViews(deepCopy(dataViews));
  };

  const setDataViewEnabled = (id, enabled) => {
    const dataView = getDataViewByID(id);
    dataView.enabled = enabled;
    setDataViews(deepCopy(dataViews));
  };

  const getSourceOptionByID = (sourceID) => {
    const option = deepCopy(dataSourceOptions[sourceID]);
    return option;
  };

  const sourcesAreIncompatible = (sourcesToCompare = dataSources) => {
    if (sourcesToCompare.length === 2) {
      const [sourceAID, sourceBID] = sourcesToCompare;
      if (sourceAID === sourceBID) return false;

      const sourceA = dataSourceOptions[sourceAID.sourceID];
      const sourceB = dataSourceOptions[sourceBID.sourceID];

      const aStart = parseDate(sourceA.start_date);
      const aEnd = parseDate(sourceA.end_date);
      const bStart = parseDate(sourceA.start_date);
      const bEnd = parseDate(sourceB.end_date);

      if (aStart && bEnd && aStart > bEnd) return true;
      if (bStart && aEnd && bStart > aEnd) return true;
      return false;
    }

    for (let i = 0; i < sourcesToCompare.length; i += 1) {
      const sourceA = sourcesToCompare[i];
      for (let j = 0; j < sourcesToCompare.length; j += 1) {
        const sourceB = sourcesToCompare[j];
        if (sourcesAreIncompatible([sourceA, sourceB])) return true;
      }
    }
    return false;
  };

  const valueFormat = {
    getCategoryBySourceID,
    title,
    setTitle,
    dataCategoryOptions,
    setDataCategoryOptions,
    dataSourceOptions,
    setDataSourceOptions,
    dataSources,
    setDataSources,
    getChosenSourceIDs,
    setCategoryID,
    setSearchText,
    setPickByCategory,
    setSourceID,
    addSource,
    addSources,
    removeSource,
    dataViews,
    setDataViews,
    setDataViewText,
    setDataViewEnabled,
    userColumns,
    setUserColumns,
    filterSourceOptionsBySearchText,
    filterSourceOptionsByCategory,
    getOptionsForSource,
    sourcesValidated,
    analysis,
    setAnalysis,
    getSourceOptionByID,
    questions,
    setQuestions,
    answerQuestion,
    sourcesAreIncompatible,
    dataSourceFilters,
    setDataSourceFilters,
  };
  const [value, setValue] = useState({ ...valueFormat });

  useEffect(() => {
    if (sourcesValidated) return;
    validateSources();
  }, [dataSourceOptions, dataCategoryOptions]);

  useEffect(() => {
    setValue({ ...valueFormat });
  }, [
    title,
    dataCategoryOptions,
    dataSourceOptions,
    dataSources,
    dataViews,
    userColumns,
    analysis,
    dataSourceFilters,
  ]);

  return (
    <ReportContext.Provider value={value}>
      {children}
    </ReportContext.Provider>
  );
}

ReportContextProviderInner.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
};

function ReportContextProvider({
  children = null,
}) {
  return (
    <FormContextProvider>
      <ReportContextProviderInner>
        {children}
      </ReportContextProviderInner>
    </FormContextProvider>
  );
}

ReportContextProvider.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
};

export { ReportContextProvider };
export default ReportContext;
