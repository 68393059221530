import React, { useState, useMemo } from 'react';
import propTypes from 'prop-types';
import SettingsIcon from '@mui/icons-material/Settings';
import { joinRows } from '../../../core/Display/Table';

import Button from '../../../core/Input/Button';
import MultipleSelect from '../../../core/Input/MultipleSelect/MultipleSelect';
import Dropdown from '../../../core/Input/Dropdown';
import InlineMessage from '../../../core/Display/InlineMessage';
import columnProps from '../../../core/Display/Table/columnProps';
import Text from '../../../core/Display/Text';

import BarGraph from './BarGraph';
import LineGraph from './LineGraph';
import DateRangeSelector, { DateRange } from './DateSelector';
import getNearestDateIndex from './getNearestDateIndex';
import './Graph.css';

const GRAPH_OPTIONS = 'graph options';
const messages = {
  tooManyColumns: `There are too many data sets selected for graphing. Please use ${GRAPH_OPTIONS} to narrow down your selection.`,
  noDataSoDisabled: 'This option is disabled, as if you selected it no data would match your filters.',
};

function Graph({
  type,
  maxColumns,
  rows,
  columns,
  xAxisID,
  isMultiAxes = true,
  stackX,
  filterZeroTooltips,
  variant,
}) {
  if (columns.length > maxColumns) {
    return (
      <InlineMessage
        text={messages.tooManyColumns}
        type="hint"
      />
    );
  }

  switch (type) {
    case 'bar':
      return (
        <BarGraph
          key={rows}
          rows={rows}
          columns={columns}
          xAxisID={xAxisID}
          stackX={stackX}
          filterZeroTooltips={filterZeroTooltips}
          variant={variant}
        />
      );
    case 'line':
      return (
        <LineGraph
          key={rows}
          rows={rows}
          columns={columns}
          xAxisID={xAxisID}
          isMultiAxes={isMultiAxes}
          variant={variant}
        />
      );
    default: return null;
  }
}

const GRAPH_PROP_TYPES = {
  rows: propTypes.arrayOf(propTypes.instanceOf(Object)),
  columns: propTypes.arrayOf(columnProps),
  xAxisID: propTypes.string,
  stackX: propTypes.bool,
  filterZeroTooltips: propTypes.bool,
  variant: propTypes.string,
  maxColumns: propTypes.number,
};
const requireAll = (props) => {
  const newProps = {};
  Object.keys(props).forEach((key) => { newProps[key] = props[key].isRequired; });
  return newProps;
};

Graph.propTypes = {
  ...requireAll(GRAPH_PROP_TYPES),
  type: propTypes.oneOf(['bar', 'line']).isRequired,
  isMultiAxes: propTypes.bool,
};

function countTags(columns) {
  const allTags = [];
  const allOptions = {};
  columns.forEach((column) => {
    const { tags } = column;
    if (!tags) return;
    const keys = Object.keys(tags);
    keys.forEach((key) => {
      if (!allTags.includes(key)) {
        allTags.push(key);
        allOptions[key] = [];
      }
      const option = tags[key];
      if (!allOptions[key].includes(option)) {
        allOptions[key].push(option);
      }
    });
  });
  return [allTags, allOptions];
}

function filterColumns(columns, filters, xAxisID) {
  const keys = Object.keys(filters);
  return columns.filter(({ id, tags }) => {
    if (id === xAxisID) return id;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const filtered = filters[key] && filters[key] !== 'all';
      const matches = filtered && filters[key].includes(tags[key]);
      if (filtered && !matches) return false;
    }
    return true;
  });
}

function wouldFilterCauseNoData(
  columns,
  filteredColumns,
  tagCategory,
  tagName,
  filters,
  xAxisID,
) {
  const newFilters = { ...filters };

  const categoryFilter = newFilters[tagCategory];
  if (categoryFilter && categoryFilter.includes(tagName)) return false;

  newFilters[tagCategory] = categoryFilter
    ? categoryFilter.concat(`,${tagName}`)
    : tagName;
  const newColumns = filterColumns(columns, newFilters, xAxisID);

  if (newColumns.length === filteredColumns.length) return true;
  if (newColumns.length <= 1) return true;
  return false;
}

function FilterBar({
  columns,
  filteredColumns,
  filters,
  setFilters,
  xAxisID,
}) {
  const [tags, options] = useMemo(
    () => countTags(columns),
    [columns],
  );

  const applyFilter = (tag, option) => {
    const newFilters = { ...filters };
    newFilters[tag] = option;
    setFilters(newFilters);
  };

  return (
    <>
      {tags.map((tag) => (
        <MultipleSelect
          defaultValue="All"
          label={tag}
          id={`filter-${tag}`}
          key={`filter-${tag}`}
          value={filters[tag] || options[tag].map((key) => key)}
          optional
          options={options[tag].map((key) => {
            const id = key;
            const name = key;
            const noData = wouldFilterCauseNoData(
              columns,
              filteredColumns,
              tag,
              id,
              filters,
              xAxisID,
            );

            return {
              id,
              name,
              disabled: noData,
              title: noData ? messages.noDataSoDisabled : '',
            };
          })}
          onChange={(value) => {
            applyFilter(tag, value);
          }}
        />
      ))}
    </>
  );
}
FilterBar.propTypes = {
  xAxisID: propTypes.string.isRequired,
  columns: propTypes.arrayOf(columnProps).isRequired,
  filteredColumns: propTypes.arrayOf(columnProps).isRequired,
  filters: propTypes.instanceOf(Object).isRequired,
  setFilters: propTypes.func.isRequired,
};

function filterRows(rows, columns, filters, xAxisID) {
  const columnsToInclude = filterColumns(columns, filters, xAxisID)
    .map(({ id }) => id);
  return rows.map((row) => {
    const newRow = {};
    columnsToInclude.forEach((id) => {
      newRow[id] = row[id];
    });
    return newRow;
  });
}

function filterDatalessColumns(rows, columns, xAxisID) {
  const columnsWithData = columns.filter(({ id, expression }) => {
    if (id === xAxisID) return true;
    if (expression) return true;
    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i];
      const cell = row[id];
      const isNumber = typeof cell === 'number';
      const isNaN = Number.isNaN(cell);
      if (isNumber && !isNaN) return true;
    }
    return false;
  });
  const rowsWithData = rows.map((oldRow) => {
    const keys = columnsWithData.map(({ id }) => id);
    const newRow = {};
    keys.forEach((key) => { newRow[key] = oldRow[key]; });
    return newRow;
  });
  return [rowsWithData, columnsWithData];
}

function cleanData(rows, columns, xAxisID) {
  const [rowsWithData, columnsWithData] = filterDatalessColumns(
    rows,
    columns,
    xAxisID,
  );
  const computedRows = joinRows(rowsWithData, columnsWithData);
  return [computedRows, columnsWithData];
}

function GraphTitle({
  title,
  subtitle = '',
  toggleSettingsOpen,
  disableAllOptions,
}) {
  return (
    <div className="graph-header">
      <div className="graph-header-titles">
        <div className="graph-title">
          <Text variant="h6">
            <abbr title={title} className="graph-title-text">{title}</abbr>
          </Text>
        </div>
        {
          subtitle
          && (
            <div className="graph-subtitle">
              <Text variant="h7">
                {subtitle}
              </Text>
            </div>
          )
        }
      </div>
      {disableAllOptions
        ? <div />
        : (
          <Button
            variant="icon"
            onClick={toggleSettingsOpen}
            disabled={disableAllOptions}
          >
            <SettingsIcon />
          </Button>
        )}
    </div>
  );
}
GraphTitle.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  toggleSettingsOpen: propTypes.func.isRequired,
  disableAllOptions: propTypes.bool.isRequired,
};

function GraphWrapper({
  rows = [],
  columns = [],
  xAxisID = 'year',
  initialFilters = {},
  title = '',
  subtitle = '',
  disableAllOptions = false,
  stackX = false,
  filterZeroTooltips = false,
  variant = 'dmm',
  maxColumns = 20,
}) {
  if (!rows.length || !columns.length) return null;
  const [cleanRows, cleanColumns] = useMemo(
    () => cleanData(rows, columns, xAxisID),
    [rows, columns],
  );

  const [uniqueID] = useState(Date.now());
  const [type, setType] = useState(cleanRows.length > 5 ? 'line' : 'bar');

  const [filters, setFilters] = useState({ ...initialFilters });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [dateRange, setDateRange] = useState(
    new DateRange(
      cleanRows[0][xAxisID],
      cleanRows[cleanRows.length - 1][xAxisID],
    ),
  );
  const [isMultiAxes, setIsMultiAxes] = useState(true);

  const filteredRows = useMemo(
    () => filterRows(cleanRows, cleanColumns, filters, xAxisID),
    [JSON.stringify(filters), cleanRows, cleanColumns],
  );
  const filteredColumns = useMemo(
    () => filterColumns(cleanColumns, filters, xAxisID),
    [cleanColumns, filters],
  );
  const dates = useMemo(() => filteredRows.map((item) => item[xAxisID]), [filteredRows]);
  const startIndex = useMemo(
    () => getNearestDateIndex(dateRange.start, dates),
    [dates, dateRange, dateRange.start],
  );
  const endIndex = useMemo(
    () => getNearestDateIndex(dateRange.end, dates, false),
    [dates, dateRange, dateRange.end],
  );
  const dateLimitedRows = useMemo(
    () => filteredRows.slice(
      startIndex > -1 ? startIndex : 0,
      (endIndex <= dates.length ? endIndex : dates.length) + 1,
    ),
    [startIndex, endIndex, filteredRows],
  );

  const filterPanelClassNames = [
    'filter-bar-wrapper',
    filtersOpen ? 'open' : 'closed',
  ].join(' ');
  return (
    <div>
      <GraphTitle
        title={title}
        subtitle={subtitle}
        toggleSettingsOpen={() => { setFiltersOpen(!filtersOpen); }}
        disableAllOptions={disableAllOptions}
      />
      <div className={filterPanelClassNames}>
        <FilterBar
          filteredRows={filteredRows}
          columns={cleanColumns}
          filteredColumns={filteredColumns}
          filters={filters}
          setFilters={setFilters}
          xAxisID={xAxisID}
        />
        <Dropdown
          id={`graph-type-picker-${uniqueID}`}
          label="Graph type"
          value={type}
          onChange={({ target }) => { setType(target.value); }}
          options={[
            { id: 'bar', name: 'Bar' },
            { id: 'line', name: 'Line' },
          ]}
        />
        <DateRangeSelector
          type={type}
          dateRange={dateRange}
          setDateRange={setDateRange}
          dates={cleanRows.map((row) => row[xAxisID])}
          isMultiAxes={isMultiAxes}
          toggleMultipleAxes={() => {
            /*
            if (isMultiAxes) {
              setSetInversions(setInversions.map(() => false));
            }
            */
            setIsMultiAxes(!isMultiAxes);
          }}
        />
      </div>
      <Graph
        rows={dateLimitedRows}
        columns={filteredColumns}
        type={type}
        xAxisID={xAxisID}
        isMultiAxes={isMultiAxes}
        stackX={stackX}
        filterZeroTooltips={filterZeroTooltips}
        variant={variant}
        maxColumns={maxColumns}
      />
    </div>
  );
}
GraphWrapper.propTypes = { ...GRAPH_PROP_TYPES };

export default GraphWrapper;
