import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../core/Display/Table';

import SectionAnalysis from '../Common/SectionAnalysis';

function CorrelationsView({
  canEdit = false,
  dataSet = {},
  userText = '',
  onUserTextChange = () => {},
}) {
  const tableCells = dataSet.corrs
    .split('\n')
    .map((row) => row.split('\t'));
  const columns = tableCells[0].map((cell) => ({
    id: cell || 'data',
    name: cell || 'Data Set',
    type: cell ? 'float' : 'string',
  }));
  const rows = tableCells.slice(1, tableCells.length - 1)
    .map((row) => {
      const object = {};
      row.forEach((cell, index) => {
        object[columns[index].id] = cell;
      });
      return object;
    });
  return (
    <>
      <SectionAnalysis
        canEdit={canEdit}
        content={userText}
        onChange={onUserTextChange}
      />
      <Table
        keyFieldName="data"
        columns={columns}
        rows={rows}
      />
    </>
  );
}

export default CorrelationsView;

CorrelationsView.propTypes = {
  canEdit: PropTypes.bool,
  dataSet: PropTypes.shape({
    corrs: PropTypes.string.isRequired,
  }),
  userText: PropTypes.string,
  onUserTextChange: PropTypes.func,
};
