import axios from 'axios';

async function fetchMailingList(getURL, userID) {
  let url = getURL();
  if (url[url.length - 1] !== '/') { url = url.concat('/'); }
  url = url.concat(userID);
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then((resp) => { resolve(resp); })
      .catch((err) => { reject(err); });
  });
}

async function addMailingListEntry(getURL, userID, email) {
  return new Promise((resolve, reject) => {
    axios.post(getURL(), {
      user_id: userID,
      recip_email: email,
    })
      .then(() => { resolve(email); })
      .catch((err) => { reject(err); });
  });
}

export {
  addMailingListEntry,
  fetchMailingList,
};
