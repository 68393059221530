import React from 'react';
import propTypes from 'prop-types';

import './BackgroundImage.css';

export default function BackgroundImage({
  children = null,
  image = undefined,
  color = 'var(--bg-color-primary)',
  className = '',
}) {
  const formatURL = (preformat) => {
    if (!preformat) return undefined;
    if (process.env.NODE_ENV === 'development') return `url(${preformat})`;
    const firstLetterIndex = String(preformat)
      .split('')
      .findIndex((character) => !['.', '/'].includes(character));
    const absolutePath = String(preformat).slice(firstLetterIndex);
    return `url(/${absolutePath})`;
  };
  const style = {
    '--background-image': formatURL(image),
    '--background-color': color,
  };
  return <div className={`background-image ${className}`} style={style}>{children}</div>;
}
BackgroundImage.propTypes = {
  children: propTypes.node,
  image: propTypes.string,
  color: propTypes.string,
  className: propTypes.string,
};
