import React from 'react';

import { TRENDS_REPORTS } from '../../constants';

import './SFATrends.css';
import BackButton from './BackButton';

import { ReportContextProvider } from '../Report/ReportContext';
import ReportRetriever from '../Report/RerunReport/ReportRetriever';

import { setTitle } from '../../utils/browserUtils';

function SFATrends() {
  setTitle('Market Trends');
  const graph = TRENDS_REPORTS.map((reportid) => (
    <div>
      <ReportContextProvider>
        <ReportRetriever
          reportid={reportid}
          graphOnly
        />
      </ReportContextProvider>
    </div>
  ));

  return (
    <>
      <h1 className="center-text">Some Market Trends We Track</h1>
      <BackButton noText />
      <div className="trends sizing-small">
        {graph}
      </div>
    </>
  );
}
export default SFATrends;
