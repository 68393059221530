import React from 'react';
import propTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

import Button from '../../Input/Button';
import ContentBox from '../ContentBox';

import './Dialog.css';

function Titles({
  title = '',
  subtitle = '',
  supertitle = '',
}) {
  if (!title && !subtitle && !supertitle) return null;
  return (
    <h2>
      {supertitle && <span className="supertitle">{supertitle}</span>}
      {title}
      {subtitle && <span className="subtitle">{subtitle}</span>}
    </h2>
  );
}
Titles.propTypes = {
  title: propTypes.string,
  supertitle: propTypes.string,
  subtitle: propTypes.string,
};

function CloseButton({ closeFn = undefined }) {
  if (!closeFn) return null;
  return (
    <Button
      variant="icon"
      onClick={closeFn}
      className="closeButton"
    >
      <CloseIcon />
    </Button>
  );
}
CloseButton.propTypes = {
  closeFn: propTypes.func,
};

function Header({
  closeFn = undefined,
  title = '',
  subtitle = '',
  supertitle = '',
}) {
  if (!closeFn && !title && !subtitle && !supertitle) return null;
  return (
    <div className="dialog-header">
      <Titles supertitle={supertitle} title={title} subtitle={subtitle} />
      <CloseButton closeFn={closeFn} />
    </div>
  );
}
Header.propTypes = {
  closeFn: propTypes.func,
  title: propTypes.string,
  supertitle: propTypes.string,
  subtitle: propTypes.string,
};

function Dialog({
  children = null,
  centerAll = false,
  closeFn = undefined,
  title = '',
  subtitle = '',
  supertitle = '',
  footerContent = null,
  className = '',
}) {
  const classNames = ['dialog'];
  if (className) classNames.push(className);
  if (centerAll) classNames.push('dialog-centerall');
  return (
    <div className="dialog-wrapper">
      <div className={classNames.join(' ')}>
        <ContentBox>
          <Header
            closeFn={closeFn}
            title={title}
            subtitle={subtitle}
            supertitle={supertitle}
            footerContent={footerContent}
          />
          <div className="dialog-content">
            {children}
          </div>
          {footerContent && (
            <div className="dialog-footer">
              {footerContent}
            </div>
          )}
        </ContentBox>
      </div>
    </div>
  );
}

export default Dialog;

Dialog.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  centerAll: propTypes.bool,
  closeFn: propTypes.func,
  title: propTypes.string,
  subtitle: propTypes.string,
  supertitle: propTypes.string,
  footerContent: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  className: propTypes.string,
};
