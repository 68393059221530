import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MarkdownParser from '../../core/Transform/MarkdownParser';

import {
  SOLUTIONS_PATH,
  ABOUT_PATH,
  APP_STYLE,
  NAVBAR_PAGES,
  PRIVACY_POLICY_PATH,
  TOS_PATH,
  getPath,
} from '../../constants';
import './footer.css';
import logo from '../../images/LogoColor.svg';
import Flex from '../Common/FlexV2';

import Navbar from '../Navbar/Navbar';

function FooterLink({
  text = '',
  link = '',
  subtext = '',
}) {
  return (
    <Flex direction="column">
      <Link className="footer-link" to={link}>
        {text}
      </Link>
      {subtext}
    </Flex>
  );
}

function SFAFooter() {
  const STRINGS = {
    DMMHomeLink: 'https://www.datamixmaster.com/',
    DMMHomeText: 'Powered by DataMixMaster',
  };
  const MENU_ITEMS = [
    ...NAVBAR_PAGES[0].children,
    {
      text: 'Glossary',
      url: getPath('SFA.GLOSSARY').replace('*', 'options'),
    },
    {
      text: 'Bibliography',
      url: getPath('SFA.BIBLIOGRAPHY').replace('*', 'options'),
    },
  ];

  return (
    <footer>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            <b>
              <span style={{ color: 'var(--color-primary)' }}>Explore</span>
              {' more SFA data:'}
            </b>
          </p>
        </div>
        <Navbar pagesToRender={MENU_ITEMS} onBottom noSignIn />
        <div className="footer-DMMHomeLink">
          <MarkdownParser
            md={`[${STRINGS.DMMHomeText}](${STRINGS.DMMHomeLink})`}
            openNewTab
          />
        </div>
      </div>
    </footer>
  );
}

function Footer() {
  const supportEmail = 'support@datamixmaster.com';

  if (APP_STYLE === 'sfa-variant') return <SFAFooter />;
  return (
    <footer>
      <div>
        <img src={logo} alt="logo" className="footer-logo" />
        <FooterLink link={SOLUTIONS_PATH} text="Solutions" />
        <FooterLink
          link={ABOUT_PATH}
          text="Company"
          subtext={(
            <>
              <Link to={PRIVACY_POLICY_PATH}>Privacy Policy</Link>
              <Link to={TOS_PATH}>Terms of Service</Link>
            </>
          )}
        />
        <FooterLink
          link={ABOUT_PATH}
          text="Contact"
          subtext={(
            <a
              href={`mailto:${supportEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {supportEmail}
            </a>
          )}
        />
      </div>
    </footer>
  );
}

FooterLink.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Footer;
