import React from 'react';
import propTypes from 'prop-types';

import HeightIcon from '@mui/icons-material/Height';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Tooltip from '../Tooltip';

import './Table.css';
import columnProps from './columnProps';
import tableOptionsProps from './tableOptionsProps';
import hasRowActions from './hasRowActions';

const columnsProps = propTypes.arrayOf(columnProps);

function getCellStyle(column) {
  const style = column.width
    ? {
      minWidth: `${column.width}ch`,
      maxWidth: `${column.width}ch`,
    } : undefined;
  return style;
}

function HeaderCell({
  column,
  icon = null,
  sort = undefined,
  switchSortColumn = undefined,
  switchSortDirection = undefined,
}) {
  const style = getCellStyle(column);
  const { noSort } = column;
  const toggleSort = () => {
    if (noSort) return;
    if (!sort) return;
    if (column.id === sort.column) {
      switchSortDirection();
    } else {
      switchSortColumn(column.id);
    }
  };
  const renderSortIcon = () => {
    if (noSort) return null;
    if (!sort) return null;
    if (sort.column === column.id) return <SortIcon sort={sort} />;
    return <HeightIcon className="non-sorted-column-icon" />;
  };
  return (
    <th
      style={style}
      className={column.sticky ? 'stickyLeft' : ''}
    >
      <div>
        <button
          type="button"
          key={`${column.id}-sort-button`}
          onClick={toggleSort}
        >
          {column.name}
        </button>
        {icon}
        {renderSortIcon()}
      </div>
    </th>
  );
}
HeaderCell.propTypes = {
  column: columnProps.isRequired,
  icon: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  sort: propTypes.shape({
    column: propTypes.string,
    ascending: propTypes.bool,
  }),
  switchSortColumn: propTypes.func,
  switchSortDirection: propTypes.func,
};

function SortIcon({ sort }) {
  return sort.ascending
    ? <ArrowUpwardIcon key="up-icon" />
    : <ArrowDownwardIcon key="down-icon" />;
}
SortIcon.propTypes = {
  sort: propTypes.shape({
    ascending: propTypes.bool,
  }).isRequired,
};

export default function TableHead({
  columns,
  sort = undefined,
  switchSortColumn = undefined,
  switchSortDirection = undefined,
  options = undefined,
  keyFieldName = '',
}) {
  return (
    <thead>
      <tr>
        {columns.map((column) => {
          if (column.hidden) return null;
          return (
            <HeaderCell
              column={column}
              key={column.id || column.name}
              icon={[
                column.description
                  ? <Tooltip key="desc" text={column.description} />
                  : null,
              ]}
              sort={sort}
              switchSortDirection={switchSortDirection}
              switchSortColumn={switchSortColumn}
            />
          );
        })}
        {hasRowActions(keyFieldName, options) && (
          <HeaderCell
            column={{ name: 'Actions' }}
            sort={sort}
          />
        )}
      </tr>
    </thead>
  );
}
TableHead.propTypes = {
  columns: columnsProps.isRequired,
  sort: propTypes.shape({
    column: propTypes.string,
    ascending: propTypes.bool,
  }),
  switchSortColumn: propTypes.func,
  switchSortDirection: propTypes.func,
  options: tableOptionsProps,
  keyFieldName: propTypes.string,
};
