import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Burger from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Button from '../../core/Input/Button';

import './navbar.css';
import SignInOutButton from './SignInOutButton';

import {
  NAVBAR_STRINGS,
} from '../../constants';
import UserContext from '../../UserContext';
import { useGoogleAnalyticsTracker } from '../../utils/googleAnalyticsUtils';
import Logo from '../../images/LogoColor';

function useOnHoverOutside(ref, handler) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current) { return; }
      if (ref.current.contains(e.target)) { return; }
      handler(e);
    };

    document.addEventListener('mouseover', listener);
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('mouseout', listener);
    };
  }, [ref, handler]);
}

function Dropdown({ pagesRendered, open }) {
  if (open) {
    return (
      <nav
        id="navbarDropdown"
        className="dropdown-content"
      >
        {pagesRendered}
      </nav>
    );
  }
  return (
    <div />
  );
}

function WebNav({
  setNavOpen,
  item = {},
  topLevel = false,
  onBottom = false,
}) {
  const {
    text,
    url,
    children,
    reqs,
  } = item;
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  const { loggedIn } = useContext(UserContext);
  const googleAnalyticsEventTracker = useGoogleAnalyticsTracker('Navigation');

  const expand = (click) => {
    if (!children) return;
    if (window.innerWidth < 788 && !click) return;
    setOpen(true);
  };
  const collapse = () => { if (children) setOpen(false); };
  useOnHoverOutside(dropdownRef, collapse);
  const classNames = [
    (open ? 'nav-list-open' : 'nav-list-closed'),
    (topLevel ? 'nav-list-dropdown' : 'nav-list-bar'),
  ];

  const matchReqs = (requirements) => {
    let match = true;
    requirements.forEach((req) => {
      if (!match) { return; }
      switch (req) {
        case 'signed_out':
          match = !loggedIn();
          break;
        case 'signed_in':
          match = loggedIn();
          break;
        default:
          break;
      }
    });
    return match;
  };

  const list = (
    <ul className={classNames.join(' ')}>
      {children && children.map((child) => (
        <WebNav
          key={child.url || child.text}
          item={child}
          setNavOpen={setNavOpen}
          onBottom={onBottom}
        />
      ))}
    </ul>
  );

  if (reqs && !matchReqs(reqs)) { return null; }
  return (
    <li
      key={url}
      ref={dropdownRef}
      onFocus={() => { expand(true); }}
      onMouseOver={() => { expand(false); }}
    >
      {onBottom && list}
      {text && url && (
        <Link
          to={url}
          onClick={() => {
            setNavOpen(false);
            googleAnalyticsEventTracker('Click', `${text} (${url})`);
          }}
        >
          {text}
        </Link>
      )}
      {text && !url && (
        <button
          type="button"
          onClick={expand}
        >
          {text}
        </button>
      )}
      {!onBottom && list}
    </li>
  );
}

WebNav.propTypes = {
  item: PropTypes.instanceOf(Object),
  topLevel: PropTypes.bool,
  setNavOpen: PropTypes.func.isRequired,
  onBottom: PropTypes.bool,
};

function getMiscDataObject(pagesToRender) {
  for (let i = 0; i < pagesToRender.length; i += 1) {
    const { text, children } = pagesToRender[i];
    if (text === NAVBAR_STRINGS.MISC_DATA_TEXT) return pagesToRender[i];
    if (children) {
      const miscDataObject = getMiscDataObject(children);
      if (miscDataObject) return miscDataObject;
    }
  }
  return undefined;
}

function formatMiscDataMenu(miscDataMenu, pagesToRender) {
  try {
    const miscDataObject = getMiscDataObject(pagesToRender);
    miscDataObject.children = miscDataMenu
      .map(({ key }) => ({
        url: `/misc/${key.replace(/ /g, '_')}`,
        text: key,
      }));
    return miscDataObject;
  } catch {
    return [];
  }
}

function Navbar({
  pagesToRender = { Pages: [] },
  onBottom = false,
  noSignIn = false,
}) {
  const [open, setOpen] = useState(false);
  const { miscDataMenu } = useContext(UserContext);
  if (!Array.isArray(pagesToRender)) return null;

  formatMiscDataMenu(miscDataMenu, pagesToRender);

  const classNames = [open ? 'nav-open' : 'nav-closed'];
  if (onBottom) classNames.push('on-bottom');

  const updateMenu = () => {
    const newOpen = !open;
    setOpen(newOpen);
  };

  const topOfViewport = document.documentElement.scrollTop || document.body.scrollTop;

  return (
    <>
      <Button
        classes={['burger']}
        onClick={updateMenu}
        variant="icon"
      >
        <Burger />
      </Button>
      <nav
        className={classNames.join(' ')}
        style={{ top: `${topOfViewport}px` }}
      >
        <div className="close-button-container">
          <div className="title">
            <Logo />
          </div>
          <Button
            classes={['burger']}
            onClick={updateMenu}
            variant="icon"
          >
            <CloseIcon />
          </Button>
        </div>
        {Array.isArray(pagesToRender)
          && pagesToRender.map((page) => (
            <WebNav
              key={page.url || page.text}
              item={page}
              topLevel
              setNavOpen={setOpen}
              onBottom={onBottom}
            />
          ))}
        {noSignIn
        || (
          <SignInOutButton
            setNavOpen={setOpen}
            onBottom={onBottom}
          />
        )}
      </nav>
    </>
  );
  /*
  }
  return (
    <Flex direction="column">
      <Flex className="dropdown" direction="row">
        <Aligner width="70">
          <Link to={HOME_PATH} className="title">
            <Suspense fallback={null}>
              <Logo />
            </Suspense>
          </Link>
        </Aligner>
        <Aligner width="30">
          <Flex>
            <Button
              onClick={() => updateMenu()}
              className="dropdown_btn"
              type="button"
            >
              <Burger />
            </Button>
          </Flex>
        </Aligner>
      </Flex>
      <nav className={open ? 'nav-list-open' : 'nav-list-closed'}>
        {Array.isArray(pagesToRender)
          && pagesToRender.map((page) => (
            <WebNav
              key={page.url || page.text}
              item={page}
              topLevel
            />
          ))}
      </nav>
    </Flex>
  );
  */
}

Navbar.propTypes = {
  pagesToRender: PropTypes.instanceOf(Object),
  onBottom: PropTypes.bool,
  noSignIn: PropTypes.bool,
};

Dropdown.propTypes = {
  pagesRendered: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
};
export default Navbar;
