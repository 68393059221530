import React from 'react';
import propTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';

import './BackButton.css';

function BackButton({ noText = false }) {
  return (
    <Link to="/" className="sfa-hub-back-button">
      <button type="button" className={noText ? 'no-text' : ''}>
        <div className="icon-background">
          <KeyboardArrowLeftIcon />
        </div>
        <span>
          Back to Dashboard
        </span>
      </button>
    </Link>
  );
}
BackButton.propTypes = { noText: propTypes.bool };

export default BackButton;
