import { ON_STAGING } from '../constants';

export const BASE_URL = ON_STAGING
  ? localStorage.getItem('backendURL')
  : process.env.REACT_APP_BACKEND_BASE_URL;

const URL_MAP = { BASE_URL };

export function getMap() { return URL_MAP; }
export default URL_MAP;
