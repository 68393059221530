import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InlineMessage from '../../../core/Display/InlineMessage';
import LoadingAnimation from '../../../core/Display/LoadingAnimation';
import PageTitle from '../../../core/Display/PageTitle';
import ContentBox from '../../../core/Display/ContentBox';
import Dropdown from '../../../core/Input/Dropdown';

import UserContext from '../../../UserContext';
import NONE, { PAGE_TITLES } from '../../../constants';

import { cropText } from '../../../utils/stringUtils';

import ReportRetriever from './ReportRetriever';

import { fetchReportList } from '../../../utils/networkUtils';
import { setTitle } from '../../../utils/browserUtils';

const RPT_LOAD_ERR = 'Something went wrong while loading your saved reports.';
const DISPLAY = {
  Pending: 'Pending',
  Error: 'Error',
  Content: 'Content',
};

/* eslint no-underscore-dangle: off */
/* eslint arrow-body-style: off */

function SelectReport({ setReportID }) {
  setTitle(PAGE_TITLES.ViewReports);
  const [reports, setReports] = useState([]);
  const [activeReport, setActiveReport] = useState('');
  const [display, setDisplay] = useState(DISPLAY.Pending);
  const {
    receivedURLs,
    userID,
  } = useContext(UserContext);

  const transformReports = (list) => {
    const transformed = [{ id: NONE, name: NONE }];
    list.forEach((rpt) => {
      transformed.push({
        id: rpt._id,
        name: cropText(rpt.rpt_title),
      });
    });
    return transformed;
  };

  useEffect(() => { setReportID(activeReport); }, [activeReport]);

  useEffect(() => {
    if (!receivedURLs) { return; }
    fetchReportList(userID)
      .then((resp) => {
        const transReports = transformReports(resp.data.Reports);
        setReports(transReports);
        setActiveReport(transReports[0].id);
        setDisplay(DISPLAY.Content);
      })
      .catch(() => { setDisplay(DISPLAY.Error); });
  }, [receivedURLs]);

  let content = <LoadingAnimation />;
  switch (display) {
    case DISPLAY.Content:
      content = (
        <Dropdown
          options={reports}
          onChange={(e) => { setActiveReport(e.target.value); }}
          value={activeReport}
        />
      );
      break;
    case DISPLAY.Error:
      content = <InlineMessage type="error" text={RPT_LOAD_ERR} />;
      break;
    default:
      content = <LoadingAnimation />;
      break;
  }
  return (
    <ContentBox>
      <PageTitle text={PAGE_TITLES.ViewReports} />
      {content}
    </ContentBox>
  );
}

SelectReport.propTypes = {
  setReportID: PropTypes.func.isRequired,
};

function RetrieverWrapper({ reportid }) {
  const hidereport = (reportid === NONE || !reportid);
  if (hidereport) { return null; }
  return <ReportRetriever key={reportid} reportid={reportid} />;
}

RetrieverWrapper.propTypes = {
  reportid: PropTypes.string.isRequired,
};

function RerunReport() {
  const [reportID, setReportID] = useState(NONE);
  return (
    <>
      <SelectReport setReportID={setReportID} />
      <RetrieverWrapper reportid={reportID} />
    </>
  );
}

export { RPT_LOAD_ERR };
export default RerunReport;
