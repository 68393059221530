import React from 'react';
import propTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import './PaginationButtons.css';

function PaginationButtons({
  startRow,
  rowsPerPage,
  tableLength,
  onNext,
  onBack,
  changePage,
}) {
  const lastRow = tableLength + 1;
  const last = Math.min(startRow + rowsPerPage, tableLength);
  const numberOfPages = Math.ceil(tableLength / rowsPerPage);
  const currentPage = Math.ceil((startRow + 1) / rowsPerPage);
  const pageNumbers = new Array(numberOfPages).fill(null).map((value, index) => {
    const page = index + 1;
    const pageButton = (
      <Button
        key={page}
        onClick={() => { changePage(page); }}
        variant="icon"
        classes={page === currentPage ? [] : ['button-hollow']}
      >
        {page}
      </Button>
    );
    if (page === currentPage) return pageButton;
    if (page === 1) return pageButton;
    if (page === numberOfPages) return pageButton;
    if (page === currentPage - 1) return pageButton;
    if (page === currentPage + 1) return pageButton;
    const ellipsesButton = (
      <Button
        key={page}
        variant="icon"
        classes={['button-hollow']}
        disabled
      >
        ...
      </Button>
    );
    if (Math.abs(currentPage - page) === 2
      && currentPage > 4
      && currentPage < numberOfPages - 3) return ellipsesButton;
    if (page === numberOfPages - 5 && currentPage > page + 1) return ellipsesButton;
    if (page === 6 && currentPage < page - 1) return ellipsesButton;
    if (page < 7 && currentPage <= 4) return pageButton;
    if (page > (numberOfPages - 6) && currentPage >= (numberOfPages - 3)) return pageButton;
    return null;
  });

  return (
    <div className="pagination-button-container">
      <div>
        {`Showing ${startRow + 1} to ${last} of ${tableLength} rows`}
      </div>
      <ButtonGroup>
        <Button
          onClick={onBack}
          variant="icon"
        >
          <ChevronLeft />
        </Button>
        {pageNumbers}
        <Button
          onClick={onNext}
          disabled={last >= lastRow}
          variant="icon"
        >
          <ChevronRight />
        </Button>
      </ButtonGroup>
    </div>
  );
}

PaginationButtons.propTypes = {
  startRow: propTypes.number.isRequired,
  rowsPerPage: propTypes.number.isRequired,
  tableLength: propTypes.number.isRequired,
  onNext: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
  changePage: propTypes.func.isRequired,
};

export default PaginationButtons;
