import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ContentBox from '../../../../core/Display/ContentBox';
import Text from '../../../../core/Display/Text';
import Button from '../../../../core/Input/Button';

import Aligner from '../../../Common/AlignerV2';
import Flex from '../../../Common/FlexV2';
import Graph from '../../../Common/Graph';

import SectionAnalysis from '../Common/SectionAnalysis';

const RESET_BUTTON_TEXT = 'Reset Graph';

function STYPEtoLabel(STYPE) {
  switch (STYPE) {
    case ('1D'):
      return 'days';
    case ('1W'):
      return 'weeks';
    case ('1M'):
      return 'months';
    case ('1Q'):
      return 'quarters';
    case ('1Y'):
      return 'years';
    default:
  }
  return '';
}

function OffsetsBar({
  dataSet,
  offsets,
  resetOffsets,
}) {
  if (Object.keys(offsets).length === 0) { return null; }
  const OFFSET_INFO = Object.keys(offsets).map((name) => {
    const val = offsets[name];
    const unit = (dataSet.stype ? STYPEtoLabel(dataSet.stype) : '');
    return (
      <Flex
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        key={`${name}${val}${unit}`}
      >
        <Text variant="questionsLabel" key={name}>
          {name}
          :
        </Text>
        <Flex sx={{ alignItems: 'center' }}>
          <Text variant="questionsLabel">
            {`${val} ${unit}`}
          </Text>
        </Flex>
      </Flex>
    );
  });
  return (
    <ContentBox>
      <Aligner>
        <Aligner width={60}>
          <Flex direction="column">
            <Text variant="questionsLabel" key="OFFSET_LABEL">Offsets:</Text>
            {OFFSET_INFO}
          </Flex>
        </Aligner>
        <Aligner width={40}>
          <Flex direction="row-reverse">
            <Button onClick={resetOffsets}>
              {RESET_BUTTON_TEXT}
            </Button>
          </Flex>
        </Aligner>
      </Aligner>
    </ContentBox>
  );
}

OffsetsBar.propTypes = {
  dataSet: PropTypes.instanceOf(Object).isRequired,
  offsets: PropTypes.instanceOf(Object).isRequired,
  resetOffsets: PropTypes.func.isRequired,
};

function GraphView({
  canEdit = false,
  dataSet = { columns: [], rows: [] },
  offsets = {},
  resetOffsets = () => {},
  userText = '',
  onUserTextChange = () => {},
  title = undefined,
  userColumns = [],
}) {
  const { columns, rows } = dataSet;
  const jointColumns = useMemo(() => [...columns, ...userColumns], [columns, userColumns]);
  return (
    <>
      <Graph
        title={title}
        xAxisID="date"
        columns={jointColumns}
        rows={rows}
      />
      <SectionAnalysis
        canEdit={canEdit}
        content={userText}
        onChange={onUserTextChange}
      />
      <OffsetsBar
        dataSet={dataSet}
        offsets={offsets}
        resetOffsets={resetOffsets}
      />
    </>
  );
}

export default GraphView;

GraphView.propTypes = {
  canEdit: PropTypes.bool,
  dataSet: PropTypes.instanceOf(Object),
  offsets: PropTypes.instanceOf(Object),
  resetOffsets: PropTypes.func,
  userText: PropTypes.string,
  onUserTextChange: PropTypes.func,
  title: PropTypes.string,
  userColumns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};
