import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  oldDataSetToNew,
} from '../../../core/Utils/objectUtils';

import ReportContext from '../ReportContext';
import { DATA_VIEW_NAMES } from '../constants';

import DataTableView from './DataViews/DataTableView';
import StatsView from './DataViews/StatsView';
import CorrelationsView from './DataViews/CorrelationsView';
import GraphView from './DataViews/GraphView';
import HigherMomentsView from './DataViews/HigherMomentsView';
import SectionAnalysis from './Common/SectionAnalysis';
import DataViewHeader from './Common/DataViewHeader';
import ReportTitle from '../Common/ReportTitle';
import SaveReport from './SaveReport/SaveReport';

import './DisplayReport.css';

function generateItems(
  canEdit,
  dataViews,
  dataSet,
  userColumns,
  setUserColumns,
  setDataViewText,
  setDataViewEnabled,
) {
  const newDataSet = oldDataSetToNew(dataSet);
  const reportItems = dataViews.map((item) => {
    let comp = null;
    if (!item.enabled && !canEdit) return null;
    switch (item.name) {
      case DATA_VIEW_NAMES.Data:
        comp = (
          <DataTableView
            key="datasetwidget"
            dataSet={newDataSet}
            userText={item.text}
            canEdit={canEdit}
            onUserTextChange={(val) => { setDataViewText(item.id, val); }}
            userColumns={userColumns}
            setUserColumns={setUserColumns}
          />
        );
        break;
      case DATA_VIEW_NAMES.Stats:
        comp = (
          <StatsView
            key="stats"
            dataSet={dataSet}
            userText={item.text}
            canEdit={canEdit}
            onUserTextChange={(val) => { setDataViewText(item.id, val); }}
          />
        );
        break;
      case DATA_VIEW_NAMES.Correlations:
        if (!dataSet.corrs) break;
        comp = (
          <CorrelationsView
            key="corrs"
            dataSet={dataSet}
            userText={item.text}
            canEdit={canEdit}
            onUserTextChange={(val) => { setDataViewText(item.id, val); }}
          />
        );
        break;
      case DATA_VIEW_NAMES.Graph:
        comp = (
          <GraphView
            key="graph"
            dataSet={newDataSet}
            userText={item.text}
            canEdit={canEdit}
            onUserTextChange={(val) => { setDataViewText(item.id, val); }}
            userColumns={userColumns}
          />
        );
        break;
      case DATA_VIEW_NAMES.Moments:
        if (!dataSet.var) break;
        if (!dataSet.skew) break;
        if (!dataSet.kurt) break;
        comp = (
          <HigherMomentsView
            key="moments"
            dataSet={dataSet}
            userText={item.text}
            canEdit={canEdit}
            onUserTextChange={(val) => { setDataViewText(item.id, val); }}
          />
        );
        break;
      default:
    }

    if (!comp) return null;
    return (
      <DataViewHeader
        canEdit={canEdit}
        defaultOpen={(comp.key === 'graph')}
        enabled={item.enabled}
        key={comp.key}
        onToggle={() => { setDataViewEnabled(item.id, !item.enabled); }}
        text={item.text}
        title={item.name}
      >
        {comp}
      </DataViewHeader>
    );
  });
  return reportItems;
}

function DisplayReport({
  dataSet,
  form,
  reportID = '',
  canEdit = false,
}) {
  const {
    title,
    setTitle,
    dataViews,
    setDataViewText,
    setDataViewEnabled,
    userColumns,
    setUserColumns,
    analysis,
    setAnalysis,
  } = useContext(ReportContext);
  const [reportItems, setReportItems] = useState([]);

  useEffect(() => {
    setReportItems(generateItems(
      canEdit,
      dataViews,
      dataSet,
      userColumns,
      setUserColumns,
      setDataViewText,
      setDataViewEnabled,
    ));
  }, [dataViews, userColumns]);

  return (
    <div>
      <div className="report-header">
        <div className="report-header-title">
          <ReportTitle
            className="data-set-widget-title"
            text={title}
            canEdit={canEdit}
            onChange={setTitle}
          />
        </div>
        <SaveReport
          dataSet={dataSet}
          form={form}
          report={{
            id: reportID,
            text: analysis,
            title,
            components: dataViews,
            userColumns,
          }}
          small
        />
      </div>
      <SectionAnalysis
        className="user-text"
        variant="reportSectionAnalysis"
        content={analysis}
        canEdit={canEdit}
        onChange={(val) => { setAnalysis(val); }}
      />
      { reportItems }
      <SaveReport
        dataSet={dataSet}
        form={form}
        report={{
          id: reportID,
          text: analysis,
          title,
          components: dataViews,
          userColumns,
        }}
      />
    </div>
  );
}

export default DisplayReport;

DisplayReport.propTypes = {
  reportID: PropTypes.string,
  dataSet: PropTypes.instanceOf(Object).isRequired,
  canEdit: PropTypes.bool,
  form: PropTypes.instanceOf(Object).isRequired,
};
