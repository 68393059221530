import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
} from 'react-simple-maps';
import { Navigate } from 'react-router-dom';

import SectionTitle from '../../core/Display/SectionTitle';
import Dropdown from '../../core/Input/Dropdown';

import NONE, { SFA_STATE } from '../../constants';
import { setTitle } from '../../utils/browserUtils';

import GEO_SOURCE from './us-albers.json';
import './SFAState.css';
import BackButton from './BackButton';

const DEFAULT = NONE;
const STRINGS = {
  instructions: 'Choose your state'.toUpperCase(),
  confirm: 'Begin',
};

const COLORS = {
  normal: 'var(--color-10)',
  highlight: 'var(--color-primary)',
};

export function createStateChoices(states, defaultString = 'Select State') {
  const choices = states
    .map(({ properties }) => ({
      id: properties.iso_3166_2,
      name: properties.name,
    }))
    .sort(({ name: a }, { name: b }) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
  return [{ id: DEFAULT, name: defaultString }].concat(choices);
}

function SFAStates() {
  const [state, setState] = useState(DEFAULT);
  const [stateChoices] = useState(createStateChoices(GEO_SOURCE.objects.us.geometries));
  setTitle('US State Data');

  if (state !== DEFAULT) return <Navigate push to={`${SFA_STATE}/${state}`} />;
  return (
    <div className="sfa-state-nonmap sizing-small">
      <BackButton />
      <SectionTitle text={STRINGS.instructions} />
      <Dropdown
        options={stateChoices}
        value={state}
        onChange={({ target }) => { setState(target.value); }}
        sorted
      />
      <ComposableMap
        projection="geoAlbers"
        className="us-map"
        projectionConfig={{
          center: [-1.5, 35.5],
          scale: 1050,
        }}
      >
        <Geographies geography={GEO_SOURCE}>
          {({ geographies }) => geographies.map((geography) => (
            <Geography
              key={geography.rsmKey}
              geography={geography}
              stroke="white"
              tabIndex="-1"
              style={{
                default: {
                  fill: state === geography.properties.iso_3166_2
                    ? COLORS.highlight
                    : COLORS.normal,
                  transition: 'fill 0.2s',
                  outline: 'none',
                },
                hover: {
                  fill: COLORS.highlight,
                  transition: 'fill 0.2s',
                  cursor: 'pointer',
                  outline: 'none',
                },
                pressed: {
                  outline: 'none',
                },
              }}
              onClick={() => { setState(geography.properties.iso_3166_2); }}
            />
          ))}
        </Geographies>
      </ComposableMap>
    </div>
  );
}
export default SFAStates;
