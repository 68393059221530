import axios from 'axios';
import { checkAuth } from '../../../../utils/networkUtils';

export const ERRORS = {
  noAuthKey: 'No auth key was provided to useSaveReport.',
  unauthorized: 'The given auth key is invalid.',
  formFalsy: 'Form is falsy.',
  formEmpty: 'Form has no properties.',
  formNoAPIS: 'Form has no api_ids property.',
  componentsFalsy: 'Components is falsy.',
  componentsEmpty: 'Components is empty.',
};

function constructQueryParams(form) {
  if (!form) throw new Error(ERRORS.formFalsy);
  if (!Object.keys(form).length) { throw new Error(ERRORS.formEmpty); }
  if (!form.api_ids) { throw new Error(ERRORS.formNoAPIS); }
  let queryParams = '';
  Object.keys(form).forEach((param) => {
    if (!form[param]) { return; }
    if (param === 'title') { return; } // title sent separately
    if (queryParams !== '') { queryParams = queryParams.concat('&'); }
    queryParams = queryParams.concat(param, '=', form[param]);
  });
  return queryParams;
}

function condenseComponents(components) {
  if (!components) { throw new Error(ERRORS.componentsFalsy); }
  if (!components.length) { throw new Error(ERRORS.componentsEmpty); }
  const conciseComponents = components.map((comp) => ({
    name: comp.name,
    text: comp.text,
    enabled: comp.enabled,
  })).filter((comp) => comp !== null);
  return conciseComponents;
}

async function useSaveReport(
  form,
  getSaveReportURL,
  report,
  user,
  userID,
  destinationName = '',
) {
  if (!user) throw new Error(ERRORS.noAuthKey);
  await checkAuth(user)
    .then(() => {})
    .catch(() => { throw new Error(ERRORS.unauthorized); });

  const {
    text,
    title,
    components,
    userColumns,
  } = report;

  if (!title) { throw new Error('A report must have a title.'); }
  const source = axios.CancelToken.source();
  const queryParams = constructQueryParams(form);
  const conciseComponents = condenseComponents(components);

  const requestParams = {};
  if (source.token) requestParams.cancelToken = source.token;
  if (queryParams) requestParams.qry_params = queryParams;
  if (userID) requestParams.user = userID;
  if (text) requestParams.rpt_text = text;
  if (title) requestParams.rpt_title = title;
  if (conciseComponents) requestParams.sections = conciseComponents;
  if (destinationName) requestParams.dest_nm = destinationName;
  if (userColumns && userColumns.length) requestParams.user_cols = userColumns;

  return new Promise((resolve, reject) => {
    axios.post(getSaveReportURL(), requestParams)
      .then((resp) => resp.data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default useSaveReport;
