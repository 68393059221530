import queryStringToObject from './queryStringToObject';

export default function queryStringToParams(query) {
  const params = queryStringToObject(query);
  if (!params.path_keys) return params;
  const ignoredKeys = JSON.parse(params.path_keys);
  if (!Array.isArray(ignoredKeys)) return params;
  ignoredKeys.forEach((key) => { delete params[key]; });
  delete params.path_keys;
  return params;
}
