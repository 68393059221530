import React from 'react';
import PropTypes from 'prop-types';

import NONE from '../../../constants';

import Label from '../Label';
import '../Input.css';

const SUPPORTED_BROWSERS = [
  'Mozilla', // Firefox
  'Chrome', // Chrome, but will match Opera or Edge because they use the Blink engine too
  'OPR', // Blink-based versions of Opera
  'Edg', // Edge
  'Safari',
];
const USER_AGENT = window.navigator.userAgent;
const tagInAgentString = (tag) => USER_AGENT.includes(tag);
const NOT_FOUND = -1;
const ON_SUPPORTED_BROWSER = SUPPORTED_BROWSERS.findIndex(tagInAgentString) !== NOT_FOUND;
const ON_MOBILE = USER_AGENT.toLowerCase().includes('mobile');
const IN_TEST_ENVIRONMENT = process.env.JEST_WORKER_ID !== undefined;
const HR_VALID_CHILD = ON_SUPPORTED_BROWSER && !ON_MOBILE && !IN_TEST_ENVIRONMENT;

function renderOptions(options, sorted = false) {
  const sortedOptions = sorted
    ? options
    : options.sort(({ id: idA, name: a }, { id: idB, name: b }) => {
      if (idA === NONE) return -1;
      if (idB === NONE) return 1;
      return a > b;
    });
  const items = sortedOptions
    .map((option, index) => {
      const id = option.id ? option.id : index.toString();
      const name = option.name ? option.name : '';
      return (
        <React.Fragment key={id}>
          <option
            className="dropdown-option"
            value={id}
          >
            {name}
          </option>
          {(id === NONE && HR_VALID_CHILD) && <hr />}
        </React.Fragment>
      );
    });
  return items;
}

function Dropdown({
  options = [],
  id = undefined,
  label = undefined,
  onChange = () => {},
  value = undefined,
  defaultValue = undefined,
  disabled = false,
  fullWidth = false,
  sorted = false,
  helperText = '',
}) {
  const items = renderOptions(options, sorted);
  const containerClassName = ['input-container', 'has-options-list'];
  const selectClassName = ['input-select'];
  if (fullWidth) containerClassName.push('input-full-width');
  return (
    <div className={containerClassName.join(' ')}>
      {label
      && (
        <Label
          className="input-label"
          htmlFor={id}
          id={`${label.replace(/ /g, '-')}`}
          text={label}
          helperText={helperText}
        />
      )}
      <select
        className={selectClassName.join(' ')}
        id={id}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
      >
        {items}
      </select>
    </div>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  id: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sorted: PropTypes.bool,
  helperText: PropTypes.string,
};
