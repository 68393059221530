import React from 'react';
import propTypes from 'prop-types';

import MarkdownParser from '../../core/Transform/MarkdownParser';
import Text from '../../core/Display/Text';
import Button from '../../core/Input/Button';

import {
  SOLUTIONS_PATH,
  APP_STYLE,
} from '../../constants';
import { useScrollbarWidth, setTitle } from '../../utils/browserUtils';

import col1Text from '../../text/home_col1';
import col2Text from '../../text/home_col2';
import col3Text from '../../text/home_col3';
import DEMO_VIDEO from '../../videos/dmm_demo_video.mp4';
import DEMO_VIDEO_TRACK from '../../videos/dmm_demo_video.vtt';
import col1Icon from '../../images/DMM_icon01.svg';
import col2Icon from '../../images/DMM_icon02.svg';
import col3Icon from '../../images/DMM_icon03.svg';

import Flex from '../Common/FlexV2';

import './home.css';
import SFAHomepage from './SFAHomepage';

function HomeParagraph({ column }) {
  const { text, image } = column;
  return (
    <div className="home-paragraph">
      <Text variant="body1">
        <img src={image} alt="" />
        <MarkdownParser md={text} />
      </Text>
    </div>
  );
}
HomeParagraph.propTypes = {
  column: propTypes.shape({
    text: propTypes.string,
    image: propTypes.string,
  }).isRequired,
};

function HomeParagraphs() {
  const columns = [
    { text: col1Text, image: col1Icon },
    { text: col2Text, image: col2Icon },
    { text: col3Text, image: col3Icon },
  ];
  return (
    <div
      className="row-col home-bottom"
      style={{ '--scrollbar-width': `${String(useScrollbarWidth())}px` }}
    >
      <HomeParagraph column={columns[0]} />
      <HomeParagraph column={columns[1]} />
      <HomeParagraph column={columns[2]} />
    </div>
  );
}

function Home() {
  setTitle();

  if (APP_STYLE === 'sfa-variant') {
    return <SFAHomepage />;
  }

  return (
    <Flex direction="column" gap={10}>
      <div className="row-col home-top">
        <div>
          <h1 style={{ fontWeight: 'normal' }}>
            <span style={{ color: 'var(--color-secondary)' }}>
              Stress Relief&nbsp;
            </span>
            for
            <br />
            Data Analysts
          </h1>
          <br />
          <Text variant="body1">
            The same way analysts were liberated by spreadsheets 40 years ago,
            DataMixMaster opens up a world of insights and creativity...
          </Text>
          <br />
          <Text variant="body1">
            The ability to play with numbers and ask &quot;What if?&quot;
          </Text>
          <br />
          <br />
          <Button homePage url={SOLUTIONS_PATH}>
            Learn more
          </Button>
        </div>
        <div className="graph-container">
          <video className="solutions_video" controls width="100%">
            <source src={DEMO_VIDEO} type="video/mp4" />
            <track default kind="captions" srcLang="en" src={DEMO_VIDEO_TRACK} />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      </div>
      <HomeParagraphs />
    </Flex>
  );
}

export default Home;
